import React, { useState, useEffect, useRef, FC, ReactNode, CSSProperties } from "react";
import {
  Select,
  OutlinedInput,
  Menu,
  MenuItem,
  Grid,
  Input,
  InputAdornment,
  Box,
} from "@material-ui/core";
import { Add, Check, KeyboardArrowDown, KeyboardArrowUp, Search } from "@material-ui/icons";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { makeStyles, withStyles } from "@material-ui/styles";

import CustomLabel from "./Label";
import TextInput from "./TextInput";
import Button from "./Button";

const mainOptions: OptionProps[] = [
  {
    id: 2,
    value: "Contact",
    label: "Contact",
    options: [
      {
        label: "Name",
        value: "name",
        // options: [
        //   { label: "First Name", value: "first_name" },
        //   {
        //     label: "Last Name",
        //     value: "last_name",
        //     options: [
        //       { label: "LN1", value: "ln1" },
        //       {
        //         label: "LN2",
        //         value: "ln2"
        //       }
        //     ]
        //   }
        // ]
      },
      { label: "Email", value: "email" },
      { label: "Phone", value: "phone" },
      { label: "Facebook", value: "facebook" }
    ]
  },
  {
    id: 3,
    label: "Company",
    value: "Company",
    options: [
      { label: "Company name", value: "company_name" },
      { label: "Domain", value: "domain" }
    ]
  },
  {
    id: 4,
    label: "Event",
    value: "event"
  }
];

const isEmpty = (value: Object | null) => {
  if (value == null) {
    return true;
  }
  for (var key in value) {
    if (React.hasOwnProperty.call(value, key)) {
      return false;
    }
  }
  return true;
}
const toLower = (value: string) => {
  return typeof value === "string" ? value.toLowerCase() : "";
};

const defaultVal = {
  label: "",
  value: "-1",
  object: undefined
};

type OptionProps = {
  id?: number | string;
  value: string;
  label: string;
  options?: { label: string; value: string; options?: any[] }[];
};

export type OnChangeProps = {
  label: string;
  value: string;
  object: string;
  parent?: { label: string; value: string };
};

type defaultValueObjectsProps = {
  object?: string;
  label: string;
  value: string;
};

type Props = {
  crmFields?: OptionProps[];
  onChange: (changeObj: OnChangeProps) => void;
  fullWidth?: boolean;
  defaultLabel?: string;
  labelStyle?: CSSProperties;
  placeholder?: string;
  defaultValueObject: defaultValueObjectsProps;
  logo?: ReactNode;
  searchable?: boolean;
  addable?: boolean;
  addButtonText?: string;
  addPlaceholder?: string;
  required?: boolean;
  onCreateNew?: (value: string) => void;
  searchPlaceholder?: string;
  openInitialDefaultSelectionOnMenuOpen?: boolean;
};

const ReactNestedSelect: FC<Props> = ({
  crmFields = mainOptions,
  onChange = (obj) => console.log("Selected ", obj),
  fullWidth = false,
  searchable = true,
  addable = true,
  searchPlaceholder,
  openInitialDefaultSelectionOnMenuOpen = true,
  defaultLabel,
  labelStyle,
  placeholder = "Select",
  addButtonText = "Add New",
  addPlaceholder = "Typing...",
  required = false,
  onCreateNew = (val) => console.log("Sent ", val),
  defaultValueObject = defaultVal,
  logo
}) => {
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();

  const [anchorEl, setAnchorEl] = useState({});
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [focusedObject, setFocusedObject] = useState("");
  const [selectedValue, setSelectedValue] = useState<OnChangeProps>({} as OnChangeProps);
  const [
    selectedObjectNestedKeyList,
    setSelectedObjectNestedKeyList
  ] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const parentRef = useRef({} as any);

  useEffect(() => {
    // Get initially selected objects and set to state
    if (defaultValueObject && !isEmpty(defaultValueObject)) {
      updateValuesFromSelectedTree(
        defaultValueObject.object || "",
        defaultValueObject.label,
        defaultValueObject.value
      );
    }
  }, [defaultValueObject]);

  /**
   * getFilteredObject - Filter options by default value and object and returns fitlered object tree
   * @returns {Object} - Returns an array of object
   */
  const getFilteredObject = (
    array: OptionProps[] = [],
    selectedRootObjName = "",
    selectedLabel: string,
    selectedVal: string
  ) => {
    const fn = ({ label, value }: { label: string; value: string }) => {
      return (
        toLower(value) === toLower(selectedVal) &&
        toLower(label) === toLower(selectedLabel)
      );
    };
    const filteredObjectList = array?.filter((each) => {
      const { label, value } = each;
      if (selectedRootObjName) {
        return (
          toLower(value) === toLower(selectedRootObjName) ||
          toLower(label) === toLower(selectedRootObjName)
        );
      }
      return true;
    });
    return filterData(filteredObjectList, fn);
  };

  /**
   * getSelectedObjectKeyValCombination
   * @returns {Object} - Returns an array of string in the format [label+value].
   * @example
   * // returns ["Namefirst_name", "Contactemail"]
   */
  const getSelectedObjectKeyValCombination = (
    obj = {} as OptionProps,
    results: string[] = [],
    searchKey: string,
    defaultVal: string
  ) => {
    let r = results;
    Object.keys(obj).forEach((key) => {
      const value = obj[key as keyof OptionProps];
      const label = obj?.label;
      if (
        key === searchKey &&
        typeof value !== "object" &&
        obj?.options?.length
      ) {
        r.push(label + value);
      } else if (typeof value === "object") {
        if (value?.length) {
          value.sort((each: OptionProps) => {
            if (each?.options?.length) {
              return 1;
            }
            return -1;
          });
        }
        //@ts-ignore
        if (defaultVal && value?.value === defaultVal) {
          r = [];
        }

        //@ts-ignore
        getSelectedObjectKeyValCombination(value, r, searchKey, defaultVal);
      }
    });
    return r;
  };

  const updateValuesFromSelectedTree = (
    object: string,
    fieldLabel: string,
    fieldValue: string
  ) => {
    const [defaultObject]: OptionProps[] = getFilteredObject(
      crmFields,
      object,
      fieldLabel,
      fieldValue
    );

    if (!isEmpty(defaultObject)) {
      const nestedValues = getSelectedObjectKeyValCombination(
        defaultObject,
        [],
        "value",
        fieldValue
      );
      setSelectedObjectNestedKeyList(nestedValues);

      setSelectedValue({
        ...selectedValue,
        value: fieldValue,
        label: fieldLabel,
        object
      });
    }
  };

  const handleSelect = (selectedObject: OnChangeProps) => {
    onChange(selectedObject);
    setSelectedValue(selectedObject);
    updateValuesFromSelectedTree(
      selectedObject.object,
      selectedObject.label,
      selectedObject.value
    );
    // reset
    setAnchorEl({});
    setFocusedObject("");
    setSearchValue("");
  };

  const handleCreateNewClick = () => {
    onCreateNew(newCategory);
    // reset
    setNewCategory("");
    setIsCreatingNew(false);
    setAnchorEl({});
  }

  const filterData = (array: any[], fn: { ({ label, value }: { label: any; value: any; }): boolean; ({ value, label }: { value: any; label: any; }): boolean; (arg0: any): any; }) => {
    return array.reduce((r: any[], o: { options: any; }) => {
      var options = filterOptionsByKeyword(o.options || []);
      if (fn(o) || options?.length)
        r.push({ ...o, ...(options.length && { options }) });
      return r;
    }, []);
  };

  const filterOptionsByKeyword = (array: OptionProps[] = []) => {
    const fn = ({ value, label }: { value: string; label: string }) => {
      return (
        toLower(value).includes(toLower(searchValue)) ||
        toLower(label).includes(toLower(searchValue))
      );
    };
    return filterData(array, fn);
  };

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, key: string = "") => {
    e.stopPropagation();
    setAnchorEl({
      [key]: e.currentTarget
    });
    setFocusedObject(key);
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, key: string = "") => {
    setAnchorEl({
      [key]: null
    });
    setFocusedObject("");
  };

  const renderSelectValue = (val: unknown) => {
    if (val === placeholder) {
      return (
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs="auto">
            <span className={classes.selectLabelWrapper}>
              {val}
            </span>
          </Grid>
          <Grid item xs="auto">
            {!isEmpty(anchorEl) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container alignItems="center" justifyContent="space-between">
        {logo && (
          <Grid item xs="auto" style={{ display: "flex", marginRight: 6 }}>
            {logo}
          </Grid>
        )}
        <Grid item xs="auto">
          <span className={classes.selectLabelWrapper}>{val as unknown as string}</span>
        </Grid>
        <Grid item xs="auto">
          {!isEmpty(anchorEl) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </Grid>
      </Grid>
    );
  };

  return (
    <Box style={{ width: "100%" }}>
      {defaultLabel && (
        <CustomLabel style={{ ...labelStyle }} required={required}>{defaultLabel}</CustomLabel>
      )}
      <Select
        value={selectedValue?.label ?? placeholder}
        renderValue={renderSelectValue}
        open={!isEmpty(anchorEl)}
        onOpen={(e) => setAnchorEl({ main: e.target })}
        onClose={() => setAnchorEl({})}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          className: classes.menu,
          classes: { paper: classes.menuPaper, list: classes.menuList },
        }}
        input={
          <OutlinedInput
            fullWidth={fullWidth}
            id="nested-select-1"
            classes={outlinedInputClasses}
            style={{ borderRadius: "8px" }}
          />
        }
        classes={{ icon: classes.selectIcon, select: classes.select }}
        
      >
        {/* Display search filter if searchable is true */}
        {searchable && (
          <MenuItem
            className={classes.searchListItem}
            onClick={(event) => {
              event.stopPropagation();
              setAnchorEl({
                ...anchorEl,
                main: event.currentTarget
              });
            }}
            // onKeyDown={(e) => e.stopPropagation()}
          >
            <TextInput
              id="nested-select-filter"
              placeholder={searchPlaceholder || "Type ..."}
              iconLeft={<Search style={{ fill: "#8C8C8C" }} />}
              fullWidth
              value={searchValue}
              autoComplete="off"
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              className={classes.searchField}
              style={{ borderRadius: "98px" }}
            />
          </MenuItem>
        )}

        {/* Display search filter if searchable is true */}
        {addable && (
          <MenuItem
            className={isCreatingNew ? classes.searchListItem : classes.addNewItem}
            onClick={(event) => {
              event.stopPropagation();
              setAnchorEl({
                ...anchorEl,
                main: event.currentTarget
              });
              if (!isCreatingNew) {
                setIsCreatingNew(true);
              }
            }}
          >
           {isCreatingNew ? (
              <Input
                id="nested-create-new-field"
                type="text"
                placeholder={addPlaceholder}
                fullWidth
                value={newCategory}
                onChange={e => setNewCategory(e.target.value)}
                endAdornment={
                    <InputAdornment position="end">
                        <Button
                            disabled={newCategory === ""}
                            style={{ 
                                height: "fit-content", 
                                padding: "5px 16px", 
                                backgroundColor: newCategory === "" ? "#94A3B8" : "#1A469C", 
                                color: "#F8FAFC",
                                fontSize: "10px",
                                lineHeight: "12px" 
                            }}
                            onClick={handleCreateNewClick}
                            // onMouseDown={handleMouseDownPassword}
                        >
                            Add
                        </Button>
                    </InputAdornment>
                }
                style={{ height: "46px" }}
                classes={{ underline: classes.underline }}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation();
                  }
                }}
              />
           ) : (
              <Grid container alignItems="center" spacing={1}>
                    <Grid item xs="auto">
                        <Add style={{ fill: "#1A469C" }} />
                    </Grid>
                    <Grid item xs="auto" style={{ display: "flex", color: "#1A469C" }}>
                        {addButtonText}
                    </Grid>
              </Grid>
            )}
          </MenuItem>
        )}

        {filterOptionsByKeyword(crmFields).map(({ value, label, options }: OptionProps) => {
          const currentAnchor = {
            ...anchorEl,
            ...(toLower(value) === toLower(selectedValue?.object)&& !Boolean(anchorEl[(label + value) as keyof {}])
              ? {
                  [label + value]: parentRef.current[label + value]
                }
              : {})
          };
          const isValueSelected =
            toLower(value) === toLower(selectedValue?.object) ||
            Boolean(currentAnchor[(label + value) as keyof {}]) ||
            selectedObjectNestedKeyList.includes(label + value);

          const subMenuToOpen =
            focusedObject &&
            focusedObject === label + value &&
            !!options?.length
              ? true
              : (Boolean(currentAnchor[(label + value) as keyof {}]) || isValueSelected) &&
                !!options?.length &&
                openInitialDefaultSelectionOnMenuOpen &&
                !focusedObject;

          return (
            <div
              onMouseEnter={(e) => handleMouseEnter(e, label + value)}
              onMouseLeave={(e) => handleMouseLeave(e, label + value)}
              key={label + value}
              ref={el => {
                if (el) {
                  parentRef.current[label + value] = el;
                }
              }}
            >
              <StyledMenuItem
                id={`menu-item-${label}${value}`}
                value={value}
                selected={isValueSelected}
                onClick={(event) => {
                  event.stopPropagation();
                  if (!options?.length) {
                    handleSelect({
                      label,
                      value,
                      object: value
                    });
                  }
                }}
                style={{ backgroundColor: (focusedObject === label + value) ? "#E8EDF5" : "#FFF", color: (focusedObject === label + value) ? "#1A469C" : "#475569" }}
              >
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid item xs="auto">
                    {label}{" "}
                  </Grid>
                  <Grid item xs="auto" style={{ display: "flex" }}>
                    {options?.length ? <StyledArrowForwardIosIcon fontSize="small" />
                      : value === selectedValue.value ? <Check fontSize="small" style={{ fill: "#1A469C" }} /> : null
                    }
                  </Grid>
                </Grid>
                {/* render sub menu if options exists */}
                {options && 
                    <SubMenu
                      depth={1}
                      open={subMenuToOpen}
                      parentAnchorEle={parentRef.current}
                      label={label}
                      value={value}
                      options={options}
                      selectValue={(subLabel, subValue, parent = {}) => {
                        handleSelect({
                          label: subLabel,
                          value: subValue,
                          object: value,
                          parent
                        });
                      }}
                      selectedValue={selectedValue}
                      selectedObjectNestedKeyList={selectedObjectNestedKeyList}
                    />}
              </StyledMenuItem>
            </div>
          );
        })}
      </Select>
    </Box>
  );
};

export default ReactNestedSelect;

type SubMenuProps = {
  depth: number;
  open: boolean;
  parentAnchorEle: any;
  label: string;
  value: string;
  options: OptionProps[];
  selectValue: (label: string, value: string, parent?: any) => void;
  selectedValue: OnChangeProps;
  selectedObjectNestedKeyList: string[];
};

const SubMenu: FC<SubMenuProps> = React.memo(
  ({
    depth,
    open,
    parentAnchorEle,
    label,
    value,
    options,
    selectValue,
    selectedValue,
    selectedObjectNestedKeyList
  }) => {
    const classes = useStyles();

    const [currentAnchorEle, setCurrentAnchorEle] = useState({});
    const [parentRef, setParentRef] = useState(null);
    const [focusedObject, setFocusedObject] = useState("");

    const submenuRef = useRef({} as any);

    useEffect(() => {
      if (Boolean(parentAnchorEle[label + value]) && isEmpty(parentRef)) {
        setTimeout(() => {
          setParentRef(parentAnchorEle[label + value]);
        }, 100 + depth * 100);
      }
    }, [parentAnchorEle[label + value]]);

    const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, key: string = "") => {
      e.stopPropagation();
      setCurrentAnchorEle({
        [key]: e.currentTarget
      });
      setFocusedObject(key);
    };
    const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, key: string = "") => {
      e.stopPropagation();
      setCurrentAnchorEle({
        [key]: null
      });
    };

    return (
      <Menu
        style={{ pointerEvents: "none" }}
        id={`simple-menu-${label}${value}`}
        open={open && !!parentRef}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        PaperProps={{
          elevation: 2,
          style: { marginLeft: 4, maxHeight: 400 },
          className: classes.menuPaper,
        }}
        MenuListProps={{
          style: { padding: 4 }
        }}
        autoFocus={false}
        disableAutoFocus
        disableEnforceFocus
        anchorEl={parentRef}
        getContentAnchorEl={null}
      >
        {options?.map((eachOption) => {
          const {
            value: subVal,
            label: subLabel,
            options: subOptions
          } = eachOption;

          const isSelected =
            (toLower(subVal) === toLower(selectedValue?.value) &&
              toLower(subLabel) === toLower(selectedValue?.label)) ||
            selectedObjectNestedKeyList.includes(subLabel + subVal);

          const isParentAndSelected =
            Boolean(parentAnchorEle[label + value]) &&
            toLower(subVal) === toLower(selectedValue?.parent?.value || "") &&
            toLower(subLabel) === toLower(selectedValue?.parent?.label || "");

          const currentAnchor = {
            ...currentAnchorEle,
            ...(isSelected && !Boolean(currentAnchorEle[(subLabel + subVal) as keyof {}])
              ? {
                  [subLabel + subVal]: submenuRef.current[(subLabel + subVal) as keyof {}]
                }
              : {})
          };

          const isMenuSelected =
            isSelected ||
            isParentAndSelected ||
            Boolean(currentAnchor[(subLabel + subVal) as keyof {}]);

          const subMenuToOpen =
            focusedObject &&
            focusedObject === subLabel + subVal &&
            !!subOptions?.length
              ? true
              : isMenuSelected && !!subOptions?.length && !focusedObject;

          const handleMenuItemClick = (e: any) => {
            e.stopPropagation();
            if (!subOptions?.length) {
              selectValue(subLabel, subVal, { label, value });
              setCurrentAnchorEle({});
            }
          };
          return (
            <div
              onMouseEnter={(e) => handleMouseEnter(e, subLabel + subVal)}
              onMouseLeave={(e) => handleMouseLeave(e, subLabel + subVal)}
              key={subLabel + subVal}
              ref={(el) => {
                if (el) {
                    submenuRef.current[(subLabel + subVal) as keyof {}] = el;
                }
              }}
            >
              <StyledMenuItem
                id={`sub-menu-item-${subLabel}${subVal}`}
                key={subLabel + subVal}
                value={subVal}
                selected={isMenuSelected}
                onClick={handleMenuItemClick}
                style={{ pointerEvents: "auto" }}
              >
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid item xs="auto">
                    {subLabel}{" "}
                  </Grid>
                  <Grid item xs="auto" style={{ display: "flex" }}>
                    {subOptions?.length ? <StyledArrowForwardIosIcon fontSize="small" />
                      : isMenuSelected ? <Check fontSize="small" style={{ fill: "#1A469C" }} /> : null
                    }
                  </Grid>
                </Grid>
                {subOptions && <SubMenu
                  depth={depth + 1}
                  open={subMenuToOpen}
                  label={subLabel}
                  value={subVal}
                  options={subOptions}
                  selectValue={(l, v, parent) => selectValue(l, v, parent)}
                  selectedValue={selectedValue}
                  parentAnchorEle={submenuRef.current}
                  selectedObjectNestedKeyList={selectedObjectNestedKeyList}
                />}
              </StyledMenuItem>
            </div>
          );
        })}
      </Menu>
    );
  }
);

const useStyles = makeStyles(() => ({
  menu: {
    marginTop: 0,
  },
  menuPaper: {
    border: "1px solid #FFF !important",
    borderRadius: "8px !important",
    backgroundColor: "#fff !important",
    maxHeight: "340px !important",
        '&::-webkit-scrollbar': {
        width: '13px'
    },
    '&::-webkit-scrollbar-track': {
        border: '0.94px solid #CBD5E1'
    },
    '&::-webkit-scrollbar-thumb': {
        borderRadius: '28.34px 28.34px 85.01px',
        backgroundColor: '#EDF1F6',
        border: '0.94px solid #94A3B8'
    }
  },
  menuList: {
    padding: "10px 23px 10px 10px !important",
  },
  selectLabelWrapper: {
    marginRight: 16
  },
  selectLabel: {
    margin: "10px auto",
    color: "#0A0A0A"
  },
  selectIcon: {
    color: "#0A0A0A",
    marginRight: 8
  },
  searchField: {
    borderRadius: 98,
  },
    select: {
        "&:focus": {
            backgroundColor: "transparent !important",
        },  
    },
  searchListItem: {
    padding: "0 0 10px !important",
    background: "#fff !important"
  },
  addNewItem: {
    backgroundColor: "#E8EDF5 !important",
    borderRadius: "8px !important",
    padding: "12px 16px !important"
  },
  underline: {
    "&:before": {
        borderBottom: "1px solid #000 !important"
    },
    "&:after": {
        borderBottom: "1px solid #000 !important"
    }
  }
}));

const useOutlinedInputStyles = makeStyles(() => ({
  root: {
    width: "100%",
    background: "#fff",
    height: 44,
    borderRadius: "8px",
    "& $notchedOutline": {
        border: "1px solid #CBD5E1",
    },
    "&:hover $notchedOutline": {
        border: "1px solid #CBD5E1",
    },
    "&$focused $notchedOutline": {
        border: "1px solid #CBD5E1",
    },
    minWidth: 230
  },
  focused: {
    border: "1px solid #CBD5E1",
  },
  notchedOutline: {}
}));

const StyledArrowForwardIosIcon = withStyles(() => ({
  root: {
    fontSize: 14,
    color: "#898989"
  }
}))(ArrowForwardIosIcon);

const StyledMenuItem = withStyles(() => ({
  root: {
    minWidth: 170,
    width: "auto",
    height: 48,
    color: "#475569",
    fontSize: 16,
    lineHeight: "24px",
    margin: "10px 8px",
    padding: "12px 16px",
    borderRadius: 8,
    "&:hover": {
        backgroundColor: "#E8EDF5 !important",
        borderRadius: 8
    },
    "&.Mui-selected": {
        backgroundColor: "#FFF !important",
        "&:hover": {
          backgroundColor: "#E8EDF5 !important",
        }
    }
  }
}))(MenuItem);