import React from "react";
// Customizable Area Start
import {
  Box,
  Paper,
  Typography,
  InputBase,
  List,
  ListItemIcon,
  styled,
  CircularProgress,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { CallMade, Close } from "@material-ui/icons";

import { bg, darkBg} from "./assets";

import OutsideAlerter from "../../utilities/src/OutsideAlerter";
import CustomButton from "../../utilities/src/Button";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start 
  renderHeader = () => (
    <Search data-test-id="search-container" style={{ border: this.state.isOpen ? "2px solid #1A469C" : "" }}>
      <SearchIconWrapper data-test-id="search-icon-wrapper">
        <SearchIcon data-test-id="search-icon" />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search"
        inputProps={{ "aria-label": "search" }}
        onChange={(event) => this.handleInputChange(event)}
        onClick={this.openHistory}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            this.handleAddToSearchHistory(this.state.searchQuery);
            event.preventDefault();
          }
        }}
        data-test-id="search-input"
        value={this.state.searchQuery}
      />
    </Search>
  )

  renderNoResults = () => (
    <NoSearchContent data-test-id="empty-content" darkMode={this.props.darkMode}>
      <NoSearchTypography data-test-id="empty-no-search">
        "No Search Found"
      </NoSearchTypography>
      <NoSearchDesc data-test-id="empty-explore-text">
        Start exploring and your searches will appear here!
      </NoSearchDesc>
    </NoSearchContent>
  )
  renderSearchHistory = () => {
    return (
      <>
        <Typography style={{ fontFamily: "Rubik", fontWeight: 500, fontSize: "7px", lineHeight: "11px", color: "#475569", textTransform: "capitalize" }}>
          recent search
        </Typography>
        <List data-test-id="search-history-list" style={{ marginTop: "9px", maxHeight: "146px", overflow: "hidden", padding: 0 }}>
          {this.state.searchHistoryList.map((item: any, index: number) => (
              <div 
                key={index} 
                data-test-id="addSearch" 
                style={{ marginBottom: index !== this.state.searchHistoryList.length ? "10px" : 0 }}
              >
                <div 
                  style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} 
                  data-test-id={`search-history-item-${index}`}
                >
                  <Box style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                    <Close 
                      data-test-id="clear-search-history-button"
                      onClick={() => this.handleDelete(item.id)}
                      style={{ height: "12px", width: "12px", color: "#64748B", cursor: "pointer" }} 
                    />
                    <SearchItemTypo>{item.name}</SearchItemTypo>
                  </Box>
                  <CallMade 
                    data-test-id="add-search-history-btn"
                    style={{ height: "12px", width: "12px", color: "#1A469C", cursor: "pointer" }}                       
                    onClick={() => this.handleSelectSearchHistory(item.name)} 
                  />
                </div>
              </div>
          ))}
        </List>
      </>
    )
  }
  getHighlightedText = (text: string, highlight: string) => {
    // Split text on highlight term, include term itself into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return <ResultItemTypo>{parts.map((part, index) => part.toLowerCase() === highlight.toLowerCase() ? <span key={index} style={{ color: "#0F172A" }}>{part}</span> : part)}</ResultItemTypo>;
  }
  renderSearchResults = () => (
    <>
      <Typography style={{ fontFamily: "Rubik", fontWeight: 500, fontSize: "7px", lineHeight: "11px", color: "#475569", textTransform: "capitalize" }}>
        search results
      </Typography>
      <List data-test-id="history-list" style={{ marginTop: "11px", maxHeight: "220px", overflow: "hidden" }}>
        {this.state.searchHistory.map((item, index) => (
          <div 
            key={index} 
            data-test-id={`history-item-${index}`}
            style={{ marginBottom: index !== this.state.searchHistory.length ? "11px" : 0 }}
          >
            <div 
              style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} 
              data-test-id={`search-history-item-${index}`}
            >
              {this.getHighlightedText(item.courseName, this.state.searchQuery)}
              <ListItemIcon style={{ minWidth: 0, marginRight: "6px" }}>
                <CallMade
                  data-test-id="result-item-icon" 
                  style={{ height: "12px", width: "12px", color: "#1A469C", cursor: "pointer" }}                       
                  onClick={() => this.navigateToCourseDetail(item.id)} 
                />                          
              </ListItemIcon>
              
            </div>
          </div>
        ))}
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <StyledCustomButton 
            data-test-id="show-all-btn"
            size="sm" 
            onClick={() => this.goToFilters("Filteritems")}
          >Show all Results</StyledCustomButton>
        </Box>
      </List>
    </>
  )
  renderDropdown = () => (
    <OutsideAlerter onOutsideClick={this.handleClose}>
      <div style={{ position: "absolute", top: "60px", zIndex: 100 }} data-test-id="filters1-container">
        <div data-test-id="advanced-filters" style={{ zIndex: 100 }}>
          <Dropdown>
            <ArrowIcone />
            {this.state.isLoading ? (
              <Box style={{ height: "256px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress data-test-id="loading-spinner" color="secondary" />
              </Box>
            ) : (
              this.state.searchQuery === "" 
              ? (
                this.state.searchHistoryList.length === 0 ? this.renderNoResults() : this.renderSearchHistory()
              )
              : (
                this.state.searchHistory.length === 0 ? this.renderNoResults() : this.renderSearchResults()
              )
            )}
          </Dropdown>
        </div>
      </div>
    </OutsideAlerter>
  )
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={this.props.darkMode ? darkTheme : lightTheme} data-test-id="theme-provider">
        <ContainerBody data-test-id="container-body">
          {this.renderHeader()}
          {this.state.isOpen && this.renderDropdown()}
        </ContainerBody>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const FontStyle = {
  fontFamily: 'Rubik',
  h1: { fontSize: '32px' },
  h2: { fontSize: '30px' },
  h3: { fontSize: '28px' },
  h4: { fontSize: '26px' },
  h5: { fontSize: '24px' },
  h6: { fontSize: '22px' },
  subtitle1: { fontSize: '20px' },
  subtitle2: { fontSize: '18px' },
  body1: { fontSize: '16px' },
  body2: { fontSize: '14px' }
}

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
      light: '#F8FAFC',
      dark: '#FAFAF9',
      contrastText: '#DC2626'
    },
    secondary: {
      dark: '#808080',
      light: '#848484',
      main: '#000000',
      contrastText: "#94A3B8"
    },
    background: {
      paper: '#EDF1F6',
      default: '#FFFFFF'
    },
    text: {
      hint: '#E2E8F0',
      secondary: '#43536A',
      primary: '#0F172A',
      disabled: "#F4F4F5"
    },
    action: {
      focus: '#F9F9F9',
      selected: '#1A469C',
      active: '#F1F5F9',
      disabled: '#D7D7D7',
      hover: "#3F3F46",
      disabledBackground: "#27272A"
    },
    info: {
      light: '#475569',
      main: "#0F172A",
      contrastText: '#1A469C',
      dark: '#CBD5E1',
    },
    warning: {
      main: '#E8EDF5',
      light: "#059669",
      dark: '#D97706',
      contrastText: "#DDE1E6"
    },
    error: {
      main: "#FEE2E2",
      light: "#FEF3C7",
      dark: "#D1FAE5",
      contrastText: '#6683BD'
    },
    success: {
      main: "#475569"
    },
  },
  typography: FontStyle
});
export const darkTheme = createTheme({
  palette: {
    primary: {
      main: '#161616',
      light: '#0F172A',
      dark: '#121212',
      contrastText: '#F87171'
    },
    secondary: {
      main: '#FFFFFF',
      dark: '#18212E',
      light: '#99A7BB',
      contrastText: "#94A3B8"
    },
    background: {
      default: "#0D0D0D",
      paper: '#121B29'
    },
    text: {
      primary: '#D5DDF0',
      secondary: '#C4CFE1',
      hint: '#18212E',
      disabled: '#F7FBF7',
    },
    action: {
      active: '#424242',
      selected: 'rgb(112, 161, 255)',
      focus: '#161616',
      disabled: '#D7D7D733',
      hover: "#393D42",
      disabledBackground: "#212529"
    },
    info: {
      main: "#D5DDF0",
      light: '#99A7BB',
      dark: '#273444',
      contrastText: '#70A1FF'
    },
    warning: {
      main: '#121B29',
      light: '#42EBB5',
      dark: "#F28407",
      contrastText: "#242A31"
    },
    error: {
      main: "#1C1C1C",
      light: "#514101",
      dark: "#074525",
      contrastText: "#4F6080"
    },
    success: {
      main: "#93A1B5"
    }
  },
  typography: FontStyle
});

const NoSearchTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Kallisto !important",
  fontSize: "30px",
  fontWeight: 700,
  lineHeight: "40px",
  letterSpacing: "-0.005em",
  textAlign: "left",
  color: theme.palette.text.primary,
  width: "352px",
  height: "25px",
  gap: "0px",
  opacity: "0px",
}))
const NoSearchDesc = styled(Typography)(({ theme }) => ({
  width: "362px",
  height: "18px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "11.1px",
  fontWeight: 400,
  lineHeight: "15.54px",
  textAlign: "left",
  color: theme.palette.text.primary
}))

const ContainerBody = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const ArrowIcone = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '-6px',
  left: '10%',
  transform: 'rotate(45deg)', // Center adjustment
  width: '10px',
  height: '10px',
  zIndex: 999,
  borderBottom: 'none',
  borderRight: 'none',
  backgroundColor: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
}));

const SearchIconWrapper = styled("div")({
  padding: "0 16px",
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "gray"
});

const Search = styled('div')(({ theme }) => ({
  position: "relative",
  height: "44px",
  borderRadius: "98px",
  backgroundColor: theme.palette.background.default,
  cursor: "pointer",
  "&:hover": {
    border: `2px solid ${theme.palette.action.selected}`,
  },
  marginRight: "16px",
  marginLeft: 0,
  width: "100%",
  "@media (min-width:600px)": {
    marginLeft: "24px",
    width: "auto",
  },
  color: "#8C8C8C",
  border: `1px solid ${theme.palette.info.dark}`,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  paddingLeft: "50px",
  paddingTop: "5px",
  width: '100%',
  '&::placeholder': {
    color: theme.palette.primary.main
  },
}));

const Dropdown = styled(Paper)(({ theme }) => ({
  width: 500,
  maxHeight: 350,
  borderRadius: "18px",
  backgroundColor: theme.palette.primary.main,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(3),
  overflow: "hidden"
}));

const NoSearchContent = styled(Box)(({ theme, darkMode }: { theme: any; darkMode: any }) => ({
  backgroundImage: darkMode
    ? `url(${darkBg})`
    : `url(${bg})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 50px top -30px',
  backgroundSize: "126px 137px",
  width: "418px",
  height: "116.88px",
  gap: "0px",
  borderRadius: "4.97px",
  opacity: "0px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "left",
  margin: "30px",
}));

const SearchItemTypo = styled(Typography)({
  fontFamily: "Rubik",
  fontSize: "10px",
  lineHeight: "11px",
  color: "#0F172A"
})

const ResultItemTypo = styled(SearchItemTypo)({
  fontFamily: "Rubik",
  fontSize: "12px",
  lineHeight: "11px",
  color: "#94A3B8"
})

const StyledCustomButton = styled(CustomButton)({
  borderRadius: "30px",
  padding: "7px 12px",
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "12px"
})
// Customizable Area End
