import { Close } from "@material-ui/icons";
import React from "react";

import Card from "./Card";
import { handleFileSize } from "./handle-file-size";
import IconButton from "./IconButton";

const videoIcon = require("./assets/video-icon.svg");
const pdfIcon = require("./assets/pdf-icon.svg");
const audioIcon = require("./assets/audio-icon.svg");
const zipIcon = require("./assets/zip-icon.svg");


const FilePreviewCard = ({ file, onRemoveFileButtonClick }: { file: File; onRemoveFileButtonClick: () => void }) => {
    const getFileTypeIcon = (fileType: string) => {
        if (fileType.includes("video")) {
            return videoIcon;
        }
        if (fileType.includes("audio")) {
            return audioIcon;
        }
        if (fileType.includes("zip")) {
            return zipIcon;
        }
        return pdfIcon;
    }

    return (
        <Card 
            thumbnailSrc={getFileTypeIcon(file.type)}
            title={file.name} 
            subtitle={handleFileSize(file.size || 0)} 
            actionButton={<IconButton onClick={onRemoveFileButtonClick}><Close /></IconButton>} 
            style={{ marginTop: "10px", width: "70%" }}
        />
    )
}

export default FilePreviewCard;