// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const mailIcon = require("../assets/mailIcon.svg");
export const bellIcon = require("../assets/bellIcon.svg");
export const profileImage = require("../assets/profilePic.svg");
export const addIcon = require("../assets/addIcon.png");
export const attachIcon = require("../assets/attachIcon.png");
export const attachIconDark = require("../assets/attachIconDark.png");
export const atIcon = require("../assets/atIcon.png");
export const atIconDark = require("../assets/atIconDark.png");
export const cloudIcon = require("../assets/cloudIcon.png");
export const cloudIconDark = require("../assets/cloudIconDark.png");
export const videoIcon = require("../assets/videoIcon.png");
export const videoIconDark = require("../assets/videoIconDark.png");
export const closeIcon = require("../assets/closeIcon.png");
export const greenDot = require("../assets/greenDotIcon.png");
export const orangeDot = require("../assets/orangeDotIcon.png");
export const viewAll = require("../assets/viewAllIcon.png");
export const addSqureIcon = require("../assets/addSquareIcon.png");
export const moreIcon = require("../assets/moreIcon.png");
export const trashIcon = require("../assets/trashIcon.png");
export const editIcon = require("../assets/pencilIcon.png");
export const minimiseIcon = require("../assets/minimiseIcon.png");
export const playIcon = require("../assets/playIcon.png");
export const courseVideo = require("../assets/courseVideo.png");
export const downloadIcon = require("../assets/downloadIcon.png");
export const playButton = require("../assets/playButton.png");
export const downloaded = require("../assets/downloaded.png");
export const instructorImage = require("../assets/instructorImage.png");
export const submittedIcon = require("../assets/submittedIcon.png");
export const deleteWarningIcon = require("../assets/delete_warning.png");
export const reviewAddIcon = require("../assets/reviewAddIcon.png");
export const starRatingIcons = require("../assets/starRatingIcons.png");
export const videoRounded = require("../assets/videoo_rounded.svg")
export const audioButton = require("../assets/audioButton.png");
export const audioPlay = require("../assets/audioPlay.png")
export const expand = require("../assets/expand.png")
export const nextButton = require("../assets/nextButton.png")
export const previousButton = require("../assets/previousButton.png")
export const switchButton = require("../assets/switchButton.png")
export const playPauseButton = require("../assets/playPauseButton.png")
export const Vector = require("../assets/Vector.png")
export const AutoplayOff = require("../assets/Autoplay.png")
export const AutoplayOn = require("../assets/AutoPlayOn.png")
export const Replay = require("../assets/Replay.png")
export const PauseButton = require("../assets/PauseButton.png")
export const Volume = require("../assets/Volume.png")
export const Audio = require("../assets/audio.png")
export const Pdf = require("../assets/pdf.png")
export const textImage = require("../assets/textImage.png")
export const FileDownload = require("../assets/FileDownload.png")
export const ImageNotes = require("../assets/ImageNotes.png");
export const PptImage = require("../assets/PptImage.png");
export const CommentButton = require("../assets/CommentButton.png");
export const ExpandOut = require("../assets/ExpandOut.png");
export const AudioPlayButton = require("../assets/AudioPlayButton.png");
export const AudioPauseButton = require("../assets/AudioPauseButton.png");
export const tickIcon = require("../assets/tick_icon.svg");
export const rejectButton = require("../assets/RejectButton.svg");
export const approveButton = require("../assets/approve_btn.svg");
export const assignCourseIcon = require("../assets/assign_course_icon.svg");
export const Done=require("../assets/Done.jpg")




// Customizable Area End
