import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { handleResponseMessage } from "./helpers/handle-response-message";
import createRequestMessage from "./helpers/create-request-message";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { FieldValue } from "../../customisableuserprofiles/src/CustomisableUserProfilesController";
import { toast } from "react-toastify";
import moment from "moment";

interface Profile {
  "attributes": {
    "id": number,
    "created_at": string,
    "updated_at": string,
    "status": string,
    "progress_percentage": string,
    "expiration_date": string,
    "group": string,
    "red_label": string,
    "course": {
      "data": {
        "attributes": {
          "course_name": string,
        },
      },
    },
    "profile": {
      "data": {
        "attributes": {
          "id": number,
          "email": string,
          "preferred_name": string,
          "role_id": string,
          "image": string
        }
      }
    }
  }
}

interface Course {
  "attributes": {
    "id": number,
    "status": string,
    "progress_percentage": string,
    "expiration_date": string,
    "course_name": string,
    "preferred_name": string,
    "course": {
      "data": {
        "attributes": {
          "course_name": string,
          "course_description": string,
          "sub_sub_category": string,
          "language": string,
          "price": string,
          "level": string,
          "tags": string,
          "duration": string,
          "year": string,
          "is_assigned": boolean,
          "is_completed": boolean,
          "profile_id": number,
          "role_id": string,
          "preferred_name": string,
          "media_type": string,
          "publish_type": string,
          "due_date": string,
          "course_contents_count": number,
          "image": string,
          "video_url": string,
          "document_url": string
        }
      }
    }
  }
}
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isLoading: boolean;
  sideMenu: boolean;
  blackTheme: boolean;
  selectedCompany: string;
  selectedGroup: string;
  selectedCourse: string;
  selectedStatus: string;
  allCompanyName: { [key: string]: FieldValue }[];
  allGroupName: { [key: string]: FieldValue }[];
  allCourseName: { attributes: { [key: string]: FieldValue } }[];
  totalPage: number;
  currentPage: number;
  assignedCourseList: Profile[];
  completedCourseList: Course[];
  selectedRadio: string[];
  durationType: string;
  currentRole: string;
  enableExpDate: boolean;
  enableRedLabel: boolean;
  anchorSort: null | HTMLElement;
  enableSearchBar: boolean;
  searchValue: string;
  anchorDotOption: null | HTMLElement;
  selectedId: string;
  sortType: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CertificationTrackingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTokenApiId: string;
  getCompanyNamesApiCallId: string = "";
  getGroupNamesApiCallId: string = "";
  getCourseNamesApiCallId: string = "";
  getAssignedCoursesApiCallId: string = "";
  getCompletedCourseApiCallId: string = "";
  getUserRoleApiCallId: string = "";
  searchListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      isLoading: false,
      sideMenu: false,
      blackTheme: false,
      selectedCompany: "",
      selectedCourse: "",
      selectedGroup: "",
      selectedStatus: "",
      allCompanyName: [],
      allGroupName: [],
      allCourseName: [],
      totalPage: 1,
      currentPage: 1,
      assignedCourseList: [],
      completedCourseList: [],
      selectedRadio: [],
      durationType: '',
      currentRole: '',
      enableExpDate: false,
      enableRedLabel: false,
      anchorSort: null,
      enableSearchBar: false,
      searchValue: "",
      anchorDotOption: null,
      selectedId: "",
      sortType: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.getTokenApiId = "";
    this.handleGoToEmployeeList = this.handleGoToEmployeeList.bind(this);
    this.handleGoToCertList = this.handleGoToCertList.bind(this);
    this.handleGoToEmployeeCertMappingList =
      this.handleGoToEmployeeCertMappingList.bind(this);
    this.handleGoToEmployeeCertStatusList =
      this.handleGoToEmployeeCertStatusList.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallDataId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseDataJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const errorDataJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );

      this.setState({
        isLoading: false,
      });

      if (apiRequestCallDataId === this.getTokenApiId) {
        handleResponseMessage({
          responseJson: responseDataJson,
          errorJson: errorDataJson,
          onSuccess: () => {
            const responseToken = responseDataJson.meta.token;
            this.setState({
              token: responseToken,
            });
            setStorageData("token", responseToken);
          },
          onFail: () => {
            this.showAlert(`Error`, "Get Token Failed. Please retry!");
          },
        });
      }

      if (apiRequestCallDataId === this.getCompanyNamesApiCallId) {
        if (responseDataJson.errors) {
          toast.error(responseDataJson.errors[0].token)
          const msg1: Message = new Message(
            getName(MessageEnum.NavigationEmailLogInMessage)
          );
          msg1.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          this.send(msg1);
        } else {
          this.setState({ allCompanyName: responseDataJson.companies })
        }
      }

      this.checkResponses(message)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.checkTheme();
    window.addEventListener('storage', this.handleStorageChange)
    let menuState:any= localStorage.getItem('open');
    if(menuState!=null){
     this.setState({sideMenu:JSON.parse(menuState)})
    }
    this.getUserRole();
    this.getGroupNames();
    this.getAllCompanyName();
    this.getAllCourseName();
  }

  handleStorageChange(event :any) {
    if (event.key === 'darkMode') {
      this.checkTheme();
    }
  }
  handleGetToken() {
    this.setState({
      isLoading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getTokenApiId = requestMessage.messageId;

    const body = {
      data: {
        attributes: {
          email: configJSON.loginEmail,
          password: configJSON.loginPassword,
        },
        type: "email_account",
      },
    };

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configJSON.loginURLEndPoint,
      method: configJSON.postApiMethodType,
      body: JSON.stringify(body),
    });
  }
  handleGoToEmployeeList() {
    this.props.navigation.navigate("EmployeeList");
  }
  handleGoToCertList() {
    this.props.navigation.navigate("CertificateList");
  }
  handleGoToEmployeeCertMappingList() {
    this.props.navigation.navigate("EmployeeCertificateMappingList");
  }
  handleGoToEmployeeCertStatusList() {
    this.props.navigation.navigate("EmployeeCertificateEmployeeList");
  }

  checkResponses = (message: Message) => {
    const apiRequestCallDataId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage),
    );

    const responseDataJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    if (apiRequestCallDataId === this.getUserRoleApiCallId) {
      this.setState({ currentRole: responseDataJson.role_id })
      if (responseDataJson.role_id === "worker") {
        this.getCompletedCourses();
      } else {
        this.getAssignedCourses();
      }
    }

    if (apiRequestCallDataId === this.getGroupNamesApiCallId) {
      this.setState({ allGroupName: responseDataJson.group })
    }

    if (apiRequestCallDataId === this.getCourseNamesApiCallId) {
      this.setState({ allCourseName: responseDataJson.data })
    }

    if (apiRequestCallDataId === this.getAssignedCoursesApiCallId) {
      this.setState({ assignedCourseList: responseDataJson.assigned_courses.data, totalPage: responseDataJson.total_pages })
    }

    if (apiRequestCallDataId === this.getCompletedCourseApiCallId) {
      this.setState({ completedCourseList: responseDataJson.competed_courses.data, totalPage: responseDataJson.total_pages })
    }

    if (apiRequestCallDataId === this.searchListApiCallId) {
      this.setState({ assignedCourseList: responseDataJson.search_results.data, totalPage: responseDataJson.total_pages })
    }
  }

  goToPerformanceTracker() {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "PerformanceTracker");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleSideMenu = () => {
    this.setState({ sideMenu: !this.state.sideMenu })
    setStorageData('open', JSON.stringify(!this.state.sideMenu))
  }

  handleBlackTheme = () => {
    this.setState({ blackTheme: !this.state.blackTheme })
    setStorageData('darkMode', `${!this.state.blackTheme}`)
  }

  changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ currentPage: value }, () => this.state.enableSearchBar ? this.searchList({ key: 'Enter' }) : this.getAssignedCourses())
  }

  checkTheme = async () => {
    const mode = await getStorageData("darkMode");
    if (mode === 'true') {
      this.setState({ blackTheme: true })
    }
    else {
      this.setState({ blackTheme: false })
    }
  }
  handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = this.state.selectedRadio;
    newValue.push(event.target.value)
    this.setState({ selectedRadio: newValue })
  }

  sortList = (value: string) => {
    this.setState({ sortType: value }, () => this.getAssignedCourses())
  }

  changeCompany = (event: unknown) => {
    this.setState({ selectedCompany: String(event) }, () => this.getAssignedCourses())
  }

  changeGroup = (event: unknown) => {
    this.setState({ selectedGroup: String(event) }, () => this.getAssignedCourses())
  }

  changeCourse = (event: unknown) => {
    this.setState({ selectedCourse: String(event) }, () => this.getAssignedCourses())
  }

  changeStatus = (event: unknown) => {
    this.setState({ selectedStatus: String(event) }, () => this.getAssignedCourses())
  }

  changeSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchValue: event.target.value })
  }

  searchList = async (event: React.KeyboardEvent | { key: string }) => {
    if (event.key === 'Enter') {
      this.setState({ isLoading: true })
      this.searchListApiCallId = await this.callApi({
        apiContentType7: configJSON.validationApiContentType,
        httpMethod7: configJSON.validationApiMethodType,
        apiEndPoint7: `${configJSON.searchListEndPoint}?query=${this.state.searchValue}&page=${this.state.currentPage}&per_page=5`
      });
    }
  }

  handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>, key: string) => {
    this.setState({ anchorDotOption: event.currentTarget, selectedId: key });
  };

  renewExpiration = (value: string) => {
    this.setState({ anchorDotOption: null })
  }

  sendNotification = (value: string) => {
    this.setState({ anchorDotOption: null })
  }

  resetFilter = () => {
    this.setState({
      selectedCompany: "", selectedCourse: "", selectedGroup: "", selectedStatus: "",
      enableExpDate: false, enableRedLabel: false, selectedRadio: []
    }, () => this.getAssignedCourses())
  }
  convertDate = (value: string) => {
    let startDate = moment(value);
    let endDate = moment()
    const duration = moment.duration(startDate.diff(endDate));
    const diffMonths = Math.floor(duration.asMonths());
    const remainingDaysAfterMonths = duration.subtract(moment.duration(diffMonths, 'months')).days();
    const diffWeeks = Math.floor(remainingDaysAfterMonths / 7);
    const diffDays = remainingDaysAfterMonths % 7;
    if (diffMonths > 0) {
      return `WITHIN ${diffMonths} MONTH`
    } else if (diffWeeks > 0) {
      return `WITHIN ${diffMonths} WEEKS`
    } else if (diffDays > 0) {
      return `WITHIN ${diffDays} DAYS`
    }
  }

  getExpirationDate(value: string) {
    let expDate = moment(value).format('MM-DD-YY');
    return expDate
  }

  callApi = async (data: { [key: string]: FieldValue }) => {
    const { httpMethod7, apiEndPoint7, body7, type7, apiContentType7 } = data;
    let payload = body7;
    if (type7 === '') {
      payload = JSON.stringify(body7);
    }
    const apiHeader = {
      token: await getStorageData("authToken"),
      "Content-Type": apiContentType7,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint7
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(apiHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      httpMethod7
    );

    body7 &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        payload
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };

  getUserRole = async () => {
    this.setState({ isLoading: true })
    this.getUserRoleApiCallId = await this.callApi({
      apiContentType7: configJSON.validationApiContentType,
      httpMethod7: configJSON.validationApiMethodType,
      apiEndPoint7: configJSON.getRoleEndPoint
    });
  }

  getGroupNames = async () => {
    this.setState({ isLoading: true })
    this.getGroupNamesApiCallId = await this.callApi({
      apiContentType7: configJSON.validationApiContentType,
      httpMethod7: configJSON.validationApiMethodType,
      apiEndPoint7: configJSON.getGroupNamesEndPoint
    });
  }

  getAllCompanyName = async () => {
    this.setState({ isLoading: true })
    this.getCompanyNamesApiCallId = await this.callApi({
      apiContentType7: configJSON.validationApiContentType,
      httpMethod7: configJSON.validationApiMethodType,
      apiEndPoint7: configJSON.getCompanyNamesEndPoint
    });
  }

  getAllCourseName = async () => {
    this.setState({ isLoading: true })
    this.getCourseNamesApiCallId = await this.callApi({
      apiContentType7: configJSON.validationApiContentType,
      httpMethod7: configJSON.validationApiMethodType,
      apiEndPoint7: configJSON.getCourseNamesEndPoint
    });
  }

  getAssignedCourses = async () => {
    this.setState({ isLoading: true })
    this.getAssignedCoursesApiCallId = await this.callApi({
      apiContentType7: configJSON.validationApiContentType,
      httpMethod7: configJSON.validationApiMethodType,
      apiEndPoint7: `${configJSON.getAssignedCoursesEndPoint}?page=${this.state.currentPage}&per_page=5&course_name=${this.state.selectedCourse}&status=${this.state.selectedStatus}&group_name=${this.state.selectedGroup}&comany_name=${this.state.selectedCompany}&order=${this.state.sortType}&red_label=${this.state.enableRedLabel}&expiration_date=${this.state.enableExpDate}`
    });
  }

  getCompletedCourses = async () => {
    this.setState({ isLoading: true })
    this.getCompletedCourseApiCallId = await this.callApi({
      apiContentType7: configJSON.validationApiContentType,
      httpMethod7: configJSON.validationApiMethodType,
      apiEndPoint7: `${configJSON.getCompletedCourseEndPoint}?page=${this.state.currentPage}&per_page=4`
    });
  }
  // Customizable Area End
}
