import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Alert } from "react-native";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { OptionTypeBase, ValueType } from "react-select";

// Customizable Area Start
import moment from "moment";
import React, { ReactNode, RefObject } from 'react';
import { getStorageData, setStorageData, transformUrl } from "../../../framework/src/Utilities";
import  axios from 'axios';
import { RadioChangeEvent } from "antd";
import * as Yup from "yup";
import { pdfType, quiz, videoType, textType, audioType, multimediaType, pptType, interactiveType, imageType } from "./assets";
import FormDataBuilder from "../../../blocks/utilities/src/form-data-builder";
import { OnChangeProps } from "../../../blocks/utilities/src/ContextMenu";

type WithId<T> = {
  id: string;
  attributes: T;
}

export interface SubLesson {
  title: string;
  content?: string;
  lesson_type: string;
  files?: File|null;
  lesson_timer?: string;
  optional_text?: string;
}

interface FileType{
  id: number;
  name: string;
  size: number;
  url: string;
};

export interface Lessons {
  title: string;
  sub_lessons_attributes: SubLesson[];
}

export interface Chapter {
  title: string;
  lessons_attributes: Lessons[];
}

export interface FinalTestData {
  final_tests: {
    data: { id: string }[];
  };
  upload_media:{
    data:{}
  }
}

interface Course {
  course_name: string;
  duration: string;
  year: string;
  media_type: string;
  course_description: string;
  category_id: string;
  sub_category_id: string;
  time_for_supervisor_to_review?: string;
  language: string;
  level: string;
  price: string;
  tags: string;
  image: string;
  video: string;
  document: string;
  course_content_attributes: Chapter[];
  attributes:FinalTestData;
}

export interface Lesson {
  [key: string]: string
};

interface ProfileAttributes {
  id: number;
  first_name: string | null;
  last_name: string | null;
  email: string;
  date_of_birth: string;
  preferred_name: string;
  role_id: string;
  full_phone_number: string;
  employee_number: string | null;
  location: string | null;
  company_reference: string | null;
  completed_course: string | null;
  account_id: number;
  image: string | null;
  group_id: number;
  group_name: string;
  company_id: number[];
  company_name: string;
  phone_number_verified: boolean;
  selected_theme: string;
  font_size: string;
}

export interface MediaUrls{
  id:string;
  url:string;
}

interface ProfileData  {
  id: string;
  type: string;
  attributes: ProfileAttributes;
}

export type ITitleProps = { 
  heading: string; 
  subHeading?: string; 
  enableOfflineDownload?: boolean; 
  actionButton?: ReactNode;
  onBackClick?: () => void;
}

export type ICopyLessonSectionProps = | { 
  values: { [field: string]: any }; 
  errors: { [field: string]: any }; 
  handleChange: (e: React.ChangeEvent<any>) => void;
  withOptionalText?: false;
} | {
  values: { [field: string]: any }; 
  errors: { [field: string]: any }; 
  handleChange: (e: React.ChangeEvent<any>) => void;
  withOptionalText: true;
  optinalTextPosition: "top" | "bottom";
}

export const validationSchema = Yup.object({
  downloaded: Yup.boolean().default(false),
  course_name: Yup.string().trim().required('This field is required'),
  course_description: Yup.string().trim().required('This field is required'),
  image: Yup.string().trim().required('This field is required'),
  video: Yup.string().nullable(),
  category_id: Yup.number().min(0, 'This field is required'),
  sub_category_id: Yup.number().nullable(),
  time_for_supervisor_to_review: Yup.string().trim().required('This field is required'),
  start_date: Yup.string().nullable(),
  end_date: Yup.string().nullable(),
  tags: Yup.string().nullable(),
});
export const ChapterSchema = Yup.object({
  title: Yup.string().trim().required('This field is required')
});
export const SubLessonSchema = Yup.object({
  title: Yup.string().trim().required('This field is required'),
  lesson_type: Yup.string().min(1, 'This field is required'),
  content: Yup.string().nullable(),
  files: Yup.mixed().nullable(),
  lesson_timer: Yup.string().trim().matches(/^[0-9]+:[0-5][0-9]$/).nullable(),
  optional_text: Yup.string().nullable(),
  multimediaURL: Yup
    .string()
    .url()
    .transform((currentValue) => transformUrl(currentValue))
    .nullable()
});
export const LessonSchema = Yup.object({
  title: Yup.string().trim().required('This field is required'),
  sub_lessons_attributes: Yup.array(SubLessonSchema).default([])
});

export const LessonTypes = [
  { name: "video", icon: videoType }, 
  { name: "text", icon: textType }, 
  { name: "pdf", icon: pdfType }, 
  { name: "audio", icon: audioType }, 
  { name: "quiz", icon: quiz }, 
  { name: "multimedia", icon: multimediaType }, 
  { name: "presentation", icon: pptType }, 
  { name: "interactive training", icon: interactiveType }, 
  { name: "image", icon: imageType }
]

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  PostData: [];
  token: string;
  name: string;
  description: string;
  price: string;
  currency: string;
  category_id: string;
  image: string | undefined;
  uploadedImages: [];
  AllCategory: [];
  id: string;
  refresh: boolean;
  file: string;
  profileImageData: { data: string | null | undefined, content_type: string, filename: string };
  selectedCategory: ValueType<OptionTypeBase, false>;
  allCategories: { attributes: { [key: string]: string | number | object } }[];
  allSubCategories: { attributes: { [key: string]: string | number | object } }[];
  openLeftMenu: boolean;
  darkTheme: boolean;
  CourseInfo: { [key: string]: string | Lesson[] | File | object };
  ChapterInfo: { [key: string]: string | Lesson[] | File | object };
  CategoryInfo: { [key: string]: string | object };
  moduleContent: { [key: string]: string | File };
  allModules: { [key: string]: string | File }[];
  anchorEl: null | HTMLElement;
  publishModal: boolean;
  successModal: boolean;
  isAlertModalOpen: boolean;
  isSaveAndCloseModalOpen:boolean;
  publishType: string;
  imagePreview: string;
  videoPreview: string;
  uploadImage: File | null;
  uploadVideo: File | null;
  uploadDoc: File | null;
  suggestions:any;
  activeSuggestionIndex: number | null,
  showDropdown: boolean,
  dropdownPosition: { top: 0, left: 0 }
  content: string,
  selection: any,
  showSuggestions: boolean,
  suggestionBoxPosition: { top: number, left: number},
  open: boolean,
  addNewCourse: boolean;
  addCurriculum: boolean;
  enableChapter: boolean;
  enableListChapter: boolean;
  newChapterAdded: boolean;
  showNewLesson: boolean;
  coverImage: File | null;
  coverPhotoPreview: string;
  promotionalVideo: File | null;
  promotionalVideoPreview: string;
  loading: boolean;
  selectedTagColor: string;
  enableCurriculumChapter: boolean;
  enableLessonSection: boolean;
  lessonType: string;
  enableLessonType: boolean;
  profileData:ProfileData;
  showMainButton:boolean;
  allCourseDetails: Course;
  expandedList:{[key:string]:boolean};
  newChapterName:string;
  newLessonName:string;
  newSubLessonName:string;
  newOptionalText:string;
  newTextContent:string;
  currChapterIndex:number;
  currLessonIndex:number;
  currSubLessonIndex:number;
  addingSubLesson:boolean;
  enableContinue:boolean;
  currentStage:number;
  courseId:number;
  editLessonName:string;
  editLessonType:string;
  editSubLessonName:string;
  editSubLessonContent:string;
  editSubLessonFile:string;
  editChapterName:string;
  editOptionalText:string;
  editChapterIndex:number;
  editLessonIndex:number;
  editSubLessonIndex:number;  
  editChapterFlag:boolean;
  editLessonFlag:boolean;
  editSubLessonFlag:boolean;
  step: number;
  courseDetail: Course;
  isAddingChapter: boolean;
  isEditingChapter: boolean;
  editableIndex: number;
  isAddingLesson: boolean;
  isEditingLesson: boolean;
  editableLessonIndex: number;
  isSelectingSubLessonType: boolean;
  isAddingSubLesson: boolean;
  isEditingSubLesson: boolean;
  editableSubLessonIndex: number;
  quiz_id:string;
  final_test_id:string;
  upload_media_id:string;
  newCategory: string;
  newSubcategory: string;
  courses: WithId<Course & { chapters: { data: WithId<Chapter & { lessons: { data: WithId<Lessons & { sub_lessons: { data: (WithId<SubLesson>)[] } }>[] }}>[] } }>[];
  lessons: (Pick<Lessons, 'title'> & { id: string, sub_lessons_attributes: (SubLesson & { id: string })[] })[];
  chapterToCopy: { value: string; label: string; object?: string };
  subLessonToCopy: SubLesson;
  sublessonToCopy: { value: string; label: string; object?: string };
  media_files_urls:MediaUrls[];
  media_files:File[];
  doc_files:File[];
  doc_files_urls:MediaUrls[];
  combined_files:File[];
  isCombinedFiles:boolean;
  combined_files_urls:MediaUrls[];
  getDataByDelete:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PostCreationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPostItemCallId: string = "";
  apiGetCategoryCallID: string = "";
  apiGetSubCategoryCallId: string = "";
  apiGetIndCategoryCallId: string = "";
  apiGetIndSubCategoryCallId: string = "";
  apiGetCoursesCallId: string = "";
  apiGetCourseByIdCallId: string = "";
  PostApiCallId: string = "";
  DeleteApiCallId: string = "";;
  addpostApiCallId: string = "";
  uploadMediaApiCallId: string = "";
  updateMediaPostAPICallId: string = "";
  addCategoryApiCallId: string = "";;
  updatePostApiCallId: string = "";;
  quillRef:any;
  apiProfileGETCallId:string="";
  fileRefs:Record<string, RefObject<HTMLInputElement>>={};
  formikRef: any;
  chapterFormikRef: any;
  lessonFormikRef: any;
  subLessonFormikRef: any;
  deleteQuizApiCallId:string = "";
  deleteImageApiCallId:string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      token: "",
      PostData: [],
      name: "",
      description: "",
      price: "",
      currency: "$",
      category_id: "",
      image: "",
      id: "",
      uploadedImages: [],
      AllCategory: [],
      file: "",
      refresh: false,
      profileImageData: { data: null, content_type: "", filename: "" },
      selectedCategory: {},
      allCategories: [],
      allSubCategories: [],
      openLeftMenu: false,
      darkTheme: false,
      CourseInfo: {},
      ChapterInfo: {},
      CategoryInfo: {},
      moduleContent: {},
      allModules: [],
      anchorEl: null,
      publishModal: false,
      successModal: false,
      isAlertModalOpen: false,
      isSaveAndCloseModalOpen:false,
      publishType: '',
      imagePreview: '',
      videoPreview: '',
      uploadImage: null,
      uploadVideo: null,
      uploadDoc: null,
      addNewCourse: true,
      addCurriculum: false,
      enableChapter: false,
      enableListChapter: false,
      newChapterAdded: false,
      showNewLesson: false,
      activeSuggestionIndex: null,
      showDropdown: false,
      dropdownPosition: { top: 0, left: 0 },
      content: '',
      selection: null,
      suggestions: [],
      showSuggestions: false,
      suggestionBoxPosition: { top: 0, left: 0 },
      open: false,
      coverImage: null,
      coverPhotoPreview: '',
      loading: false,
      promotionalVideo: null,
      promotionalVideoPreview: '',
      selectedTagColor: "",
      enableCurriculumChapter: false,
      enableLessonSection: false,
      lessonType: "",
      enableLessonType: false,
      profileData: {
        id: "",
        type: "profile",
        attributes: {
          id: 0,
          first_name: null,
          last_name: null,
          email: "",
          date_of_birth: "",
          preferred_name: "",
          role_id: "",
          full_phone_number: "",
          employee_number: null,
          location: null,
          company_reference: null,
          completed_course: null,
          account_id: 0,
          image: null,
          group_id: 0,
          group_name: "",
          company_id: [],
          company_name: "",
          phone_number_verified: false,
          selected_theme: "",
          font_size: "",
        },
      },
      showMainButton:false,
      allCourseDetails:{
        course_name: "",
        duration: "",
        year: "",
        media_type: "",
        course_description: "",
        category_id: "",
        sub_category_id: "",
        language: "",
        level: "",
        price: "",
        tags: "",
        image: "",
        video: "",
        document: "",
        course_content_attributes: [],
        attributes:{
          final_tests: {
            data:[
              { id:""}
            ]
          },
          upload_media:{
            data:{}
          }
        },
      },
      expandedList:{},
      newChapterName:"",
      newLessonName:"",
      newSubLessonName:"",
      newOptionalText:"",
      newTextContent:"",
      currChapterIndex:0,
      currLessonIndex:0,
      currSubLessonIndex:0,
      addingSubLesson:false,
      enableContinue:false,
      currentStage:0,
      courseId: 0,
      editLessonName:"",
      editLessonType:"",
      editSubLessonName:"",
      editSubLessonContent:"",
      editSubLessonFile:"",
      editChapterName:"",
      editOptionalText:"",
      editChapterIndex:0,
      editLessonIndex:0,
      editSubLessonIndex:0,
      editChapterFlag:false,
      editLessonFlag:false,
      editSubLessonFlag:false,
      step: 0,
      courseDetail: {
        course_name: "",
        duration: "",
        year: "",
        media_type: "",
        course_description: "",
        category_id: "",
        sub_category_id: "",
        language: "",
        level: "",
        price: "",
        tags: "",
        image: "",
        video: "",
        document: "",
        course_content_attributes: [],
        attributes:{
          final_tests: {
            data:[
              { id:""}
            ]
          },
          upload_media:{
            data:{}
          }
        }
      },
      isAddingChapter: false,
      isEditingChapter: false,
      editableIndex: -1,
      isAddingLesson: false,
      isEditingLesson: false,
      editableLessonIndex: -1,
      isSelectingSubLessonType: false,
      isAddingSubLesson: false,
      isEditingSubLesson: false,
      editableSubLessonIndex: -1,
      quiz_id:"",
      final_test_id:"",
      upload_media_id:"",
      newCategory: "",
      newSubcategory: "",
      courses: [],
      lessons: [],
      chapterToCopy: { label: "Select course", value: "-1", object: undefined },
      sublessonToCopy: { label: "Select lesson", value: "-1", object: undefined },
      subLessonToCopy: { title: "", lesson_type: "" },     
      media_files:[ ],
      media_files_urls:[ ],
      doc_files:[],
      doc_files_urls:[],
      combined_files:[],
      combined_files_urls:[],
      isCombinedFiles:false,
      getDataByDelete:false,
    };
    this.quillRef = React.createRef();
    this.fileRefs = {
      "image" : React.createRef(),
      "video" : React.createRef(),
      "ppt" : React.createRef(),
      "pdfFile" : React.createRef(),
      "mediaFile" : React.createRef(),
      "audio" : React.createRef(),
      "mainImage" : React.createRef(),
      "mainVideo" : React.createRef(),
    }
    this.formikRef = React.createRef();
    this.chapterFormikRef = React.createRef();
    this.lessonFormikRef = React.createRef();
    this.subLessonFormikRef = React.createRef();
    // Customizable Area End
    console.disableYellowBox = true;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount() {
    const mode = await getStorageData("darkMode");
    if (mode === 'true') {
      this.setState({ darkTheme: true })
    }
    else {
      this.setState({ darkTheme: false })
    }

    if (this.quillRef.current) {
      const editor = this.quillRef.current.getEditor();
      const editorElement = editor.root;
      editorElement.setAttribute('spellcheck', 'true');
    }

    this.getAllCategory();
    this.getUserProfileData();
    const courseId=await localStorage.getItem("courseId")
    if(courseId){
      this.setState({step:1, courseId:Number(courseId)})
      this.getCourseById()
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson && responseJson.errors) {
        this.setState({ refresh: false, publishModal: false });
        const combinedError = responseJson.errors.reduce(
            (previousObject: object, currentObject: object) => {
                return Object.assign(previousObject, currentObject);
            },
        {});
        if (apiRequestCallId === this.addpostApiCallId) {
          this.formikRef.current.setErrors(combinedError);
          this.setState({ loading: false });
        } else {
          this.parseApiErrorResponse(responseJson.errors);
          this.parseApiCatchErrorResponse(responseJson.errors);
        }
      } else if (responseJson) {

        const mediaExtensions = ["png", "jpg", "jpeg"];
        const docExtensions = ["doc", "docx", "pdf"];
        
        const filterFilesByExtension = (files: FileType[], extensions: string[]): MediaUrls[] => {
          return files
            .filter((file: FileType) => {
              const extension = file.name.split(".").pop()?.toLowerCase() || "";
              return extensions.includes(extension);
            })
            .map((file) => ({
              id: file.id.toString(),
              url: file.url,
            }));
        };

        const allFiles: FileType[] = responseJson.data?.attributes?.media_and_documents_urls || responseJson.data?.data?.attributes.upload_media.data[0]?.attributes?.media_and_documents_urls  || [];
        
        const mediaFiles = filterFilesByExtension(allFiles, mediaExtensions);
        const docFiles = filterFilesByExtension(allFiles, docExtensions);        

        switch (apiRequestCallId) {
          case this.apiPostItemCallId:
            this.setState({ PostData: responseJson.data, refresh: false });
            break;
        
          case this.addpostApiCallId:
            this.setState({
              loading: false,
              step: 1,
              courseId: responseJson.course.data.id,
            });
            localStorage.setItem("courseId",responseJson.course.data.id)
            break;
          
          case this.uploadMediaApiCallId:
              if(this.state.isSaveAndCloseModalOpen){
                this.setState({isSaveAndCloseModalOpen:false, upload_media_id:responseJson.data.id})
                this.props.navigation.navigate("Dashboard")
              }else{
                this.setState({
                  loading: false,
                  upload_media_id:responseJson.data.id,
                  media_files_urls: mediaFiles,
                  doc_files_urls: docFiles,
                  combined_files_urls: responseJson.data.attributes?.media_and_documents_urls || [],
                  step:3, quiz_id:""
                })
              }
              break;
          case this.updateMediaPostAPICallId:
              if(this.state.isSaveAndCloseModalOpen){
                this.setState({isSaveAndCloseModalOpen:false})
                this.props.navigation.navigate("Dashboard")
              } else {
                if(!this.state.getDataByDelete){
                  this.setState({ step: 3, quiz_id: "" })
                }               
                this.setState({
                  loading: false,
                  media_files_urls: mediaFiles,
                  doc_files_urls: docFiles,
                  combined_files_urls: responseJson.data.attributes?.media_and_documents_urls || [],
                });
                
              }
              break;
          
          case this.updatePostApiCallId:
           
            this.setState({
              refresh: true,
              loading: false,
              step: this.state.step + 1,
              courseId: responseJson.data.data.id,
              upload_media_id: responseJson.data.data.attributes.upload_media.data[0]?.id || "",
              isCombinedFiles: responseJson.data.data.attributes.upload_media.data[0]?.id ? true : false,
              media_files_urls: mediaFiles,
              doc_files_urls: docFiles,
              combined_files_urls: responseJson.data.data.attributes.upload_media.data[0]?.attributes?.media_and_documents_urls || [],
            });
            break;
          case this.DeleteApiCallId:
            this.getPostData();
            break;

          case this.deleteImageApiCallId:
            this.uploadMediaApi();
            this.setState({getDataByDelete:true})
            break;
        
          case this.apiGetCategoryCallID:
            if (this.state.newCategory !== "") {
              const newCategoryId = responseJson.data.find((category: any) => category.attributes.name === this.state.newCategory)?.id || -1;
              this.handleCategoryChange(this.formikRef.current.values.category_id, newCategoryId)
              this.setState({ newCategory: "" })
            }
            this.setState({ allCategories: responseJson.data });
            break;
        
          case this.apiGetSubCategoryCallId:
            if (this.state.newSubcategory !== "") {
              const newSubategoryId = responseJson.data.find((subcategory: any) => subcategory.attributes.name === this.state.newSubcategory)?.id || -1;
              this.formikRef.current.setFieldValue('sub_category_id', newSubategoryId);
              this.setState({ newSubcategory: "" });
            }
            this.setState({ allSubCategories: responseJson.data });
            break;
        
          case this.apiGetIndCategoryCallId:
            this.setState({
              CourseInfo: {
                ...this.state.CourseInfo,
                category_name: responseJson.data?.attributes?.name,
              },
            });
            break;
        
          case this.apiGetIndSubCategoryCallId:
            this.setState({
              CourseInfo: {
                ...this.state.CourseInfo,
                sub_category_name: responseJson.data?.attributes?.name,
                sub_category: responseJson.data?.attributes?.id,
              },
            });
            break;        
          case this.apiProfileGETCallId:
            this.setState({ profileData: responseJson.data });
            break;
          case this.apiGetCoursesCallId:
            this.setState({ courses: responseJson.data?.data }); 
            break;
          case this.apiGetCourseByIdCallId:
            this.setState({ courseDetail: this.mapResponseToState(responseJson.data) })
            break;
        }         
      } else if (errorReponse) {
        this.setState({ refresh: false });
        this.parseApiErrorResponse(errorReponse);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }
  goToItemDetails(item:{attributes: { name:string, id:string}} | null, isEdit: boolean) {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      isEdit ? "PostCreation" : "PostDetails"
    );

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );

    raiseMessage.addData(getName(MessageEnum.PostDetailDataMessage), item);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  }
  editNavigation = (item:{attributes: { name:string, id:string}}) => {
    this.goToItemDetails(item, true);
  };
  navigateToDetails = (item:{attributes: { name:string, id:string}}) => {
    this.goToItemDetails(item, false);
  };
  getAllCategory = async (categoryName?: string) => {
    this.apiGetCategoryCallID = await this.apiCall({
      method2: configJSON.validationApiMethodType,
      contentType2: configJSON.validationApiContentType,
      endPoint2: `${configJSON.getAllCategoryEndPoint}${categoryName && categoryName !== "" ? "?category_name=" + categoryName + "&is_new=true" : ""}`,
    });
  }
  getUserProfileData = async () => {
    this.apiProfileGETCallId = await this.apiCall({
      method2: configJSON.validationApiMethodType,
      contentType2: configJSON.validationApiContentType,
      endPoint2: configJSON.profileGetURL,
    });
  }
  getPostData(): boolean {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostItemCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  delete(recordId: string) {
    this.setState({ refresh: true });
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.DeleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl + "/" + `${recordId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deletePostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.getPostData();
    return true;
  }
  getCourses = async () => {
    this.apiGetCoursesCallId = await this.apiCall({
      method2: configJSON.validationApiMethodType,
      contentType2: configJSON.validationApiContentType,
      endPoint2: configJSON.createPostEndPoint,
    });
  }
  getCourseById = async () => {
    this.apiGetCourseByIdCallId = await this.apiCall({
      method2: configJSON.validationApiMethodType,
      contentType2: configJSON.validationApiContentType,
      endPoint2: `${configJSON.createPostEndPoint}/${this.state.courseId}`,
    });
  }

  txtInputProductNameProps = {
    value: '',
    onChangeText: (text: string) => {
      this.setState({ name: text });
      this.txtInputProductNameProps.value = text;
    }
  };
  txtInputProductDiscripationProps = {
    value: '',
    onChangeText: (text: string) => {
      this.setState({ description: text });
      this.txtInputProductDiscripationProps.value = text;
    }
  };
  txtInputProductPriceProps = {
    value: '',
    onChangeText: (text: string) => {
      this.setState({ price: text });
      this.txtInputProductPriceProps.value = text;
    }
  };

  leftMenuHandle = () => {
    this.setState({ openLeftMenu: !this.state.openLeftMenu })
    setStorageData('open', JSON.stringify(!this.state.openLeftMenu))
  }
  handleTheme = () => {
    this.setState({ darkTheme: !this.state.darkTheme })
    setStorageData('darkMode', `${!this.state.darkTheme}`)
  }
  isEditingCurriculum = () => {
    return this.state.isAddingChapter || this.state.isEditingChapter || this.state.isAddingLesson || this.state.isEditingLesson || this.state.isSelectingSubLessonType
      || this.state.isAddingSubLesson || this.state.isEditingSubLesson;
  }
  handleBackToPreviousStep = () => {
    this.setState({ isAlertModalOpen: false, isSaveAndCloseModalOpen: false });
    if (this.state.step === 0) {
      this.props.navigation.goBack();
    } else {
      if (this.state.step !== 1 || !this.isEditingCurriculum()) {
          this.setState(prevState => ({ step: prevState.step - 1 }), () => {
            if (this.state.courseId !== 0) {
              this.getCourseById();
            }
          });
          this.setState({combined_files:[],doc_files:[], media_files:[]})
      } else {
        if (this.state.isAddingChapter || this.state.isEditingChapter || this.state.isAddingLesson || this.state.isEditingLesson || this.state.isSelectingSubLessonType) {
          this.setState({ 
            isAddingChapter: false,
            isEditingChapter: false,
            isAddingLesson: false,
            isEditingLesson: false,
            isSelectingSubLessonType: false,
          });
        }
        if (this.state.isAddingSubLesson || this.state.isEditingSubLesson) {
          if (this.state.isAddingSubLesson) {
            this.removeSubLesson(this.state.editableIndex, this.state.editableLessonIndex, this.state.editableSubLessonIndex);
            this.setState({ isSelectingSubLessonType: true })
          } 
          this.setState({ 
            isAddingSubLesson: false,
            isEditingSubLesson: false,
          });
        }
      }
    }
  }
  handleCategoryChange = async (curr: string, next: string) => {
    if (curr !== next) {
      this.formikRef.current.setFieldValue('category_id', next);
      this.apiGetSubCategoryCallId = await this.apiCall({
        method2: configJSON.validationApiMethodType,
        contentType2: configJSON.validationApiContentType,
        endPoint2:`${configJSON.getAllSubCategoryEndPoint}?category_id=${next}`
      });
    } else {
      this.formikRef.current.setFieldValue('category_id', "-1");
      this.setState({ allSubCategories: [] });
    }
  }
  handleCourseChapterChange = (valueObj: OnChangeProps) => {
    const { value, label, object, parent } = valueObj;
    if ( object === "0") {
      this.setState({ lessons: this.state.courseDetail.course_content_attributes[Number(value)]?.lessons_attributes.map((lesson, index) => ({
        ...lesson,
        id: index.toString(),
        sub_lessons_attributes: lesson.sub_lessons_attributes.map((subLesson, index) => ({
          ...subLesson,
          id: index.toString()
        }))
      })) });
    } else {
      if (!parent || !parent?.value) {
        this.setState({ lessons: [] })
      } else {
        const selectedCourse = this.state.courses.find(course => course.id === object);
        const selectedChapter = selectedCourse?.attributes.chapters.data.find(chapter => chapter.id === value);  
        this.setState({ lessons: selectedChapter ? selectedChapter?.attributes.lessons.data.map(lesson => ({
          ...lesson.attributes,
          id: lesson.id,
          sub_lessons_attributes: lesson.attributes.sub_lessons.data.map(({ id, attributes }) => ({
            id,
            ...attributes
          }))
        })) : []});
      }
    }
    this.setState({ chapterToCopy: { value, label, object }})
  }
  handleSubLessonSelect = (valueObj: OnChangeProps) => {
    const { value, label,  object, parent } = valueObj;
    if (!parent || !parent?.value) {
      this.setState({ subLessonToCopy: { title: "", lesson_type: "" } })
    } else {
      const selectedLesson = this.state.lessons.find(lesson => lesson.id === object);
      const selectedSubLesson = selectedLesson?.sub_lessons_attributes.find((_subLesson, index) => index.toString() === value);  
      this.setState({ subLessonToCopy: selectedSubLesson || { title: "", lesson_type: "" } }, () => {
        if (this.state.subLessonToCopy.files) {
          this.subLessonFormikRef.current.setFieldValue("files", [this.state.subLessonToCopy.files]);
        }
      });
    }  
    this.setState({ sublessonToCopy: { value, label, object }});
  }
  handleKeyDownRestriction = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, blackList: string[]) => {
    if (!/\d/.test(event.key) && !(event.shiftKey && /\d/.test(event.key)) && !blackList.includes(event.key) ) {
      event.preventDefault();
    }
  }
  handleOpenAlertModal = () => {
    if (this.state.step !== 1 || this.state.isAddingChapter || this.state.isEditingChapter || this.state.isAddingLesson || this.state.isEditingLesson
      || this.state.isAddingSubLesson || this.state.isEditingSubLesson || this.state.isSelectingSubLessonType
    ) {
      this.setState({ isAlertModalOpen: true });
    } else {
      this.handleBackToPreviousStep();
    }
  }
  handleOpenSaveAndCloseAlertModal = () => {
    this.setState({ isSaveAndCloseModalOpen: true });
  }
  handleCloseSaveAndCloseAlertModal = () => {
    this.setState({ isSaveAndCloseModalOpen: false });
  }
  handleCloseAlertModal = () => {
    this.setState({ isAlertModalOpen: false });
  }
  closeModals = () => {
    this.setState({ publishModal: false, successModal: false, publishType: "" })
  }
  changePublishType = (event: RadioChangeEvent) => {
    this.setState({ publishType: event.target.value, CourseInfo: { ...this.state.CourseInfo, ['publish_type']: event.target.value } })
  }
  apiCall = async (data: { [key: string]: any }) => {
    const { method2, endPoint2, body2, type2, contentType2 } = data;
    let apiBody = body2;
    if (type2 === '') {
      apiBody = JSON.stringify(body2);
    }
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": contentType2,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint2
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMessage),
      configJSON.baseURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method2
    );
    body2 &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        apiBody
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };
  mapResponseToState = (responseData: any) => {
    const { attributes } = responseData
    const category_id = attributes.category.id;
    const sub_category_id = (attributes.sub_category && attributes.sub_category?.id) || "-1";
    const duration = attributes.time_for_supervisor_to_review || "";
    const video = attributes.video_url ? attributes.video_url : "";

    const chapters = attributes.chapters.data 
      ? attributes.chapters?.data.map(
        (chapter: WithId<Omit<Chapter, "lessons_attributes"> 
          & { lessons: { data: WithId<Omit<Lessons, "sub_lessons_attributes"> 
            & { sub_lessons: { data: WithId<SubLesson>[] } }>[] }}>
        ) => ({
          id: chapter.id,
          title: chapter.attributes.title,
          lessons_attributes: chapter.attributes.lessons.data.map((lesson: WithId<Omit<Lessons, "sub_lessons_attributes"> & { sub_lessons: { data: WithId<SubLesson>[] } }>) => ({
            id: lesson.id,
            title: lesson.attributes.title,
            sub_lessons_attributes: lesson.attributes.sub_lessons.data.map((subLesson: WithId<SubLesson>) => ({
              id: subLesson.id,
              title: subLesson.attributes.title,
              content: subLesson.attributes.content || "",
              files: subLesson.attributes.files,
              lesson_timer: subLesson.attributes.lesson_timer,
              optional_text: subLesson.attributes.optional_text,
        }))
      }))
    })) : [];
    
    return {
      ...responseData,
      category_id,
      sub_category_id,
      tags: attributes.tags || "",
      duration,
      start_date: attributes.start_date || "",
      end_date: attributes.end_date || "",
      video,
      course_content_attributes: chapters
    }
  }
  checkSpelling = async (content:any) => {
    try {
      const response = await axios.get('https://api.textgears.com/spelling', {
        params: {
          key: 'yJ85cVqAAMVkya7O',
          text: content
        }
      });

  const suggestions1 = response.data.response.errors.map((error:any) => ({
        word: error.bad,
        suggestions: error.better,
      }));
  this.setState({suggestions: suggestions1});
      return suggestions1;
    } catch (error) {
    }
  };
  handleWordClick = (index: number, event: any) => {
    const rect = event.target.getBoundingClientRect();
    this.setState({
      activeSuggestionIndex: index,
      showDropdown: true,
      dropdownPosition: {
        top: rect.bottom,
        left: rect.right
      }
    });
  };
  handleSelectionChange = (range:any) => {
    if (range && range.length > 0) {
      const quill = this.quillRef.current.getEditor();
      const selectedText = quill.getText(range.index, range.length);
      this.checkSpelling(selectedText);

      const bounds = quill.getBounds(range.index, range.length);

      const suggestionBoxPosition = {
      top: bounds.top + bounds.height + window.scrollY+290,
      left: bounds.left + window.scrollX+150
      };

      this.setState({
        selection: range,
        showSuggestions: true,
        suggestionBoxPosition
      });
    } else {
      this.setState({ showSuggestions: false });
    }
  };
  handleSuggestionClick = (suggestion:any) => {
    const { selection } = this.state;
    const quill = this.quillRef.current.getEditor();
    if (selection) {
      quill.deleteText(selection.index, selection.length);
      quill.insertText(selection.index, suggestion);
      quill.setSelection(selection.index + suggestion.length);
    }

    this.setState({ showSuggestions: false });
  };
  handleOpen = () => {
    this.setState({open: true})
  };
  handleClose = () => {
    this.setState({open: false})
  };
  handleCreateCourse = async (values: any) => {
    this.setState({ courseDetail: { ...this.state.courseDetail, ...values }, loading: true });
    if (this.state.courseId === 0) {
      const formData = FormDataBuilder(values, undefined, 'course');
      this.addpostApiCallId = await this.apiCall({
        method2: configJSON.PostAPiMethod,
        endPoint2: configJSON.createPostEndPoint,
        body2: formData
      });
    } else {
      const formData = FormDataBuilder({
        course_name: values.course_name,
        course_description: values.course_description,
        video: values.video,
        image: typeof values.image !== "string" && values.image,
        category_id: values.category_id,
        sub_category_id: values.sub_category_id,
        time_for_supervisor_to_review: values.time_for_supervisor_to_review,
        start_date: values.start_date,
        end_date: values.end_date,
        tags: values.tags,
        tag_color: values.tag_color
      }, undefined, 'course');
      this.updatePostApiCallId = await this.apiCall({
        method2: configJSON.PutAPIMethod,
        endPoint2: `${configJSON.createPostEndPoint}/${this.state.courseId}`,
        body2: formData
      });
    }
  }
  handleCreateCategory = async (value: string) => {
    this.setState({ newCategory: value }, () => this.getAllCategory(value));
  }
  handleCreateSubcategory = (parentId: string, value: string) => {
    this.setState({ newSubcategory: value }, async () => {
      this.apiGetSubCategoryCallId = await this.apiCall({
        method2: configJSON.validationApiMethodType,
        contentType2: configJSON.validationApiContentType,
        endPoint2:`${configJSON.getAllSubCategoryEndPoint}?category_id=${parentId}&sub_category_name=${value}`
      });
    });
  }
  handleCancelChapterFormClick = () => {
    this.chapterFormikRef.current.resetForm({ values: { title: "" }});
    this.setState({ isAddingChapter: false, isEditingChapter: false });
  }
  handleEditChapterClick = (index: number) => {
    this.setState({ isEditingChapter: true, editableIndex: index }, () => {
      this.chapterFormikRef.current.setFieldValue('title', this.state.courseDetail.course_content_attributes[this.state.editableIndex].title);
    });
  }
  handleAddSubLessonClick = (chapterIndex: number, lessonIndex: number) => {
    this.setState({
      isSelectingSubLessonType: true,
      editableIndex: chapterIndex,
      editableLessonIndex: lessonIndex,
      quiz_id:"",
    })

  }
  handleAddLessonClick = (chapterIndex: number) => {
    this.setState({ isAddingLesson: true, editableIndex: chapterIndex });
  }
  handleEditLessonClick = (chapterIndex: number, lessonIndex: number) => {
    this.setState({ isEditingLesson: true, editableIndex: chapterIndex, editableLessonIndex: lessonIndex });
  }
  handleEditSubLessonClick = (chapterIndex: number, lessonIndex: number, subLessonIndex: number) => {
    this.setState({ 
      isEditingSubLesson: true, 
      editableIndex: chapterIndex, 
      editableLessonIndex: lessonIndex, 
      editableSubLessonIndex: subLessonIndex, 
      quiz_id:this.state.courseDetail.course_content_attributes[chapterIndex].lessons_attributes[lessonIndex].sub_lessons_attributes[subLessonIndex].optional_text || "",
    });
  }
  handleChapter = (values: any) => {
    let chapterList = new Array();
    chapterList = this.state.editableIndex < 0
      ? [ ...this.state.courseDetail.course_content_attributes, { title: values.title, lessons_attributes: [] } ] 
      : [
        ...this.state.courseDetail.course_content_attributes.slice(0, this.state.editableIndex),
        { ...this.state.courseDetail.course_content_attributes[this.state.editableIndex], title: values.title },
        ...this.state.courseDetail.course_content_attributes.slice(this.state.editableIndex +1 , this.state.courseDetail.course_content_attributes.length),
      ];
    this.setState({ 
      courseDetail: { ...this.state.courseDetail, course_content_attributes: chapterList },
      isAddingChapter: false,
      isEditingChapter: false,
      editableIndex: -1,
    });
  }
  removeChapter = (index: number) => {
    const updatedCourse = this.state.courseDetail;
    updatedCourse.course_content_attributes.splice(index, 1);
    this.setState({ courseDetail: updatedCourse });
  }
  updateLesson = (values: Partial<Lesson>) => {
    const chapterList = this.state.courseDetail.course_content_attributes;
    const currentChapterIndex = this.state.editableIndex;
    const lessonList = this.state.courseDetail.course_content_attributes[currentChapterIndex].lessons_attributes;
    const currentLessonIndex = this.state.editableLessonIndex;

    const savedToChapterList: Chapter[] = [
      ...chapterList.slice(0, currentChapterIndex),
      { 
        ...chapterList[currentChapterIndex], 
        lessons_attributes: [
          ...lessonList.slice(0, currentLessonIndex),
          {
            ...lessonList[currentLessonIndex], 
            ...values
          },
          ...lessonList.slice(currentLessonIndex + 1, lessonList.length)
        ]
      },
      ...chapterList.slice(currentChapterIndex + 1, chapterList.length)
    ]

    this.setState({
      isEditingLesson: false,
      editableIndex: -1,
      editableLessonIndex: -1,
      courseDetail: { ...this.state.courseDetail, course_content_attributes: savedToChapterList },
    })   
  }
  removeLesson = (index: number, lessonIndex: number) => {
    this.setState(prevState => {
      const chapterList = prevState.courseDetail.course_content_attributes;
      const lessonList = chapterList[index].lessons_attributes;
      const updatedLessonList = [...lessonList.slice(0, lessonIndex), ...lessonList.slice(lessonIndex + 1, lessonList.length)];
      const updatedChapterList = [
        ...chapterList.slice(0, index),
        { ...chapterList[index], lessons_attributes: updatedLessonList },
        ...chapterList.slice(index + 1, chapterList.length)
      ];
      return {
        courseDetail: { ...prevState.courseDetail, course_content_attributes: updatedChapterList }
      }
    });
  }
  updateSubLesson = (values: any) => {
    const chapterList = this.state.courseDetail.course_content_attributes;
    const currentChapterIndex = this.state.editableIndex;
    const lessonList = this.state.courseDetail.course_content_attributes[currentChapterIndex].lessons_attributes;
    const currentLessonIndex = this.state.editableLessonIndex;
    const subLessonList = this.state.courseDetail.course_content_attributes[currentChapterIndex].lessons_attributes[currentLessonIndex].sub_lessons_attributes;
    const currentSubLessonIndex = this.state.editableSubLessonIndex;

    const savedToChapterList: Chapter[] = [
      ...chapterList.slice(0, currentChapterIndex),
      { 
        ...chapterList[currentChapterIndex], 
        lessons_attributes: [
          ...lessonList.slice(0, currentLessonIndex),
          {
            ...lessonList[currentLessonIndex], 
              sub_lessons_attributes: [
              ...subLessonList.slice(0, currentSubLessonIndex),
              { ...subLessonList[currentSubLessonIndex], ...values },
              ...subLessonList.slice(currentSubLessonIndex + 1, subLessonList.length)
            ] 
          },
          ...lessonList.slice(currentLessonIndex + 1, lessonList.length)
        ]
      },
      ...chapterList.slice(currentChapterIndex + 1, chapterList.length)
    ]
    
    return savedToChapterList;
  }
  resetCopyLessonForm = () => {
    this.setState({
      chapterToCopy: { label: "Select course", value: "-1", object: undefined },
      sublessonToCopy: { label: "Select lesson", value: "-1", object: undefined },
      subLessonToCopy: { title: "", lesson_type: "" },
    })
  }
  updateSubLessonAndContinue = (values: any) => {
    const savedToChapterList = this.updateSubLesson(values);
    this.setState({quiz_id:""})
    
    this.resetCopyLessonForm();

    this.setState({ 
      courseDetail: { ...this.state.courseDetail, course_content_attributes: savedToChapterList },
      isAddingSubLesson: false,
      isEditingSubLesson: false,
      isSelectingSubLessonType: true,
      editableSubLessonIndex: -1,
    });
  }
  updateSubLessonAndClose = (values: SubLesson) => {
    const savedToChapterList = this.updateSubLesson(values);

    this.resetCopyLessonForm();
    this.setState({quiz_id:values.optional_text || ""})

    this.setState({ 
      courseDetail: { ...this.state.courseDetail, course_content_attributes: savedToChapterList },
      isAddingSubLesson: false,
      isEditingSubLesson: false,
      editableIndex: -1,
      editableLessonIndex: -1,
      editableSubLessonIndex: -1,
    });
  }

  handleDeleteQuiz =  async (id:string) =>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteQuizApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postQuizURL + "/" + `${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.Delete
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  removeSubLesson = (index: number, lessonIndex: number, subLessonIndex: number) => {
    if(this.state.quiz_id){
      this.handleDeleteQuiz(this.state.quiz_id)
    }
    this.setState(prevState => {
      const chapterList = prevState.courseDetail.course_content_attributes;
      const lessonList = chapterList[index].lessons_attributes;
      const subLessonList = chapterList[index].lessons_attributes[lessonIndex].sub_lessons_attributes;
      const updatedSubLessonList = [...subLessonList.slice(0, subLessonIndex), ...subLessonList.slice(subLessonIndex + 1, subLessonList.length)];
      const updatedChapterList = [
        ...chapterList.slice(0, index),
        { 
          ...chapterList[index], 
          lessons_attributes: [
            ...lessonList.slice(0, lessonIndex),
            { ...lessonList[lessonIndex], sub_lessons_attributes: updatedSubLessonList },
            ...lessonList.slice(lessonIndex + 1, lessonList.length)
          ] 
        },
        ...chapterList.slice(index + 1, chapterList.length)
      ];
      return {
        courseDetail: { ...prevState.courseDetail, course_content_attributes: updatedChapterList }
      }
    });
  }
  handleUpdateCurriculum = async () => {
    const formData = FormDataBuilder({ chapters_attributes: this.state.courseDetail.course_content_attributes  }, undefined, 'course');
    this.setState({ loading: true });
    this.updatePostApiCallId = await this.apiCall({
      method2: configJSON.PutAPIMethod,
      endPoint2: `${configJSON.createPostEndPoint}/${this.state.courseId}`,
      body2: formData
    });
  }
  handleLesson = (lessonTitle: string, subLessonType: string) => {
    if (!this.state.isAddingLesson) {
      this.handleSubLesson({ lesson_type: subLessonType })
    } else {
      const currentChapterIndex = this.state.editableIndex;
      const chapterList = this.state.courseDetail.course_content_attributes;
      const lessonList = chapterList[currentChapterIndex].lessons_attributes;
      this.setState({ 
        isAddingLesson: false, 
        courseDetail: { 
          ...this.state.courseDetail, 
          course_content_attributes: [
            ...chapterList.slice(0, currentChapterIndex),
            { 
              ...chapterList[currentChapterIndex], 
              lessons_attributes: [
                ...lessonList, 
                { 
                  title: lessonTitle, 
                  sub_lessons_attributes: [{ title: "", lesson_type: subLessonType }]
                }
              ] 
            },
            ...chapterList.slice(currentChapterIndex + 1, chapterList.length),
          ] 
        },
        editableLessonIndex: lessonList.length,
        editableSubLessonIndex: 0,
        isAddingSubLesson: true
      }, () => this.getCourses())
    }
  }
  handleSubLesson = (payload: Pick<SubLesson, 'lesson_type'>) => {
    const currentChapterIndex = this.state.editableIndex;
    const currentLessonIndex = this.state.editableLessonIndex;
    const chapterList = this.state.courseDetail.course_content_attributes;
    const lessonList = chapterList[currentChapterIndex].lessons_attributes;
    const subLessonList = chapterList[currentChapterIndex].lessons_attributes[currentLessonIndex].sub_lessons_attributes;
    this.setState({ 
      isSelectingSubLessonType: false, 
      courseDetail: { 
        ...this.state.courseDetail, 
        course_content_attributes: [
          ...chapterList.slice(0, currentChapterIndex),
          { 
            ...chapterList[currentChapterIndex], 
            lessons_attributes: [
              ...lessonList.slice(0, currentLessonIndex), 
              { 
                ...lessonList[currentLessonIndex],
                sub_lessons_attributes: [...subLessonList, { title: "", lesson_type: payload.lesson_type }]
              },
              ...lessonList.slice(currentLessonIndex + 1, lessonList.length)
            ],
          },
          ...chapterList.slice(currentChapterIndex + 1, chapterList.length),
        ] 
      },
      editableSubLessonIndex: subLessonList.length,
      isAddingSubLesson: true
    }, () => this.getCourses())
  }
  toggleExpandedList = (chapterIndex:number,lessonIndex:number) => {
    const key = `${chapterIndex}-${lessonIndex}`;
    this.setState(prevState => ({
      expandedList: {
        ...prevState.expandedList,
        [key]: !prevState.expandedList[key],
      },
    }))
  }
  isEmptyRichText = (html:any) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    const strippedContent = div.textContent || div.innerText || '';

    return strippedContent.trim().length === 0;
  };
  
  isDateDisabled = (current: moment.Moment | undefined): boolean => {
    return current ? current < moment().startOf("day") : false;
  };
  getFile = (file: File[] | File | null) => {
    if (Array.isArray(file)) {
      return file[0];
    }
    return file;
  }

  handleUploadMedia = () =>{
    this.setState({isCombinedFiles:true})
  }

  setFieldMediaValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files: File[] = Array.from(event.target.files || []);

    this.setState(prevState => {
      const newMediaFiles = [...(prevState.media_files || []), ...files];
      const newCombinedFiles = [...new Set([...prevState.combined_files, ...files])];

      return {
        media_files: newMediaFiles,
        combined_files: newCombinedFiles
      };
    });
  };

  setFieldDocValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files: File[] = Array.from(event.target.files || []);

    this.setState(prevState => {
      const newDocFiles = [...(prevState.doc_files || []), ...files];
      const newCombinedFiles = [...new Set([...prevState.combined_files, ...files])];

      return {
        doc_files: newDocFiles,
        combined_files: newCombinedFiles
      };
    });
  };

  deleteImage = async (imageIndex:string) => {
    const header = {
      token: await getStorageData("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteImageApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.uploadMedia}/${this.state.upload_media_id}/media_image_delete?image_id=${imageIndex}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deletePostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  removeImage = (index: number, imgId: string) => {
    if (imgId) {
      this.deleteImage(imgId);
    } else {
    this.setState(prevState => {
      const fileToRemove = prevState.media_files[index];
      return {
        media_files: prevState.media_files.filter((_, i) => i !== index),
        combined_files: prevState.combined_files.filter(file => file !== fileToRemove)
      };
    },()=>{
        if(this.state.combined_files.length <= 0){
          this.setState({isCombinedFiles:false})
        }
      }
    );
  }
  };

  removeDocFile = (index: number, docIndex:string) => {
    if (docIndex) {
      this.deleteImage(docIndex);
    } else {
    this.setState(prevState => {
      const fileToRemove = prevState.doc_files[index];
      return {
        doc_files: prevState.doc_files.filter((_, i) => i !== index),
        combined_files: prevState.combined_files.filter(file => file !== fileToRemove)
      };
    },()=>{
        if(this.state.combined_files.length <= 0){
          this.setState({isCombinedFiles:false})
        }
      }
    );
    }
  };

  handleBrowseClick = () => {
    const fileInput = document.getElementById("uploadedMedia");
    if (fileInput) {
      fileInput.click();
    }
  };

  uploadMediaApi = async () =>{
    let formData = new FormData();
    this.setState({loading:true})
   
    formData.append("media[course_id]", this.state.courseId.toString());
    this.state.combined_files.forEach((file,index) => {
      formData.append(`media[document_files][${index}]`, file);
    });
       
    const apiCallConfig = {      
      method2: this.state.upload_media_id ? configJSON.PutAPIMethod : configJSON.PostAPiMethod,
      endPoint2: this.state.upload_media_id ? `${configJSON.uploadMedia}/${this.state.upload_media_id}?course_id=${this.state.courseId}` : configJSON.uploadMedia,
      body2: formData
    };

    const apiCallId = this.state.upload_media_id ? "updateMediaPostAPICallId" : "uploadMediaApiCallId";
    this[apiCallId] = await this.apiCall(apiCallConfig);

  }

  handleBackToDashboard = () => {
    this.uploadMediaApi()
  }

  openFinalTest = () => {

    this.setState({getDataByDelete:false})
    if(this.state.combined_files.length<=0){
      this.setState({step:3, quiz_id:"",final_test_id:this.state.courseDetail.attributes.final_tests?.data[0]?.id})
    }else{
      this.uploadMediaApi()
    }
   
  }

  handleDragEnd = (result:any) => {
    if (!result.destination) return;
  
    const items = Array.from(this.state.courseDetail.course_content_attributes);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
  
    this.setState((prevState) => ({
      courseDetail: {
        ...prevState.courseDetail,
        course_content_attributes: items,
      }
    }));
  };

  handleDragSublessonEnd = (result:any, chapterIndex:number, lessonIndex:number) => {
    const { source, destination } = result;
  
    if (!destination) return; 
  
    const updatedLessons = [...this.state.courseDetail.course_content_attributes];
    const subLessons = [...updatedLessons[chapterIndex].lessons_attributes[lessonIndex].sub_lessons_attributes];
  
    const [movedItem] = subLessons.splice(source.index, 1);
    subLessons.splice(destination.index, 0, movedItem);
  
    updatedLessons[chapterIndex].lessons_attributes[lessonIndex].sub_lessons_attributes = subLessons;
  
    this.setState({
      courseDetail: {
        ...this.state.courseDetail,
        course_content_attributes: updatedLessons
      }
    });
  };
  
  getSubLessonType = (type: string) => {
    return type === "pdf" ? type.toUpperCase() : type;
  }
  // Customizable Area End
}
