import React from "react";
// Customizable Area Start
import {
  ScrollView,
  SafeAreaView,
  StyleSheet,
} from "react-native";
import PostCreationController, { Chapter, ChapterSchema, ICopyLessonSectionProps, ITitleProps, Lessons, LessonSchema, LessonTypes, Props, SubLesson, SubLessonSchema, validationSchema,MediaUrls } from "./PostCreationController.web";
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MenuIcon from '@material-ui/icons/Menu';
import { ArrowRight, ArrowRightAlt, ArrowUpward, DragIndicator, Edit } from "@material-ui/icons";
import { Box, Grid, Typography, styled, FormControlLabel } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { Formik } from "formik";
import { All, allBlack, group, groupBlack, iIcon, copyIcon, addIcon,smallBin,smallEdit, handbrakeIcon, addFileIcon, clipIcon, qrCodeIcon, lightAlert, graphicStyle, addFileLibraryIcon, fileIcon, backIcon } from "../src/assets";
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import { BackButton, NextButton, darkTheme, lightTheme } from "../../email-account-registration/src/EmailAccountRegistration.web";
import { DatePicker, Radio } from "antd";
import { FieldModal, ModalHeading, ModalLine, ModalSubHead } from "../../customisableuserprofiles/src/CustomisableUserProfiles.web";
import Loader from "../../../components/src/Loader.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import moment from "moment";
import { deleteIcon } from "../../customisableuserprofiles/src/assets";
import TextInput from "../../utilities/src/TextInput";
import RichTextEditor from "../../utilities/src/RichTextEditor";
import Button from "../../utilities/src/Button";
import CustomLabel from "../../utilities/src/Label";
import IconButton from "../../utilities/src/IconButton";
import AssessmentTest from "../../assessmenttest/src/AssessmentTest.web";
import CustomSwitch from "../../utilities/src/Switch";
import ContextMenu from "../../utilities/src/ContextMenu";
import FilePreviewCard from "../../utilities/src/FIlePreviewCard";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Modal from "../../utilities/src/Modal";
import FileUploadV2 from "../../utilities/src/FileUpload-v2.web";
import CustomisedSelect from "../../utilities/src/CustomisedSelect";

import { Checkbox as CustomCheckbox } from "../../../components/src/CustomCheckBox.web";
import CourseFeedback from "./CourseFeedback.web";

const ConfigJSON = require('./config.js')
// Customizable Area End

export default class PostCreation extends PostCreationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  AlertModal = () => (
    <Modal 
      data-test-id="alertModal"
      open={this.state.isAlertModalOpen} 
      onClose={this.handleCloseAlertModal} 
      contentProps={{
        imageUrl: lightAlert,
        text: " If you go back now, any added data will be lost.",
        subText: "Are you sure you want to proceed?"
      }}
      confirmText="Go Back"
      confirmable
      onConfirm={this.handleBackToPreviousStep}
    />
  )
  SaveAndCloseModal = () => (
    <Modal 
      data-test-id="alertModal"
      open={this.state.isSaveAndCloseModalOpen} 
      onClose={this.handleCloseSaveAndCloseAlertModal} 
      contentProps={{
        imageUrl: lightAlert,
        text: "Your progress will be saved, and you can complete the form later.",
      }}
      confirmText="Save and close"
      confirmable
      onConfirm={this.handleBackToDashboard}
    />
  )
  publishModal = () => (
    <FieldModal
      open={this.state.publishModal}
      onCancel={this.closeModals}
      title={
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ModalHeading variant="h5">Publish</ModalHeading>
          <ModalLine style={{ width: '150px' }} />
          <ModalSubHead variant="subtitle2">Publish info</ModalSubHead>
          <ModalLine style={{ width: '150px' }} />
        </div>}
      centered
      closable={true}
      footer={null}
      destroyOnClose
      width={769}
      style={{ paddingBottom: '40px' }}
    >
      <Radio.Group
        data-test-id='changePublishType'
        value={this.state.publishType}
        onChange={this.changePublishType}
        style={{ width: "100%" }}
      >
        <div style={{ display: 'flex' }}>
          <ArrowBackIosIcon style={{ marginRight: "200px", marginLeft: '20px' }} onClick={this.closeModals} />
          <ModalHeading variant="h5">where to publish</ModalHeading>
        </div>
        <FormControlLabel
          value='all_companies'
          control={<Radio />}
          label={<RadioDiv>
            <img src={this.state.darkTheme ? allBlack : All} />
            <div>
              <RadioOptionHead>All</RadioOptionHead>
              <RadioOptionSubHead>The reminder will be sent to all companies “worker”.</RadioOptionSubHead>
            </div>
          </RadioDiv>}
          labelPlacement="start"
        />
        <FormControlLabel
          value='all_groups'
          control={<Radio />}
          label={<RadioDiv>
            <img src={this.state.darkTheme ? groupBlack : group} />
            <div>
              <RadioOptionHead>All Groups</RadioOptionHead>
              <RadioOptionSubHead>The Notification will be sent to all groups of all worker.</RadioOptionSubHead>
            </div>
          </RadioDiv>}
          labelPlacement="start"
        />
        <FormControlLabel
          value='specific_groups'
          control={<Radio />}
          label={<RadioDiv>
            <img src={this.state.darkTheme ? groupBlack : group} />
            <div>
              <RadioOptionHead>Specific Groups</RadioOptionHead>
              <RadioOptionSubHead>The Notification will be sent to specific groups.</RadioOptionSubHead>
              <RadioOptionSubHead>Ibrahim, Ibrahim1 and 3 others</RadioOptionSubHead>
            </div>
          </RadioDiv>}
          labelPlacement="start"
        />
      </Radio.Group>
      <BackButton data-test-id="closeModalButton" onClick={this.closeModals}>Back</BackButton>
      <NextButton
        data-test-id="addCourseButton"
        style={{ width: '65%', marginLeft: '10px', color: '#FFF' }}
        onClick={this.handleCreateCourse}
        disabled={!this.state.publishType}
      >
        Send
      </NextButton>
    </FieldModal>
  )
  renderStepper = () => (
    <StepperContainer>
      {ConfigJSON.PostSteps.map((label: string, index: number) => (
        <StepperItem
          key={index}
          style={{
            borderBottom: this.state.step >= index ? "2px solid #1A469C" : this.state.darkTheme ? "2px solid #D0DBEB" : "2px solid #475569",
            color: this.state.step >= index ? "#1A469C" : this.state.darkTheme ? '#D0DBEB' : '#475569',
          }}
        >
          {label}
        </StepperItem>
      ))}
    </StepperContainer>
  )
  renderCourseInfoSection = (props: ITitleProps) => {
    const { heading, subHeading, enableOfflineDownload = false, actionButton } = props;

    return (
      <> 
        <CourseInfoSection style={{ justifyContent: enableOfflineDownload ? 'space-between' : 'flex-start', alignItems: "center", gap: '12px' }}>
          <RowStack style={{ gap: '12px', alignItems: 'center' }}>
            <BackIconButton 
              data-test-id="backToPrevStepBtn"
              onClick={this.handleOpenAlertModal} 
              style={{ borderRadius: "30px", backgroundColor: "#FFF", border: "none" }}
            >
              <img src={backIcon} />
            </BackIconButton>
            <CourseInfoTitle>{heading}</CourseInfoTitle>
            <hr style={{ color: "#D7D7D780", width:  '240px', height: "2px" }} />
          </RowStack>
          {enableOfflineDownload && (
            <ColStack style={{ alignItems: "center" }}>
              <CourseTitleDescription style={{ width: "100%"}}>Offline Download</CourseTitleDescription>
              <CustomSwitch />
            </ColStack>
          )}
        </CourseInfoSection>
        <RowStack style={{ justifyContent: 'space-between', alignItems: "flex-end", paddingRight: "10px" }}>
          {subHeading && subHeading !== "" && (
            <CourseTitleSection>
              <RowStack style={{ width: "24px", height: "24px", alignItems:"center", justifyContent:"center" }}>
                <img src={iIcon} style={{ width:"17px", height: "17px" }} />
              </RowStack>
              <AddTypo>{subHeading}</AddTypo>
            </CourseTitleSection>
          )}
          {actionButton}
        </RowStack>
      </>
    )
  }
  renderCourseSetting = () => (
    <Formik
      initialValues={this.state.courseId ? this.state.courseDetail : ConfigJSON.courseSettingInitialValues}
      validationSchema={validationSchema}
      onSubmit={this.handleCreateCourse}
      data-test-id="create-course-formik"
      innerRef={f => this.formikRef.current = f}
    >
      {({ isValid, touched, values, errors, handleChange, handleSubmit, setFieldValue }) => {
        const categories = this.state.allCategories?.map(item => ({ value: item.attributes.id.toString(), label: item.attributes.name as string }));
        const subcategories = this.state.allSubCategories?.map(item => ({ value: item.attributes.id.toString(), label: item.attributes.name as string }));

        return (
          <form data-test-id="form" onSubmit={handleSubmit} style={{ flex: 1 }}>
            <Grid container style={{ height: '100%', width: "100%", flexWrap: "nowrap" }}>
              <LeftSection item xs={8} md={8} lg={8} >
                {this.renderStepper()}
                <ContainerOuter>
                  <ContainerWithBg>
                    <CourseInfoSection>
                      <RowStack style={{ gap: '12px', alignItems: 'center' }}>
                      <BackIconButton 
                        data-test-id="backToDashboardBtn"
                        variant="outline" 
                        onClick={this.handleOpenAlertModal}
                      >
                        <img src={backIcon} />
                      </BackIconButton>
                        <CourseInfoTitle>Course Info</CourseInfoTitle>
                        <hr style={{ color:"#D7D7D780", width: '310px', height:"1px" }} />
                      </RowStack>
                      <ColStack style={{ alignItems: "center" }}>
                        <CustomLabel style={{ fontWeight: 700 }}>Offline Download</CustomLabel>
                        <CustomSwitch 
                          data-test-id="downloadedSwitch"
                          value={values.downloaded} 
                          onChange={value => setFieldValue("downloaded", value)} 
                        />
                      </ColStack>
                    </CourseInfoSection>
                    <CourseDetailsSection>
                      <Box mb="22px">
                        <TextInput 
                          data-test-id="txtCourseTitle" 
                          name="course_name" 
                          required
                          label="Course Title" 
                          labelStyle={{ fontWeight: 700 }} 
                          value={values.course_name} 
                          onChange={handleChange} 
                          errorText={touched?.course_name && errors?.course_name ? errors?.course_name.toString() : ""}
                        />
                      </Box>
                      <Box mb="51px">
                        <RichTextEditor
                          data-test-id="txtDescription" 
                          label="Course Description" 
                          required
                          name="course_description"
                          labelStyle={{ fontWeight: 700 }} 
                          value={values.course_description} 
                          onChange={(e) => {
                            if (this.isEmptyRichText(e)) {
                              setFieldValue('course_description', '');
                            } else {
                              setFieldValue('course_description', e);
                            }
                          }}
                          errorText={touched?.course_description && errors?.course_description ? errors?.course_description.toString() : ""}
                        />
                      </Box>
                      <CoverUploadSection>
                        <FileUploadV2 
                          data-test-id="mainImageTest"
                          required
                          label="Cover Image" 
                          labelStyle={{ fontWeight: 700 }}
                          supportText="Drag and drop image , or"
                          fileType="image/*" 
                          value={values.image} 
                          onChange={e => setFieldValue('image', this.getFile(e))} 
                          uploadingFileIcon={addFileIcon}
                        />
                        <FileUploadV2 
                          data-test-id="mainVideoTest"
                          fileType="video/*" 
                          uploadingFileIcon={addFileIcon}
                          label="Promotional Video" 
                          labelStyle={{ fontWeight: 700 }} 
                          supportText="Drag and drop video , or"
                          value={values.video} 
                          onChange={e => setFieldValue('video', this.getFile(e))} 
                        />
                      </CoverUploadSection>
                    </CourseDetailsSection>
                  </ContainerWithBg>
                </ContainerOuter>
              </LeftSection>
              <RightSection item xs={4} md={4} lg={4}>
                <ColStack style={{ padding: '54.33px 12.46px 110px', gap: "18px" }}>
                  <ColStack style={{ gap: "2px" }}>
                    <CustomisedSelect
                      data-test-id='txtCategory'
                      defaultLabel="Category"
                      defaultValueObject={{
                        label: categories.find(category => category.value === values.category_id)?.label ?? "select your category",
                        value: values.category_id
                      }}
                      onChange={valueObj => this.handleCategoryChange(values.category_id, valueObj.value)}  
                      onCreateNew={this.handleCreateCategory}
                      placeholder="select your category"
                      searchPlaceholder="Search Category"
                      addButtonText="Add New Category"
                      addPlaceholder="Type your category"
                      required
                      crmFields={categories}  
                      labelStyle={{ fontWeight: 700 }}         
                    />
                    <CustomisedSelect
                      data-test-id='txtSubCategory'
                      defaultLabel="Sub-Category"
                      defaultValueObject={{
                        label: subcategories.find(subcategory => subcategory.value === values.sub_category_id)?.label || "select your sub-category",
                        value: values.sub_category_id
                      }}
                      onChange={value => setFieldValue('sub_category_id', value.value !== values.sub_category_id ? value.value : "-1")}  
                      onCreateNew={value => this.handleCreateSubcategory(values.category_id, value)}
                      placeholder="select your sub-category"
                      searchPlaceholder="Search Sub-category"
                      addButtonText="Add New Sub-category"
                      addPlaceholder="Type your sub-category"
                      crmFields={subcategories}  
                      labelStyle={{ fontWeight: 700 }}         
                    />
                    <TextInput 
                      data-test-id="txtReviewTime" 
                      name="time_for_supervisor_to_review" 
                      type="text"
                      required
                      label="Days For Supervisor To Review" 
                      labelStyle={{ fontWeight: 700 }} 
                      inputMode="numeric"
                      value={values.time_for_supervisor_to_review}
                      onChange={handleChange}
                      onKeyDown={event => this.handleKeyDownRestriction(event, ['Backspace','Delete','ArrowRight','ArrowLeft'])}
                    />
                  </ColStack>
                  <ColStack style={{ gap: "6px" }}>
                    <GroupLabel>Course Duration</GroupLabel>
                    <Box>
                      <CustomLabel style={{ fontWeight: 700 }}>Start Date</CustomLabel>
                      <DateField
                        name="start_date"
                        data-test-id='start_date'
                        placement="bottomRight"
                        format="MM-DD-YYYY"
                        size="large"
                        showToday={false}
                        disabledDate={this.isDateDisabled}
                        onChange={(_date, dateString) => setFieldValue("start_date", dateString)}
                        defaultValue={values.start_date !== "" ? moment(values.start_date) : undefined}
                      />
                    </Box>
                    <Box>
                      <CustomLabel style={{ fontWeight: 700 }}>End Date</CustomLabel>
                      <DateField
                        name="end_date"
                        data-test-id='end_Date'
                        placement="bottomRight"
                        format="MM-DD-YYYY"
                        size="large"
                        showToday={false}
                        onChange={(_date, dateString) => setFieldValue("end_date", dateString)}
                        defaultValue={values.end_date !== "" ? moment(values.end_date) : undefined}
                        disabledDate={this.isDateDisabled}
                      />
                    </Box>
                  </ColStack>
                  <ColStack style={{ gap: "11px" }}>
                    <TagInputSection>
                      <CustomLabel style={{ flexGrow: 1, padding: "5px 12px", whiteSpace: "nowrap" }}>Tag Name</CustomLabel>
                      <TextInput
                        name="tags"
                        data-test-id="txtTags"
                        placeholder="Enter tag name"
                        style={{ height: "24px", borderRadius: "98px" }}
                        value={values.tags}
                        onChange={handleChange}
                      />
                    </TagInputSection>
                    <TagInputSection>
                      <CustomLabel style={{ flexGrow: 1, padding: "5px 12px", whiteSpace: "nowrap" }}>Tag Color</CustomLabel>
                      <RowStack style={{ alignItems: "center", justifyContent: "space-evenly" }}>
                        {ConfigJSON.tagColors.map(({ id, color }: { id: string; color: string }, index: number) => (
                          <div 
                            key={index} 
                            className={values.selected_tag_color === color ? "selected" : ""} 
                            style={{ borderRadius: "50%", margin: "5px" }}
                          >
                            <TagColor
                              data-test-id={id}
                              style={{ background: color }}
                              onClick={() => setFieldValue("selected_tag_color", color)}
                            />
                          </div>
                        ))}

                      </RowStack>
                    </TagInputSection>
                  </ColStack>
                  <CustomButton 
                    data-test-id='btnContinueCourse' 
                    type="submit" 
                    className="publishBg" 
                    disabled={!isValid}
                  >Continue</CustomButton>
                </ColStack>
              </RightSection>
            </Grid>
          </form>
        )
      }}
    </Formik>
  )
  renderChapterList = () => {
    if (this.state.courseDetail.course_content_attributes.length <= 0) {
      return (
        <ColStack style={{ flex: 1, gap: "18px" }}>
          <Box>
            {this.renderCourseInfoSection({ 
              heading: "Add Chapter", 
              subHeading: "Don’t worry you can easily rearranged the order of your content by clicking the lesson and dragging it into your desired location",
            })}
          </Box>
          <RowStack style={{ height: "100vh", justifyContent: "center", alignItems: "center" }}>
            <CreateChapterButton 
              data-test-id="createChapterButton" 
              variant="outline" 
              iconRight={<img src={addIcon} style={{ width:"24px", height:"24px" }} />}
              onClick={() => this.setState({ isAddingChapter: true })}
            >
              Create Chapter 
            </CreateChapterButton>
          </RowStack>
        </ColStack>
      ) 
    }

    return (
      <ColStack style={{ flex: 1, gap: "32px" }}>  
        <Box>
          {this.renderCourseInfoSection({ 
              heading: "Chapter list", 
              subHeading: "Don’t worry you can easily rearranged the order of your content by clicking the lesson and dragging it into your desired location",
              actionButton: (
                <CustomButton 
                  data-test-id="addNewChapterBtn"
                  size="sm"
                  style={{ width: "175px", }}
                  iconLeft={<AddCircleOutlineRoundedIcon />}
                  onClick={() => this.setState({ isAddingChapter: true })}
                >
                  <Typography style={{fontSize: "14px", fontWeight: 700, lineHeight: "22px" }}>Add Chapter</Typography></CustomButton>
              )
          })}
        </Box>
        <ScrollableSection style={{ marginTop: "-10px", flex: 1, maxHeight: "500px", paddingRight: "8px" }}>
          <DragDropContext onDragEnd={this.handleDragEnd}>
            <Droppable droppableId="chapters" data-test-id="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <ColStack style={{ gap: "10px" }}>
                    {this.state.courseDetail.course_content_attributes.map((chapter, index) => (
                      <Draggable key={index} draggableId={String(index)} index={index} data-test-id="draggable">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps} 
                          >
                            <ChapterCard>
                              <ChapterHeaderBox>
                                <RowStack style={{ alignItems: "center", gap: "5px" }}>
                                  <Box
                                    style={{ cursor: "grab", color: "#64748B" }}
                                    {...provided.dragHandleProps} 
                                  >
                                    <DragIndicator />
                                  </Box>
                                  <ChapterTitleTypo data-test-id="chapterTitleTest" style={{ fill: "#64748B" }}>
                                    {chapter.title}
                                  </ChapterTitleTypo>
                                </RowStack>
                                <RowStack style={{ marginRight: "10px", gap: "3px" }}>
                                  <TextOnWhiteButton
                                    data-test-id="editChapterTest"
                                    variant="ghost"
                                    style={{ height: '22.6px', padding: "0 8px", backgroundColor: "#fff", fontSize: '10px', lineHeight: "14px" }}
                                    iconLeft={<Edit style={{ fill: "#1A469C" }} />}
                                    onClick={() => this.handleEditChapterClick(index)}
                                  >Edit</TextOnWhiteButton>
                                  <TextOnWhiteButton
                                    data-test-id="deleteChapterTest"
                                    variant="ghost"
                                    style={{ height: '22.6px', padding: "0 8px", color: '#DC2626', fontSize: '10px', lineHeight: "14px" }}
                                    onClick={() => this.removeChapter(index)}
                                    iconLeft={<img src={deleteIcon} style={{ height: '16px' }} />}
                                  >Delete</TextOnWhiteButton>
                                </RowStack>
                              </ChapterHeaderBox>
                              <ChapterBody>
                                {chapter.lessons_attributes?.length === 0 && <NoLessonTypo data-test-id="noLessonsTest">No lessons yet</NoLessonTypo>}
                                <ScrollableSection>
                                  {chapter.lessons_attributes?.map((lesson, lesson_index) => {
                                    const isExpanded = this.state.expandedList[`${index}-${lesson_index}`];
                                    return (
                                      <Box style={{ maxHeight: "188px" }} key={lesson_index}>
                                        <React.Fragment key={lesson_index}>
                                          <AccordionSummary>
                                            <RowStack style={{ paddingLeft: '8px' }}>
                                              <ExpandIcon
                                                data-test-id="expandBtn"
                                                style={{ transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)", fill: this.state.darkTheme ? '#FFFFFF' : '#1F1F1F' }}
                                                onClick={() => this.toggleExpandedList(index, lesson_index)}
                                              />
                                              <Box style={{ color: '#334155' }} onClick={() => this.toggleExpandedList(index, lesson_index)} data-test-id="lessonNameTest">{lesson.title}</Box>
                                            </RowStack>
                                            {isExpanded && (
                                              <RowStack>
                                                <IconButton
                                                  data-test-id="lessonEditTest"
                                                  style={{ padding: 0, height: "auto" }}
                                                  onClick={() => this.handleEditLessonClick(index, lesson_index)}
                                                >
                                                  <img src={smallEdit} style={{ width: "16px", height: "16px" }} />
                                                </IconButton>
                                                <IconButton
                                                  data-test-id="lessonDeleteTest"
                                                  style={{ padding: 0, height: "auto" }}
                                                  onClick={() => this.removeLesson(index, lesson_index)}
                                                >
                                                  <img src={smallBin} style={{ width: "16px", height: "16px" }} />
                                                </IconButton>
                                              </RowStack>)}
                                          </AccordionSummary>
                                          {isExpanded && (
                                            <AccordionDetails>
                                              <DragDropContext onDragEnd={(result) => this.handleDragSublessonEnd(result, index, lesson_index)}>
                                                <Droppable droppableId={`droppable-${index}-${lesson_index}`} data-test-id="droppable2">
                                                  {(provided) => (
                                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                                      {lesson.sub_lessons_attributes.map((sub, sub_index) => (
                                                        <Draggable key={`sub-${index}-${lesson_index}-${sub_index}`} data-test-id="draggable2" draggableId={`sub-${index}-${lesson_index}-${sub_index}`} index={sub_index}>
                                                          {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                              <RowStack style={{ gap: "1px", alignItems: "center"}}>
                                                                <Box style={{ cursor: "grab", color: "#64748B" }} {...provided.dragHandleProps}>
                                                                  <DragIndicator fontSize="small" />
                                                                </Box>
                                                                <span data-test-id="subLessonNameTest">{sub.title}</span>
                                                                <RowStack style={{ marginLeft: "9px", gap: "1px" }}>
                                                                  <IconButton
                                                                    data-test-id="subLessonEditTest"
                                                                    style={{ padding: 0, height: "auto" }}
                                                                    onClick={() => this.handleEditSubLessonClick(index, lesson_index, sub_index)}
                                                                  >
                                                                    <img src={smallEdit} style={{ width: "16px", height: "16px" }} />
                                                                  </IconButton>
                                                                  <IconButton
                                                                    data-test-id="subLessonDeleteTest"
                                                                    style={{ padding: 0, height: "auto" }}
                                                                    onClick={() => this.removeSubLesson(index, lesson_index, sub_index)}
                                                                  >
                                                                    <img src={smallBin} style={{ width: "16px", height: "16px" }} />
                                                                  </IconButton>
                                                                </RowStack>
                                                              </RowStack>
                                                            </div>
                                                          )}
                                                        </Draggable>
                                                      ))}
                                                      {provided.placeholder}
                                                    </div>
                                                  )}
                                                </Droppable>
                                              </DragDropContext>
                                              <Button
                                                data-test-id='addLesson'
                                                variant="ghost"
                                                size="sm"
                                                iconLeft={<AddCircleOutlineRoundedIcon fontSize="small" />}
                                                style={{ width: "fit-content", height: "22px", padding: "revert" }}
                                                onClick={() => this.handleAddSubLessonClick(index, lesson_index)}
                                              >
                                                Add Sub Topic
                                              </Button>
                                            </AccordionDetails>
                                          )}
                                        </React.Fragment>
                                      </Box>
                                    )
                                  })}
                                </ScrollableSection>
                                <RowStack style={{ justifyContent: "end", padding: "0 0 20px", gap: "5px" }}>
                                  <CustomButton
                                    data-test-id='addMainLesson'
                                    onClick={() => this.handleAddLessonClick(index)}
                                    iconLeft={<AddCircleOutlineRoundedIcon />}
                                    variant="outline"
                                    size="sm"
                                    style={{ width: "147px", backgroundColor: this.state.darkTheme ? '#0D0D0D' : '#FFFFFF' }}
                                  >Add Lesson</CustomButton>
                                  <CustomButton
                                    data-test-id='copyLessonBtn'
                                    iconLeft={<img src={copyIcon} />}
                                    variant="ghost"
                                    size="sm"
                                  >
                                    Copy lesson from
                                  </CustomButton>
                                </RowStack>
                              </ChapterBody>
                            </ChapterCard>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ColStack>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ScrollableSection>
        <RowStack style={{ justifyContent: "flex-end" }}>
          <CustomButton 
            iconRight={<ArrowRightAlt />}
            style={{ width: "294px", gap: '12px'}} 
            onClick={this.handleUpdateCurriculum}
          >Next</CustomButton>
        </RowStack>
      </ColStack>
    )
  }
  renderChapterForm = (editable?: boolean, values?: Chapter) => (
    <>
      {this.renderCourseInfoSection({ 
        heading: !editable ? "Add Chapter" : "Edit Chapter", 
        subHeading: "Don’t worry you can easily rearranged the order of your content by clicking the lesson and dragging it into your  desired location",
      })} 
      <NewChapterBox>
        <CourseInfoSection style={{ padding: 0 }}>
          <RowStack style={{ gap: '12px', alignItems: 'center' }}>
            <CourseInfoTitle>{!editable ? 'New chapter' : values?.title}</CourseInfoTitle>
            <hr style={{ color:"#D7D7D780", width: '310px', height:"1px" }} />
          </RowStack>
        </CourseInfoSection>
        <Formik
          initialValues={ConfigJSON.chapterInitialValues}
          validationSchema={ChapterSchema}
          onSubmit={this.handleChapter}
          data-test-id="create-chapter-formik"
          innerRef={f => this.chapterFormikRef.current = f}
        >
          {({ values, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit} style={{ flex: 1 }}>
              <TextInput
                name="title"
                data-test-id="txtChapterTitle"
                label="Title"
                labelStyle={{ fontWeight: 700 }}
                placeholder="Enter chapter title"
                onChange={handleChange}
                value={values.title}
              />
              <RowStack style={{ alignItems: "center", justifyContent: "center", gap: "63px", width: "70%", marginRight: "auto", marginLeft: "auto", marginTop: '31px' }}>
                <Button
                  className="cancelChapterTitle"
                  data-test-id="cancelChapterTitle1"
                  onClick={this.handleCancelChapterFormClick}
                  variant="outline"
                  style={{ width: "100%", borderRadius: "30px" }}
                >
                  Cancel
                </Button>
                {editable ?
                  <Button
                    className="publishBg"
                    type="submit"
                    data-test-id='editChapterButton'
                    style={{ width: "100%", borderRadius: '30px' }}
                  >
                    Save
                  </Button>
                  : 
                  <Button
                    type="submit"
                    className="publishBg"
                    data-test-id='addChapterTitle'
                    style={{ width: "100%", borderRadius: '30px' }}
                  >
                    Create
                  </Button>
                }
              </RowStack>
            </form>
          )}
        </Formik>
      </NewChapterBox>

    </>
  )
  renderLessonForm = (editable?: boolean, values?: Lessons) => (
    <ColStack style={{ gap: "5px" }}>
      {this.renderCourseInfoSection({ 
        heading: this.state.courseDetail.course_content_attributes[this.state.editableIndex].title || '', 
        enableOfflineDownload: true
      })} 
      <Formik
        initialValues={!editable ? ConfigJSON.chapterInitialValues : values}
        validationSchema={LessonSchema}
        validateOnChange
        onSubmit={values => {if (editable){ this.updateLesson(values) }}}
        data-test-id="create-lesson-formik"
        innerRef={f => this.lessonFormikRef.current = f}
      >
        {({ values, errors, touched, handleChange }) => {
          const isDisabled = values.title === "";
          return (
            <ColStack style={{ gap: "32px" }}>
              <TextInput
                name="title"
                data-test-id="txtLessonTitle"
                label="Title"
                placeholder="Add Lesson Title"
                labelStyle={{ fontWeight: 700 }}
                value={values.title}
                onChange={handleChange}
                error={!!errors.title && !!touched.title}
                errorText={errors.title as string}
              />
              {!editable || this.state.isSelectingSubLessonType ? (
                <LessonTypeSelect 
                  style={{ 
                    opacity: isDisabled ? "0.2": "1.0",
                    pointerEvents: isDisabled ? "none" : "auto" 
                  }}
                >
                  <Grid container spacing={2}>
                    {LessonTypes.map(({ name, icon }: { name: string; icon: string }) => (
                      <Grid item key={name}>
                        <LessonTypeDiv data-test-id={`${name}Lesson`} onClick={() => this.handleLesson(values.title, name)}>
                          <img src={icon} />
                          <LessonTypeText>{name}</LessonTypeText>
                        </LessonTypeDiv>
                      </Grid>
                    ))}
                  </Grid>
                  <CustomCheckbox label="Mark this chapter as prerequisite*"/>
                </LessonTypeSelect>)
                : (
                  <ButtonGroup>
                    <Button
                      className="cancelChapterTitle"
                      data-test-id="cancelChapterTitle1"
                      onClick={() => this.setState({ isEditingLesson: false })}
                      variant="outline"
                      style={{ width: "100%", borderRadius: "30px" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="publishBg"
                      data-test-id='editLessonButton'
                      onClick={() => this.updateLesson({ title: values.title })}
                      style={{ width: "100%", borderRadius: '30px' }}
                    >
                      Save
                    </Button>
                  </ButtonGroup>
                )
              }
            </ColStack>
          )
        }}
      </Formik>
    </ColStack>
  )
  renderCopySubLessonForm = ({
    values,
    errors,
    handleChange,
    ...rest
  }: ICopyLessonSectionProps) => {
    const courses = [
      { 
        value: "0", 
        label: "New course (this course)",
        options: this.state.courseDetail.course_content_attributes.map((chapter, index) => ({
          value: index.toString(),
          label: chapter.title
        }))
      },
      ...this.state.courses.map(course => ({
        value: course.id,
        label: course.attributes.course_name,
        options: course.attributes.chapters && course.attributes.chapters?.data && course.attributes.chapters?.data.length > 0
        ? course.attributes.chapters?.data?.map(chapter => ({
          value: chapter.id,
          label: chapter.attributes.title
        })) : []
      }))
    ]
    const lessons = this.state.lessons.map(lesson => ({
      value: lesson.id,
      label: lesson.title,
      options: lesson.sub_lessons_attributes
        .filter(subLesson => subLesson.title !== "" && subLesson.lesson_type === values.lesson_type)
        .map((subLesson) => ({
          value: subLesson.id,
          label: subLesson.title,
      }))
    }));

    return (
      <ColStack style={{ gap: "16px" }}>
        {rest.withOptionalText && rest.optinalTextPosition === "top" && (
          <TextInput
            data-test-id="optionalText"
            name="optional_text"
            placeholder="Add text (optional)"
            value={values.optional_text}
            onChange={handleChange}
            style={{ border: "none" }}
          />)}
        <RowStack style={{ alignItems: "flex-end", gap: "10px" }}>
          <ColStack style={{ width: "66%", gap: "16px" }}>
            <RowStack style={{ gap: "4px" }}>
              <img src={copyIcon} />
              <CustomLabel style={{ fontWeight: 700 }}>Copy Lesson From</CustomLabel> 
            </RowStack>
            <RowStack style={{ gap: "10px" }}>
              <ContextMenu
                data-test-id="chapterToCopySelect"
                defaultValueObject={this.state.chapterToCopy}
                onChange={this.handleCourseChapterChange}  
                placeholder="Select course"
                searchPlaceholder="Search"
                addable={false}
                openInitialDefaultSelectionOnMenuOpen={false}
                labelStyle={{ fontWeight: 700 }}         
                crmFields={courses}
              />
              <ContextMenu
                data-test-id="subLessonToCopySelect"
                defaultValueObject={this.state.sublessonToCopy}
                onChange={this.handleSubLessonSelect}  
                placeholder="Select lesson"
                searchable={false}
                addable={false}
                labelStyle={{ fontWeight: 700 }}         
                crmFields={lessons}
              />
            </RowStack>
          </ColStack>
          <ColStack>
            <TextInput 
              data-test-id="lessonTime" 
              label="Lesson Timer in Minutes and Seconds" 
              type="text"  
              placeholder="MM:SS"
              inputProps={{
                pattern: "[0-9]+:[0-5][0-9]",
              }}
              name="lesson_timer"
              value={values.lesson_timer}
              onChange={handleChange}
              onKeyDown={event => this.handleKeyDownRestriction(event, ['Backspace','Delete','ArrowRight','ArrowLeft',':'])}
              error={!!errors.lesson_timer}
            />
          </ColStack>
        </RowStack>
        {!!this.state.subLessonToCopy.files && !!this.state.subLessonToCopy.files.name
          && (
            <FilePreviewCard 
              data-test-id="filePreviewCard"
              file={this.state.subLessonToCopy.files} 
              onRemoveFileButtonClick={() => this.resetCopyLessonForm()}
            />
        )}
        {rest.withOptionalText && rest.optinalTextPosition === "bottom" && (
          <TextInput
            data-test-id="optionalText"
            name="optional_text"
            placeholder="Add text (optional)"
            value={values.optional_text}
            onChange={handleChange}
            style={{ border: "none" }}
          />)}
      </ColStack>
    )
  }
  renderSubLessonForm = (editable?: boolean, values?: SubLesson) => {
    const currentChapterIndex = this.state.editableIndex;
    const currentLessonIndex = this.state.editableLessonIndex;
    const currentSubLessonIndex = this.state.editableSubLessonIndex;
    const type = this.state.courseDetail?.course_content_attributes[currentChapterIndex]?.lessons_attributes[currentLessonIndex]?.sub_lessons_attributes[currentSubLessonIndex]?.lesson_type || "";
          
    return (
      <>
        {this.renderCourseInfoSection({ 
          heading: this.state.courseDetail.course_content_attributes[this.state.editableIndex].lessons_attributes[this.state.editableLessonIndex].title || '', 
          enableOfflineDownload: true
        })} 
        <ScrollableSection style={{ marginTop: '22px', paddingRight: '18px' }}>
          {type !== "quiz"
            ? (
              <Formik
                initialValues={!editable ? { ...ConfigJSON.subLessonInitialValues, lesson_type: type } : values}
                validationSchema={SubLessonSchema}
                onSubmit={this.updateSubLessonAndContinue}
                data-test-id="create-sublesson-formik"
                innerRef={f => this.subLessonFormikRef.current = f}
              >
                {({ errors, values, dirty, isValid, handleChange, handleSubmit, setFieldValue }) => (
                  <form onSubmit={handleSubmit} style={{ flex: 1 }}>
                    <FormLayout>
                      <ColStack style={{ gap: '16px' }}>
                        <SubLessonFormTitle>{this.getSubLessonType(type)} <span>lesson</span></SubLessonFormTitle>
                        <TextInput 
                          data-test-id="textLessonTitle" 
                          name="title" 
                          label="Lesson Title" 
                          labelStyle={{ fontWeight: 700 }}
                          value={values.title} 
                          onChange={handleChange}
                        />
                        {type === "video" && (
                          <>
                            <Box>
                              <CustomLabel style={{ fontWeight: 700 }} >Videos From Your Library</CustomLabel>
                              <FileUploadV2
                                disableBrowsing
                                withoutPreview
                                fileType="video/*"
                                value={values.files} 
                                onChange={e => setFieldValue('files', e)} 
                              />
                            </Box>
                            <Box>
                              <CustomLabel style={{ fontWeight: 700 }} >Upload Video File</CustomLabel>
                              <FileUploadV2
                                withoutPreview
                                fileType="video/*"
                                value={values.files} 
                                onChange={e => setFieldValue('files', e)} 
                              />
                            </Box>
                            <CustomLabel>
                              Max size GB. we suggest compressing your video using Handbrake<a href="https://handbrake.fr/">{" Handbrake"}<img src={handbrakeIcon} style={{ width:"20px",height:"20px" }} /></a>
                            </CustomLabel>
                            {this.renderCopySubLessonForm({ withOptionalText: true, optinalTextPosition: "top", values, handleChange, errors })}
                          </> 
                        )}
                        {type === "text" && (
                          <>
                            <RichTextEditor 
                              data-test-id="textLessonContent" 
                              name="content" 
                              label="Content" 
                              labelStyle={{ fontWeight: 700 }} 
                              placeholder="Add your content here"
                              modules={{
                                toolbar: [
                                  ['bold', 'underline', 'italic'],
                                  [{ color: [] }],
                                  ['link'],
                                  [{ header: 1 }],
                                  [{ align: [] }],
                                  [{ list: 'bullet' }],
                                  ['clean'],
                                ],
                                
                              }}
                              value={values.content}
                              onChange={value => setFieldValue('content', value)}
                            />
                            {this.renderCopySubLessonForm({ withOptionalText: true, optinalTextPosition: "bottom", values, handleChange, errors })}
                          </>)}
                        {type === "pdf" && (
                          <>
                            <Box>
                              <CustomLabel style={{ fontWeight: 700 }} >Upload Pdf File</CustomLabel>
                              <FileUploadV2
                                withoutPreview
                                fileType="application/pdf"
                                value={values.files} 
                                onChange={e => setFieldValue('files', e)} 
                              />
                            </Box>
                            <CustomLabel>You can upload files with the extension :pdf</CustomLabel>
                            {this.renderCopySubLessonForm({ values, handleChange, errors })}
                          </> 
                        )}
                        {type === "audio" && (
                          <>
                            <Box>
                              <CustomLabel style={{ fontWeight: 700 }}>Audio from your library </CustomLabel>
                              <FileUploadV2 
                                disableBrowsing
                                withoutPreview
                                fileType="audio/*"
                                value={values.files} 
                                onChange={e => setFieldValue('files', e)} 
                              />
                            </Box>
                            <Box>
                              <CustomLabel style={{ fontWeight: 700 }}>Upload Audio File</CustomLabel>
                              <FileUploadV2 
                                withoutPreview
                                fileType="audio/*"
                                value={values.files} 
                                onChange={e => setFieldValue('files', e)} 
                              />
                            </Box>
                            {this.renderCopySubLessonForm({ withOptionalText: true, optinalTextPosition: "top", errors, values, handleChange })}
                          </> 
                        )}
                        {type === "multimedia" && (
                          <>
                            <ColStack style={{ gap: "2px" }}>
                              <CustomLabel style={{ fontWeight: 700 }}>Content</CustomLabel>
                              <CustomLabel>Use externally hosted content </CustomLabel>
                            </ColStack>
                            <TextInput 
                              data-test-id="multimediaURLText" 
                              name="multimediaURL" 
                              label="URL (to your webinar tool link)"
                              labelStyle={{ fontWeight: 700 }}
                              type="url"
                              placeholder="ex:"
                              value={values.multimediaURL} 
                              onChange={handleChange}
                            />
                            {this.renderCopySubLessonForm({ errors, values, handleChange })}
                          </> 
                        )}
                        {type === "presentation" && (
                          <>
                            <Box>
                              <CustomLabel style={{ fontWeight: 700 }}>Upload PPT or PPTX file</CustomLabel>
                              <FileUploadV2 
                                withoutPreview
                                fileType="application/vnd.ms-powerpoint,
                                  application/vnd.openxmlformats-officedocument.presentationml.slideshow,
                                  application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                value={values.files} 
                                onChange={e => setFieldValue('files', e)} 
                              />
                            </Box>
                            <CustomLabel>
                              You can upload files with the extension :PPT or PPTX
                            </CustomLabel>
                            <CustomLabel>
                              For increased student engagement, we recommend keeping your presentation under 20 slides.
                            </CustomLabel>
                            {this.renderCopySubLessonForm({ errors, values, handleChange })}
                          </> 
                        )}
                        {type === "interactive training" && (
                          <>
                            <Box>
                              <CustomLabel style={{ fontWeight: 700 }}>Upload file</CustomLabel>
                              <FileUploadV2 
                                withoutPreview
                                fileType="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                                value={values.files} 
                                onChange={e => setFieldValue('files', e)} 
                              />
                            </Box>
                            {this.renderCopySubLessonForm({ errors, values, handleChange })}
                          </> 
                        )}
                         {type === "image" && (
                            <>
                              <Box>
                                <CustomLabel style={{ fontWeight: 700 }}>Upload Image</CustomLabel>
                                <FileUploadV2 
                                  fileType="image/*"
                                  multiple
                                  uploadingFileIcon={graphicStyle}
                                  supportText="Drag and Drop Image or"
                                  triggerStyle={{ height: "121px" }}
                                  value={values.files} 
                                  onChange={e => setFieldValue('files', e)} 
                                />
                              </Box>
                              {this.renderCopySubLessonForm({ errors, values, handleChange })}
                            </> 
                        )}
                      </ColStack>
                      <RowStack style={{ justifyContent: "space-evenly", gap: "20px" }}>
                        <CustomButton
                          data-test-id="editCloseButton"
                          variant="outline"
                          onClick={() => this.updateSubLessonAndClose(values)}
                          disabled={(this.state.isEditingSubLesson && !dirty) || !isValid}
                          style={{ width: '50%' }}
                        >Save and Close</CustomButton>
                        <CustomButton
                          data-test-id="saveAddButton"
                          type="submit"
                          disabled={(this.state.isEditingSubLesson && !dirty) || !isValid}
                          style={{ width: '50%' }}
                        >Save and Add New Lesson Type</CustomButton>
                      </RowStack>
                    </FormLayout>
                  </form>
                )}
              </Formik>
            )
            : <AssessmentTest testType="quiz" navigation={this.props.navigation} quiz_id={this.state.quiz_id} handleOpenAlertModalFinalTest={this.handleOpenAlertModal} onSaveAndClose={this.updateSubLessonAndClose} onAddNewLesson={this.updateSubLessonAndContinue} courseId={this.state.courseId} id="AssessmentTest" />
          } 
        </ScrollableSection>
      </>
    )
  }
  renderCurriculum = () => {
    const currentChapterIndex = this.state.editableIndex;
    const currentLessonIndex = this.state.editableLessonIndex;
    const currentSubLessonIndex = this.state.editableSubLessonIndex;
    const currentChapterList = this.state.courseDetail.course_content_attributes;

    return (
      <>
        <ColStack style={{ margin: "auto", width: '90%', minHeight: "100vh", gap: '20px' }}>
          {this.renderStepper()}
          <ColStack style={{ flex: 1 }}>
            {!this.state.isAddingChapter && !this.state.isEditingChapter && !this.state.isSelectingSubLessonType && !this.state.isAddingLesson
              && !this.state.isEditingLesson && !this.state.isAddingSubLesson && !this.state.isEditingSubLesson && this.renderChapterList()}
            {this.state.isAddingChapter && this.renderChapterForm()} 
            {this.state.isEditingChapter && this.renderChapterForm(true, currentChapterList[currentChapterIndex])}
            {this.state.isAddingLesson && this.renderLessonForm()} 
            {(this.state.isEditingLesson || this.state.isSelectingSubLessonType)
              && this.renderLessonForm(
                true, 
                currentChapterList[currentChapterIndex].lessons_attributes[currentLessonIndex]
            )} 
            {this.state.isAddingSubLesson && this.renderSubLessonForm()}
            {this.state.isEditingSubLesson && this.renderSubLessonForm(
              true,
              currentChapterList[currentChapterIndex].lessons_attributes[currentLessonIndex].sub_lessons_attributes[currentSubLessonIndex]
            )} 
  
          </ColStack>
        </ColStack>
        {this.publishModal()}
      </>
    )
  }

  renderPreview = () => {
    const { media_files, media_files_urls } = this.state;
    const hasMedia = media_files.length > 0 || media_files_urls.length > 0;
  
    return (
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: hasMedia ? "repeat(3, 1fr)" : "1fr",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          {media_files.map((file, index) => {
            const fileURL = URL.createObjectURL(file);
            return (
              <div key={index} style={removeStyle}>
                <button
                  data-test-id="removeImg"
                  onClick={() => this.removeImage(index,"")}
                  style={removeButtonStyle}
                >
                  ✖
                </button>
                <img
                  src={fileURL}
                  alt="Preview"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </div>
            );
          })}
  
          {media_files_urls.map((file, index) => {
            return (
              <div key={`url-${index}`} style={removeStyle}>
                <button
                  data-test-id="removeImg2"
                  onClick={() => this.removeImage(index, file.id)}
                  style={removeButtonStyle}
                >
                  ✖
                </button>
                <img
                  src={file.url}
                  alt="Preview"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </div>
            );
          })}
        </div>
  
        <div
          style={{
            display: "grid",
            gridTemplateColumns: hasMedia ? "repeat(2, 1fr)" : "1fr",
            gap: "10px",
            width: "100%",
          }}
        >
          <label htmlFor="uploadedMedia" className="profile-upload-btn">
            <input
              id="uploadedMedia"
              data-test-id="coverImageBtn"
              type="file"
              multiple
              accept="image/jpeg, image/png"
              style={{ display: "none" }}
              onChange={this.setFieldMediaValue}
            />
            <FileUploadZone>
              <img src={addFileIcon} alt="Upload Icon" />
              <RowStack style={{ alignItems: "center" }}>
                <SupportText style={{ fontSize: "10px", padding: 0 }}>
                  Drag and drop image , or
                </SupportText>
                <CustomButton
                  variant="ghost"
                  style={{ fontSize: "12px", padding: 0, height: "fit-content", minWidth:"52px" }}
                  onClick={this.handleBrowseClick}
                >
                  browse
                </CustomButton>
              </RowStack>
            </FileUploadZone>
          </label>
          <div>
            <FileUploadZone>
              <img src={addFileLibraryIcon} alt="Library Icon" />
              <RowStack style={{ gap: "3px" }}>
                <CustomButton variant="ghost" style={{ fontSize: "12px" }}>
                  Assign from library
                </CustomButton>
              </RowStack>
            </FileUploadZone>
          </div>
        </div>
      </div>
    );
  };  

  renderMedia = () => (
    <>
      {this.renderStepper()}
      <Grid container spacing={2} style={{ marginTop: "27px" }}>
        <Grid item xs={8}>
          <FocusableContainer style={{ padding: "34px 20px 34px 10px" }}>
            <ScrollableSection style={{ padding: "10px" }}>
              {this.renderCourseInfoSection({ heading: "Add File and Image", enableOfflineDownload: true })}
              <Box style={{ marginTop: "23px" }}>
                <CustomLabel style={{ fontWeight: 700 }}>Upload Media</CustomLabel>
                {this.renderPreview()}
  
                <AddFileBox style={{ marginTop: "30px" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <CustomLabel style={{ fontWeight: 700 }}>Attach file</CustomLabel>
                    <label htmlFor="fileInput" style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
                      <img src={clipIcon} style={{ width: "24px", height: "24px" }} alt="Attach" />
                    </label>
                  </div>
                  <input
                    id="fileInput"
                    type="file"
                    accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf"
                    style={{ display: "none" }}
                    multiple
                    onChange={this.setFieldDocValue}
                  />
                </AddFileBox>

                {(this.state.doc_files.length > 0 || this.state.doc_files_urls.length > 0) && (
                  <div
                    style={{
                      border: "1px solid rgba(148, 163, 184, 1)",
                      borderRadius: "22px",
                      padding: "25px 25px",
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      marginTop: "22px",
                      height: "169px",
                      overflow: "scroll",
                    }}
                  >
                    {[...this.state.doc_files, ...this.state.doc_files_urls].map((file, index) => {
                      const fileName = "name" in file ? file.name : file.url.split("/").pop();
                      const fileSize = "size" in file ? (file.size / 1024).toFixed(2) + " KB" : "API File";
  
                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "5px",
                            border: "1px solid rgba(255, 255, 255, 1)",
                            borderRadius: "8px",
                            padding: "10px",
                            marginTop: "10px",
                            boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
                          }}
                        >
                          <img src={fileIcon} alt="File Icon" style={{ marginRight: "10px" }} />
                          <div style={{ flexGrow: 1 }}>
                            <p style={{ margin: "0", fontSize: "10px", fontWeight: 400 }}>{fileName}</p>
                            <p style={{ margin: "0", fontSize: "9px", color: "#666" }}>{fileSize}</p>
                          </div>
                          <button
                            data-test-id="removeDoc"
                            onClick={() => this.removeDocFile(index, (file as MediaUrls).id || "")}
                            style={{
                              background: "transparent",
                              border: "none",
                              color: "rgba(0, 0, 0, 0.6)",
                              fontSize: "16px",
                              cursor: "pointer",
                            }}
                          >
                            ✖
                          </button>
                        </div>
                      );
                    })}
                  </div>
                )}
                  <RowStack style={{ marginTop: "28px", alignItems: "center", justifyContent: "flex-end", gap: "10px" }}>
                  <CustomButton style={{ width: "45%" , border:"1px solid rgb(26, 70, 156)"}} variant="ghost" disabled={this.state.media_files.length <= 0 && this.state.doc_files.length <= 0} onClick={this.handleOpenSaveAndCloseAlertModal}>Save and Close</CustomButton>
                  <CustomButton style={{ width: "45%" }} disabled={this.state.media_files.length <= 0 && this.state.doc_files.length <= 0} onClick={this.handleUploadMedia}>Save</CustomButton>
                </RowStack>
              </Box>
            </ScrollableSection>
          </FocusableContainer>
        </Grid>
          <Grid item xs={4}>
          <FocusableContainer style={{ padding: "34px 20px" }}>
            <ColStack style={{ height: '100%' }}>
              <Box style={{ flex: 1 }}>
                {this.state.isCombinedFiles && (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr",
                      gap: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {(() => {
                     let arrangedFiles: (File | MediaUrls)[][] = [];
                     let tempRow: (File | MediaUrls)[] = [];
                     
                     const allFiles = [...this.state.combined_files, ...this.state.combined_files_urls];
                     
                     allFiles.forEach((file, index) => {
                       const isImage = "type" in file ? file.type.startsWith("image/") : file.url.match(/\.(jpeg|jpg|png|gif)$/i);
                     
                       if (isImage) {
                         tempRow.push(file);
                         if (tempRow.length === 2) {
                           arrangedFiles.push([...tempRow]);
                           tempRow = [];
                         }
                       } else {
                         if (tempRow.length) {
                           arrangedFiles.push([...tempRow]);
                           tempRow = [];
                         }
                         arrangedFiles.push([file]);
                       }
                     });
                     
                     if (tempRow.length) {
                       arrangedFiles.push([...tempRow]);
                     }
                     
                     return arrangedFiles.map((row, rowIndex) => (
                       <div
                         key={rowIndex}
                         style={{
                           display: "grid",
                           gridTemplateColumns: row.length === 2 ? "1fr 1fr" : "1fr",
                           gap: "10px",
                         }}
                       >
                         {row.map((file, index) => {
                           let fileURL = "";
                           let isImage = false;
                     
                           if ("type" in file) {
                             fileURL = URL.createObjectURL(file); 
                             isImage = file.type.startsWith("image/");
                           } else {
                             fileURL = file.url;
                             isImage = file.url.match(/\.(jpeg|jpg|png|gif)$/i) !== null;
                           }
                     
                           return isImage ? (
                             <div
                               key={index}
                               style={{
                                 position: "relative",
                                 width: "100%",
                                 height: "101px",
                                 overflow: "hidden",
                                 borderRadius: "8px",
                               }}
                             >
                               <img
                                 src={fileURL}
                                 alt="Preview"
                                 style={{ width: "100%", height: "100%", objectFit: "cover" }}
                               />
                             </div>
                           ) : (
                             <div
                               key={index}
                               style={{
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "space-between",
                                 border: "1px solid rgba(255, 255, 255, 1)",
                                 borderRadius: "8px",
                                 padding: "10px",
                                 boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
                               }}
                               >
                                 <img src={fileIcon} alt="File Icon" style={{ marginRight: "10px" }} />
                                 <div style={{ flexGrow: 1 }}>
                                   <p style={{ margin: "0", fontSize: "10px", fontWeight: 400 }}>
                                     {"name" in file ? file.name : "Document"}
                                   </p>
                                   <p style={{ margin: "0", fontSize: "9px", color: "#666" }}>
                                     {"size" in file ? `${(file.size / 1024).toFixed(2)} KB` : ""}
                                   </p>
                                 </div>
                               </div>
                           );
                         })}
                       </div>
                     ))                     
                    })()}
                  </div>
                )}
              </Box>
              <ColStack style={{ gap: "4px", alignItems: "center", justifyContent: "center" }}>
                <CustomButton
                  variant="ghost"
                  iconRight={<img src={qrCodeIcon} style={{ width: "24px", height: "24px" }} />}
                  fullWidth
                >Generate QR Code</CustomButton>
                <CustomButton onClick={this.openFinalTest} fullWidth>Next</CustomButton>
              </ColStack>
            </ColStack>
          </FocusableContainer>
        </Grid>
      </Grid>
    </>
  );
  

  renderFinalTest = () => {
    return (
      <AssessmentTest testType="final_tests" navigation={this.props.navigation} quiz_id={this.state.final_test_id} onSaveAndClose={this.updateSubLessonAndClose} onAddNewLesson={this.updateSubLessonAndContinue} handleOpenAlertModalFinalTest={this.handleOpenAlertModal} courseId={this.state.courseId} id="AssessmentTest" />    )
  }

  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <SafeAreaView style={styles.safeAreaView}>
        <ScrollView style={styles.fullHeight}>
          {/* Customizable Area Start */}
          <ThemeProvider theme={this.state.darkTheme ? darkTheme : lightTheme}>
            <MainLayout>
              <Loader loading={this.state.loading} />
              <HamburgerMenu navigation={this.props.navigation} handleMenu={this.leftMenuHandle} open={this.state.openLeftMenu} handleTheme={this.handleTheme} themeDark={this.state.darkTheme} />
              <NavigationMenu id="NavigationMenu" navigation={this.props.navigation} open={this.state.openLeftMenu} theme={this.state.darkTheme} />
              <MainContainer style={{ paddingLeft: this.state.openLeftMenu ? 227 : 95 }}>
                {this.state.step === 0 && this.renderCourseSetting()}
                {this.state.step === 1 && this.renderCurriculum()}
                {this.state.step === 2 && this.renderMedia()}
                {this.state.step === 3 && this.renderFinalTest()}
                {this.state.step===4 && <CourseFeedback  />}
              </MainContainer>
              {this.AlertModal()}
              {this.SaveAndCloseModal()}
            </MainLayout>
          </ThemeProvider >
          {/* Customizable Area End */}
        </ScrollView>
      </SafeAreaView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
    alignSelf: "center",
    width: "100%",
  },
  fullScreen: {
    height: "100vh"
  },
  fullHeight: {
    height: "100vh",
    width: "100%",
  }
})
const removeButtonStyle: React.CSSProperties = {
  position: "absolute",
  top: "5px",
  right: "5px",
  borderRadius: "50%",
  width: "20px",
  height: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  background: "transparent",
  border: "none",
  color: "rgba(0, 0, 0, 0.6)",
  fontSize: "16px",
};

const removeStyle: React.CSSProperties = {
  position: "relative",
  width: "200px",
  height: "133px",
  overflow: "hidden",
  borderRadius: "8px",
}

const RowStack = styled(Box)({ display: 'flex' })
const ColStack = styled(Box)({ display: 'flex', flexDirection: 'column' })
export const ScrollableSection = styled(Box)({
  overflowX: "hidden",
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '13px'
  },
  '&::-webkit-scrollbar-track': {
    border: '0.94px solid #CBD5E1'
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '28.34px 28.34px 85.01px',
    backgroundColor: '#EDF1F6',
    border: '0.94px solid #94A3B8'
  }
})
const CustomButton = styled(Button)({
  borderRadius: "30px"
})
const TextOnWhiteButton = styled(Button)(({ theme }) => ({
  color: theme.palette.info.light,
  "&:disabled": { backgroundColor: "#fff", color: "#000" }
}))
const Link = styled('a')(({ theme }) => ({
  lineHeight: "22px",
  color: theme.palette.info.contrastText,
  textDecorationLine: "none",
  textDecorationColor: theme.palette.info.contrastText,
  "&:hover": { 
    color: theme.palette.info.contrastText,
    textDecorationLine: "underline",
    textDecorationColor: theme.palette.info.contrastText,
  }
}))
const ArrowUpwardIcon = styled(ArrowUpward)({
  transform: "rotate(24.43deg)",
  fill: "#1A469C !important",
  color: "#1A469C"
})
const MainLayout = styled(Box)({
  height: "100vh",
  width: "100%",
  overflowX: 'hidden'
})
export const FocusableContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  borderRadius: "18px",
  backgroundColor: theme.palette.action.focus,
}))
const CreateChapterButton = styled(Button)({
  width: "294px", 
  borderRadius: "30px",
  border: "1px solid #1A469C",
  backgroundColor: "#FFF"
})
const MainContainer = styled(Box)(({ theme }) => ({
  paddingTop: '10px',
  margin: "auto",
  height: 'fit-content',
  width: "90%",
  minHeight: '100vh',
  background: theme.palette.background.default,
  paddingBottom: 50,
  transition: 'all 100ms linear',
  '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
    borderBottom: 'transparent'
  },
  '& .MuiInput-underline::after': {
    borderBottom: 'transparent'
  },
  "& .MuiInput-underline::before": {
    borderBottom: 'transparent'
  },
  "& .ant-select": {
    width: "auto",
    height: '38px',
    fontSize: '14px',
    fontFamily: 'Rubik',
    lineHeight: '18px',
    fontWeight: 400,
    color: theme.palette.secondary.main,
  },
  "& .ant-select:not(.ant-select-customize-input) .ant-select-selector": {
    minWidth: '91px',
    borderRadius: "8px",
    padding: '4px 12px',
    height: '38px',
    borderColor: theme.palette.info.dark,
    background: theme.palette.background.default
  },
  "& .ant-select-dropdown": {
    fontFamily: 'Rubik',
    width: 'auto !important',
    fontSize: '12px',
    fontWeight: 400,
    background: theme.palette.action.focus,
    minWidth: "200px",
  },
  "& .ant-select-arrow": {
    color: theme.palette.info.main
  },
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.primary.main,
  },
}));
const BackIconButton = styled(IconButton)({
  width: "40px",
  height: "40px",
  borderRadius: "30px", 
  border: "none",
  backgroundColor: "#FFF", 
})
const LeftSection = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  borderRadius: '18px',
  marginRight: '10px',
})
const ContainerWithBg = styled(Box)(({ theme }) => ({
  height: '100%',
  marginRight: '10px',  
  overflowY: 'scroll',
  paddingRight: '22px',
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.action.focus,
  '&::-webkit-scrollbar': {
    width: '13px'
  },
  '&::-webkit-scrollbar-track': {
    border: '0.94px solid #CBD5E1'
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '28.34px 28.34px 85.01px',
    backgroundColor: '#EDF1F6',
    border: '0.94px solid #94A3B8'
  }
}))
const ContainerOuter = styled(Box)(({ theme }) => ({
  borderRadius: '18px',
  padding: '43px 14px',
  flex: 1,
  background: theme.palette.action.focus,
}))
const ChapterCard = styled(Box)(({ theme }) => ({
  minHeight: "109px",
  border: `1px solid ${theme.palette.info.dark}`,
  borderRadius: "8px",
}))
const RightSection = styled(Grid)(({ theme }) => ({
  display: 'flex',
  borderRadius: '18px',
  background: theme.palette.action.focus,
  flexDirection: 'column',
  "& .publishBg:hover": {
    background: theme.palette.info.contrastText
  },
  "& .selected": {
    border: `1.5px solid ${theme.palette.info.contrastText}`
  }
}))
const TagInputSection = styled(RowStack)(({ theme }) => ({
  borderRadius: "18px", 
  alignItems: "center", 
  justifyContent: "space-between", 
  backgroundColor: theme.palette.background.default, 
  padding: "9px 8px"
}))
const CourseInfoSection = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  paddingLeft: "6px"
})
const CourseTitleSection = styled(RowStack)({
  alignItems: 'center',
  gap: "8px"
})
const CourseTitleDescription = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '21px',
  textAlign: 'left',
  color: theme.palette.info.main,
}))
const FileUploadZone = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "22px",
  height: "121px",
  border: `1px dashed ${theme.palette.secondary.contrastText}`,
  backgroundColor: theme.palette.primary.main,
}))

const SupportText = styled('span')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '10px',
  lineHeight: '12px',
}))
const AddTypo = styled(Typography)(({theme})=>({
  fontFamily: "Rubik",
  fontSize: "12px",
  lineHeight: "21px",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: theme.palette.text.primary,
}))
const CourseInfoTitle = styled(Typography)(({ theme }) => ({
  fontSize: '32px',
  lineHeight: '38px',
  fontWeight: 500,
  textWrap:"nowrap",
  color: theme.palette.secondary.main
}))
const NoLessonTypo = styled(Typography)({
  paddingLeft: "13px",
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "21px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#334155"
})
const TagColor = styled(Box)({
  width: "25px",
  height: "25px",
  borderRadius: "50%",
  margin: "3px",
  padding: "10px"
})
const LessonTypeSelect = styled(ColStack)(({ theme }) => ({
  width: "100%",
  padding: "40px 20px",
  gap: "12px",
  borderRadius: "20px",
  backgroundColor: theme.palette.action.focus
}))
const LessonTypeDiv = styled(ColStack)(({ theme }) => ({
  margin: "8px",
  width: "157px",
  height: "135px",
  borderRadius: "12px",
  backgroundColor: theme.palette.action.active,
  alignItems: "center",
  justifyContent: "center",
  cursor: 'pointer',
}))
const ChapterHeaderBox = styled(RowStack)(({ theme }) => ({
  padding: "8px 4px",
  borderRadius: "7px",
  backgroundColor: theme.palette.action.focus,
  justifyContent: "space-between",
  alignItems: "center",
  height: '42px'
}))
const ChapterBody = styled(ColStack)(({ theme }) => ({
  // gap: "32px",
  padding: "8px",
  borderRadius: "0 0 8px 8px",
  backgroundColor: theme.palette.background.default,
}))
const AccordionSummary = styled(RowStack)({
  alignItems: "center", 
  gap: "10px"
})
const AccordionDetails = styled(ColStack)({
  marginTop: "5px",
  paddingLeft: "27px",
  gap: "4px"
})
const ExpandIcon = styled(ArrowRight)({
  fill: "#1F1F1F",
  transition: "transform 0.2s ease",
  cursor: "pointer"
})
const AddFileBox = styled(RowStack)({
  padding: "10px",
  borderRadius: "8px",
  border: "1px solid #CBD5E1",
  backgroundColor: "#FFF",
  justifyContent: "space-between",
  alignItems:"center",
})
const CourseDetailsSection = styled(Box)({
  marginTop: '12px',
  width: "100%",
  height:"376px",
  gap: "10px",
  borderRadius: "18px",
  '& .modalTextEditor .ql-container.ql-snow': {
    height: '100%',
    border: 'none'
  }
})
const FormLayout = styled(ColStack)(({ theme }) => ({
  padding: "34px 18px",
  width: "100%",
  borderRadius: "18px",
  gap: "25px",
  backgroundColor: theme.palette.action.focus,
  '& .modalTextEditor .ql-container.ql-snow': {
    height: '100%',
    border: 'none'
  }
}))
const NewChapterBox = styled(ColStack)(({theme}) => ({
    padding: "38px 25px 143px",
    gap: "10px",
    borderRadius: "18px",
    backgroundColor: theme.palette.action.focus
}))
const CoverUploadSection = styled(RowStack)({
  width: "100%",
  maxHeight: "271px",
  gap: '28px'
})
const GroupLabel = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: "26px",
  color: theme.palette.info.main
}))
const ChapterTitleTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "26px",
  color: theme.palette.info.main
}))
const LessonTypeText = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  fontFamily: 'Rubik',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '30px',
  textTransform: "capitalize",
}))
export const RadioDiv = styled(Box)({
  display: 'flex',
  flexDirection: 'row'
})
export const RadioOptionHead = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '20px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.41px',
  color: theme.palette.info.main,
  marginLeft: '10px'
}))
export const RadioOptionSubHead = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  color: theme.palette.info.light,
  marginLeft: '10px'
}))
const SubLessonFormTitle = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  textTransform: "capitalize",
  fontWeight: 500,
  fontSize: "20px",
  lineHeight: "24px",
  color: theme.palette.info.main
}))
export const FileNameTxt = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '20px',
}))
export const FileSizeTxt = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.light,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
}))
export const FileViewDiv = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: 'row',
  padding: '8px',
  justifyContent: 'space-around',
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '8px',
  alignItems: 'center'
}))
export const MenuLines = styled(MenuIcon)(({ theme }) => ({
  height: '42px',
  width: '42px',
  background: theme.palette.background.paper,
  padding: '12px',
  borderRadius: "6px"
}))
const DateField = styled(DatePicker)(({ theme }) => ({
  background: theme.palette.background.default,
  width: '100%',
  padding: '10px 12px',
  borderRadius: '8px',
  border: `0.94px solid ${theme.palette.info.dark}`,
  margin: '5px auto',
  '& .ant-picker-input > input': {
    color: theme.palette.info.light,
    fontFamily: 'Rubik',
    fontSize: '13.22px',
    fontWeight: 700,
    lineHeight: '20.78px',
    textAlign: 'left',
    "&:placeholder-shown": { fontWeight: 400 }
  },
  '& .ant-picker-suffix': {
    color: theme.palette.info.light
  },
  '& .ant-picker-input:hover .ant-picker-clear': {
    opacity: 1,
    display: 'none !important'
  },
  '& .ant-picker-focused': {
    boxShadow: 'none'
  }
}))
const StepperContainer = styled(RowStack)({ width: "100%" })
const StepperItem = styled(Box)({
  width: '97px',
  paddingBottom: '12px',
  fontSize: '12px',
  fontWeight: 'bolder',
  lineHeight: '18px',
  textAlign: 'center',
})
const ButtonGroup = styled(RowStack)({
  width: "70%", 
  marginRight: "auto", 
  marginLeft: "auto", 
  marginTop: '31px',
  alignItems: "center", 
  justifyContent: "center", 
  gap: "63px"
})

// Customizable Area End
