import { Box, styled } from "@material-ui/core";
import  axios from 'axios';
import React, { CSSProperties, useRef } from "react";
import ReactQuill, { Quill, ReactQuillProps } from "react-quill";
import 'react-quill/dist/quill.snow.css';

Quill.register('modules/counter', function (quill: any, options: { container: string }){
    const container = document.querySelector(options.container);
    
    quill.on('text-change', function() {
        if (container) {
            container.innerHTML = quill.root.innerHTML.length + '/1000';
            if (quill.root.innerHTML.length > 1000) {
                container.className = container.classList.value + " exceeded";
            } else {
                container.classList.remove('exceeded');
                container.className = container.classList.value;
            }
        }
    });
});

  
const modules = {
    toolbar: [
        ['bold', 'underline'],
        ['link'],
        [{ header: 1 }],
        [{ align: [] }],
        [{ list: 'bullet' }],
        ['clean'],
    ],
    counter: {
        container: "#counter"
    }
}

interface RichTextEditorProps extends ReactQuillProps {
    id?: string;
    name?: string;
    required?: boolean;
    label?: string;
    labelStyle?: CSSProperties;
    bgColor?: string;
    errorText?: string;
}

const RichTextEditor = (props: RichTextEditorProps) => {
    const { id, required, label, labelStyle, bgColor, errorText, value, onChange, ...richTextEditorProps } = props;

    const quillRef = useRef<ReactQuill>(null);

    const checkSpelling = async (content: string) => {
        try {
            const response = await axios.get('https://api.textgears.com/spelling', {
                params: {
                    key: 'yJ85cVqAAMVkya7O',
                    text: content
                }
            });
    
            const suggestions1 = response.data.response.errors.map((error: any) => ({
                word: error.bad,
                suggestions: error.better,
            }));

            return suggestions1;
        } catch (error) {
            console.log(error)
        }
    }
    const handleSelectionChange = (range: any) => {
        if (quillRef && range && range.length > 0) {
          const quill = quillRef?.current?.getEditor();
          if (quill) {
            const selectedText = quill.getText(range.index, range.length);
            checkSpelling(selectedText);
          }
        }
    }
    
    return (
        <Container>
            {label && (
                <Label 
                    htmlFor={id} 
                    role="alert" 
                    style={labelStyle}
                >
                    {label} {required && <span style={{ fontSize: '8px' }}>(Required <span style={{ color: "#DC2626" }}>*</span>)</span>}
                </Label>)}
            <Box id="quill-container" onContextMenu={handleSelectionChange} style={{ backgroundColor: "#FFF" }}>
                <ReactQuill
                    ref={quillRef}
                    // formats={formats}
                    modules={modules}
                    bounds="#quill-container"
                    style={{ backgroundColor: bgColor, border: !!errorText ? '1px solid #DC2626' : '' }}
                    value={value}
                    onChange={onChange}
                    {...richTextEditorProps}
                />
            </Box>
            <CharacterCount id="counter">0/1000</CharacterCount>
            {errorText && <HintText>{errorText}</HintText>}
        </Container>
    )
}


export default RichTextEditor

const Container = styled(Box)(({ theme }) => ({
    width: '100%',
    maxWidth: '100%',
    "& .ql-container": {
        borderRadius: '8px',
        height: "174px",
        border: 'none'
    },
    '& .quill': {
        position: 'relative',
        borderRadius: '8px',
        paddingBottom: 55,
        border: `1px solid ${theme.palette.info.dark}`,
    },
    "& .ql-toolbar": {
        borderRadius: '8px',
        border: `none`,
        position: "absolute",
        bottom: "10px",
        left: 10,
        width: "fit-content",
        transform: "translateY",
        zIndex: 1000,
        '& .ql-formats': {
        marginRight: 0
        },
        '& .ql-formats button, & .ql-formats .ql-picker': {
        border: '1px solid #94A3B8',
        marginRight: 2
        },
    },
    "& .ql-editor": {
        background: theme.palette.background.default,
        color: theme.palette.secondary.main,
        borderRadius: 8,
        fontSize: '14px',
        fontFamily: 'Rubik'
    },
    "& .ql-editor.ql-blank::before": {
        color: theme.palette.info.light,
        fontStyle: 'normal',
        fontFamily: 'Rubik',
        fontSize: '14px'
    },
}))
const Label = styled('label')(({ theme }) => ({
    fontFamily: 'Rubik',
    paddingBottom: '4px',
    color: theme.palette.text.primary,
    lineHeight: '22px'
}))
const HintText = styled('p')({
    display: 'inline-block',
    marginTop: '2px',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#DC2626'
})
const CharacterCount = styled(Box)({
    marginBottom: "2px",
    backgroundColor: "transparent",
    fontFamily: "Rubik",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#334155",
    textAlign: "right",
    "&.exceeded": { color: "#DC2626" }
})