import { Box, styled, Typography } from "@material-ui/core";
import React, { CSSProperties, ReactNode } from "react";

type WithChildren<T = {}> = T & { children?: ReactNode }

type ICardProps = WithChildren<{
    thumbnailSrc?: string;
    title: string;
    subtitle?: string;
    actionButton: ReactNode;
    style?: CSSProperties;
}>

const Card = (props: ICardProps) => {
    const { thumbnailSrc = "", title, subtitle = "", children, actionButton, style } = props;

    return (
        <Container style={style}>
            <RowStack style={{ justifyContent: "space-between" }}>
                <RowStack style={{ gap: "12px" }}>
                    <ThumbnailImage src={thumbnailSrc} alt="Thumbnail Image" />
                    <ColStack style={{ gap: "2px" }}>
                        <CardTypo>{title}</CardTypo>
                        {subtitle && <SubtitleTypo>{subtitle}</SubtitleTypo>}
                    </ColStack>
                </RowStack>
                <RowStack style={{ gap: "22px" }}>
                    {children}
                    {actionButton}
                </RowStack>
            </RowStack>
        </Container>
    )
}

export default Card;

const RowStack = styled(Box)({
    display: "flex",
    alignItems: "center"
})
const ColStack = styled(Box)({
    display: "flex",
    flexDirection: "column"
})
const Container = styled(Box)({
    width: '100%',
    border: "0.5px solid #E2E8F0",
    borderRadius: "18px",
    padding: "8px 16px",
    backgroundColor: "#FFF"
})
const ThumbnailImage = styled('img')({
    width: '42px',
    height: '42px',
    objectFit: 'cover',
    objectPosition: 'center'
})
const CardTypo = styled(Typography)({
    fontFamily: "Rubik",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#0F172A"
})
const SubtitleTypo = styled(CardTypo)({
    color: "#475569"
})