// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "projectnotes";
exports.labelBodyText = "projectnotes Body";
exports.createNoteApiEndPoint = "bx_block_projectnotes/notes";
exports.btnExampleTitle = "CLICK ME";
exports.deleteNoteApiEndPoint = "DELETE";
exports.updateNoteMethod = "PUT";
exports.httpsRegex = /^(?:https?:\/\/)?([a-z\.-]+)\.([a-z]{2,6})(?:\/(\w|-)*)*\/?$/i;
exports.htmlRegex = /<[^<>]+>/g;
exports.postProjectAPIEndpoint = "/bx_block_projectnotes/notes";
exports.getAllNotesAPIEndpoint = "/bx_block_projectnotes/notes";
exports.getMyNotesAPIEndpoint = "/bx_block_projectnotes/notes/my_notes";
exports.getIndividualAPIEndpoint = "/bx_block_projectnotes/notes/";
exports.deleteNoteAPIEndpoint = "bx_block_projectnotes/notes/";
exports.getCourseDataAPIEndpoint = "bx_block_profile/courses";
exports.postFeedbackAPIEndpoint = "bx_block_profile/feedbacks";
// Customizable Area End
