// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import React, {createRef} from 'react';
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { apiCall } from "./components/ApiCall";
import { Alert, Linking } from "react-native";

import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}
export interface ItemData {
  id: "259",
  type: "note",
  attributes: {
    id: 259,
    note_content: "<div><a href=\"www.instagram.com\">www.instagram.com</a></div>",
    reference_id: 1,
    is_deleted: false,
    is_updated: false,
    created_at: "2023-06-07T11:53:01.383Z",
    updated_at: "2023-06-07T11:53:01.383Z",
    created_time: "11:53:01",
    created_date: "2023-06-07"
  },
}

export interface ResponseData {
  message: boolean;
  id: number,
  type: string,
  attributes: ObjectAttributes,
  data: []
}

interface ObjectAttributes {
  id: number,
  note_content: string,
  reference_id: number,
  is_deleted: false,
  is_updated: false,
  created_at: string,
  updated_at: string,
  created_time: string,
  created_date: string
}

export interface DeleteItem {
  attributes: {
    created_at: "",
    created_date: "",
    created_time: "",
    id: 96,
    is_deleted: false,
    is_updated: false,
    note_content: "",
    reference_id: 1,
    updated_at: ""
  },
  id: "",
  type: ""
}
export interface Image {
  id: number;
  url: string;
}

interface FileContent {
  fileName: string;
  storage: string;
  download?: boolean;
}

interface MediaContent {
  fileContent1: FileContent[];
  image1: string;
  image2: string;
  fileContent2: FileContent[];
  fileContent3: FileContent[];
}
export interface AudioState {
  isAudioPlaying: boolean;
  currentAudioTime: number;
  audioDuration: number;
  audioProgress: number;
}

interface RemainingTime  {
  [chapterIndex: number]: number; 
};

export interface NoteAttributes {
  id: number;
  title: string;
  note_content: string;
  account_id: number;
  role_id: string;
  preferred_name: string;
  course_id: number;
  is_deleted: boolean;
  is_updated: boolean;
  created_at: string;
  updated_at: string;
  images: Image[];
  created_time: string;
  created_date: string;
}
export interface IndNoteAttributes {
  id: number;
  title: string;
  note_content: string;
  account_id: number;
  role_id: string;
  preferred_name: string;
  course_id: number;
  is_deleted: boolean;
  is_updated: boolean;
  created_at: string;
  updated_at: string;
  images: Image[];
  created_time: string;
  created_date: string;
}

export interface Notes {
  id: string;
  type: string;
  attributes: NoteAttributes;
}
export interface Note {
  id: string;
  type: string;
  attributes: IndNoteAttributes;
}

export interface NotesData {
  data: Notes[];
}
export interface NoteDataResponse {
  data:Note | [];
}
interface AudioChapter {
  intro: string;
  time: number;
  src: string;
}
interface Video {
  intro: string;
  time: number;
  type: 'ppt' | 'video' | 'pdf' | 'audio';
  src?: string; 
  audioDetails?: AudioChapter[]; 
}
type responseData=string | number | boolean;
interface Colors {
   color:string;
   bgColor:string;
}
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  notesList: [];
  isLoading: boolean;
  notesData: string;
  open:boolean;
  darkTheme:boolean;
  openNoteModal:boolean;
  openDialog:boolean;
  openImageModal:boolean;
  anchorEl: null | HTMLElement;
  uploadImage: File | null;
  uploadVideo: File | null;
  imagePreview: string;
  videoPreview: string;
  openMoreMenu:boolean;
  title:string;
  note:string;
  allNotes:NotesData;
  currentSection:string;
  openIndSection:boolean;
  enableEdit:boolean;
  editId:string;
  submitNoteModal:boolean;
  editModal:boolean;
  deleteModal:boolean;
  deleteSuccessfulModal:boolean;
  openMoreMenuId:string;
  rightMinimise: boolean;
  leftMinimise: boolean;
  startCourse: boolean;
  progress: number;
  Chapters: Video[];
  Media: MediaContent[];
  selectedVideoIndex: number;
  unlockedVideoIndex: number[];
  unlockedChapterIndex: number;
  selectedChapterIndex: number;
  timerRunning: boolean;
  remainingTime: RemainingTime;
  currentChapterIndex: number; 
  isPlaying: boolean,
  currentTime: number,
  duration: number,
  showControls: boolean,
  isAudioPlaying: boolean,
  currentAudioTime: number,
  audioDuration: number,
  audioProgress: number;
  currentPlayingAudioId: number | null;
  currentVideoIndex: number;
  videoCompleted: boolean;
  autoplay: boolean, 
  isExpanded: boolean,
  isMuted: boolean,
  volume: number, 
  audioStates: {
    [index: number]: AudioState;
  };
  pageNumber: number,
  showVolumeBar: boolean,
  isMediaClicked: boolean,
  userRole:string;
  course_status:string;
  previewMode:boolean;
  statusColors:Colors;
  isApproved:boolean;
  isRejectedPopover:boolean;
  isRejected:boolean;
  anchorElPopover:null | HTMLElement;
  course_name:string;
  course_category:string;
  instr_name:string;
  lessons:number;
  course_image:string;
  feedback:string;
  assignModal : boolean,
  companiesNames: { "attributes": { [key: string]: responseData } }[];
  searchCompany:string,
  companyFilterData: { "attributes": { [key: string]: responseData} }[];
  companyName:string|number|undefined|boolean,
  companyId:string|number,
  groupData: any,
  groupDataFilter: { "attributes": { [key: string]: responseData } }[],
  searchGroup:string,
  showGroupModal:boolean,
  selectedGroups:(string|number|boolean)[],
  selectedGroupsName:string[],
  showSuperVisorModal:boolean,
  superVisorData: { [key: string]:responseData } [],
  superVisorDataFilter:{ [key: string]: responseData } [],
  selectedSuperVisor:(responseData)[],
  searchSupervisor:string,
  showDoneModal:boolean,
  showAllSelectedGroups:boolean
}

interface SS {
  id: any;
}

const dummyChapters: Video[] = [
  { intro: 'Intro 1', time: 3000, type: 'video', src: "https://www.w3schools.com/html/mov_bbb.mp4" },
  { intro: 'Intro 2', time: 2000, type: 'video', src: "https://www.w3schools.com/html/mov_bbb.mp4" },
  { intro: 'Intro 1', time: 2000, type: 'ppt' },
  { 
    intro: 'Intro 2', 
    time: 2000, 
    type: 'audio',
    audioDetails: [
      { intro: 'Audio Name', time: 128, src: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"},
      { intro: 'Audio Name', time: 128, src: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3"},
      { intro: 'Audio Name', time: 128, src: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-3.mp3"},
      { intro: 'Audio Name', time: 128, src: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-4.mp3"},
      { intro: 'Audio Name', time: 128, src: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-5.mp3"},
    ],
  },
  { intro: 'Intro 4', time: 4000, type: 'pdf' },
];

const dummyMediaContent: MediaContent[] = [
  {
    fileContent1: [
      { fileName: 'File Name1.doc', storage: '128 KB', download: true },
    ],
    image1: '/static/media/courseVideo.8da68e6b.png',
    image2: '/static/media/courseVideo.8da68e6b.png',
    fileContent2: [
      { fileName: 'File Name1.doc', storage: '128 KB', download: true },
    ],
    fileContent3: [
      { fileName: 'File Name1.doc', storage: '128 KB', download: false },
    ],
  },
];

export default class ProjectnotesController extends BlockComponent<
  Props,
  S,
  SS
> {
  getNotesListApiCallId: string = "";
  deleteNotesCallId: string = "";
  postProjectNotesAPIId:string="";
  getAllListNoteAPIId:string="";
  editProjectNotesAPIId:string="";
  getCourseApiCallId:string="";
  approveCourseApiCallId:string="";
  rejectCourseApiCallId:string="";
  deleteProjectNoteAPId:string="";
  getCompanyDetailsApiCallId:string="";
  getGroupsApiCallId:string="";
  getGroups1ApiCallId:string="";
  getSupervisorApiCallId:string="";
  askForReviewApiCallId:string="";
  quillRef:any;
  videoRef = createRef<HTMLVideoElement>();
  courseNameRef = createRef<HTMLDivElement>();
  audioRefs: (HTMLAudioElement | null)[] = [];

  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      notesList: [],
      isLoading: false,
      notesData: "",
      open:false,
      darkTheme:false,
      openNoteModal:false,
      openDialog:false,
      openImageModal:false,
      anchorEl: null,
      uploadImage: null,
      uploadVideo: null,
      imagePreview: '',
      videoPreview: '',
      openMoreMenu:false,
      title:"",
      note:'',
      allNotes:{data: [
        {
          id: '',
          type: 'note',
          attributes: {
            id: 0,
            title: '',
            note_content: '',
            account_id: 0,
            role_id: '',
            preferred_name: '',
            course_id: 0,
            is_deleted: false,
            is_updated: false,
            created_at: '',
            updated_at: '',
            images: [],
            created_time: '',
            created_date: '',
          },
        },
      ],},
      currentSection:"all_notes",
      openIndSection:false,
      enableEdit:false,
      editId:"",
      submitNoteModal:false,
      editModal:false,
      deleteModal:false,
      deleteSuccessfulModal:false,
      openMoreMenuId:"",
      rightMinimise: false,
      leftMinimise: false,
      startCourse: false,
      progress: 0,
      Chapters: dummyChapters,
      Media: dummyMediaContent,
      selectedVideoIndex: 0, 
      unlockedVideoIndex: [0],
      unlockedChapterIndex: 0,
      selectedChapterIndex: 0,
      timerRunning: false,
      remainingTime: {
        0: 3,
        1: 2, 
        2: 2,
        3: 2,
        4: 4
      }, 
      currentChapterIndex: 0, 
      isPlaying: false,
      currentTime: 0,
      duration: 0,
      showControls: false,
      isAudioPlaying: false,
      currentAudioTime: 0,
      audioDuration: 0,
      audioProgress: 0,
      currentPlayingAudioId: null,
      currentVideoIndex: 0,
      videoCompleted: false,
      autoplay: false, 
      isExpanded: false,
      isMuted: false,
      userRole:"",
      course_status:"",
      previewMode:false,
      statusColors:{
        color:"#000000",
        bgColor:"transparent",
      },
    volume: 1, 
    audioStates: {},
    pageNumber: 1,
    showVolumeBar: false,
    isMediaClicked: false,
    isApproved:false,
    isRejectedPopover:false,
    isRejected:false,
    anchorElPopover:null,
    course_name:"",
    course_category:"",
    instr_name:"",
    lessons:10,
    course_image:"",
    feedback:"",
    assignModal: false,
    companiesNames:[],
    searchCompany:"",
   companyFilterData:[],
   companyName:"",
   companyId:"",
   groupData:[], 
   groupDataFilter:[],
   showGroupModal:false,
   selectedGroups:[],
   selectedGroupsName:[],
   showSuperVisorModal:false,
   superVisorData:[],
   superVisorDataFilter:[],
   selectedSuperVisor:[],
   searchGroup:"",
   searchSupervisor:"",
   showDoneModal:false,
   showAllSelectedGroups:false,
    }
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: this.doButtonPressed,
  };

  doButtonPressed() {
    let mesg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    mesg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(mesg);
  }

  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.currentVideoIndex !== this.state.currentVideoIndex) {
      if (this.state.selectedChapterIndex !== this.state.currentVideoIndex) {
        this.setState({ selectedChapterIndex: this.state.currentVideoIndex });
      }
    }
  
    if (prevState.selectedChapterIndex !== this.state.selectedChapterIndex) {
      if (this.state.currentVideoIndex !== this.state.selectedChapterIndex) {
        this.setState({ currentVideoIndex: this.state.selectedChapterIndex });
      }
    }
  }

  async componentDidMount() {
    this.getCourse();
    this.setState({userRole: await getStorageData("user_role")})
    if(this.state.userRole === "instructor" || "supervisor"){
      this.setState({startCourse:true})
    }
    const previewMode = await getStorageData("previewMode")
    if(previewMode === "true"){
      this.setState({previewMode:true})
    }
  }

  handleChapterSelect = (index : any) => {
    if (index <= this.state.unlockedChapterIndex) {
      this.setState({
        selectedChapterIndex: index,
        timerRunning: false,
        currentVideoIndex: index,
      });
    }
  };

  startChapterTimer = (selectedChapterTime: any) => {
    const { selectedVideoIndex, Chapters, selectedChapterIndex } = this.state;
    const chapter = Chapters[selectedVideoIndex];
  
    if (selectedChapterTime) {
      this.setState((prevState) => ({
        timerRunning: true,
        remainingTime: {
          ...prevState.remainingTime,
          [selectedChapterIndex]: Math.floor(selectedChapterTime / 1000), 
        },
      }));
    }
  
      const interval = setInterval(() => {
        this.setState((prevState) => {
          const currentTime = prevState.remainingTime[selectedChapterIndex] - 1;
      
          if (currentTime <= 0) {
            clearInterval(interval);
            return {
              remainingTime: {
                ...prevState.remainingTime,
                [selectedChapterIndex]: 0, 
              },
              timerRunning: false, 
            };
          }
      
          return {        
            remainingTime: {
              ...prevState.remainingTime,
              [selectedChapterIndex]: currentTime,
            },
            timerRunning: true, 
          };
        });
      }, 1000);
      setTimeout(() => {
        this.setState((prevState) => {
          const nextUnlockedIndex = selectedChapterIndex + 1;
          return {
            unlockedChapterIndex: Math.max(prevState.unlockedChapterIndex, nextUnlockedIndex),
          };
        });
      }, selectedChapterTime);
 
  };


  showNotesModalUpdateState = (item: ItemData) => {
    this.props.navigation.navigate("NewProjectNotes", { data: item })
  }

  showNotesModalSetState = () => {
    this.props.navigation.navigate("NewProjectNotes")
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id ) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)

      )

      if (responseJson && responseJson.data) {
        if (apiRequestCallId === this.getCourseApiCallId) {
          this.setState({
            course_name:responseJson.data.attributes.course_name,
            course_status:responseJson.data.attributes.status,
            statusColors: responseJson.data.attributes.status_colors,
            course_category:responseJson.data.attributes.category_name,
            instr_name:responseJson.data.attributes.instructor,
            course_image:responseJson.data.attributes.image,
          })
        }
        if (apiRequestCallId === this.approveCourseApiCallId) {
          this.setState(
            { isApproved: true },
            () => {
              setTimeout(() => {
                this.setState({ isApproved: false });
              }, 2000);
            }
          );
          this.getCourse()
        }
        if (apiRequestCallId === this.rejectCourseApiCallId) {
          this.getCourse();
            this.setState(
              {
                isRejected: false,
                isRejectedPopover: true,
              },
              () => {
                setTimeout(() => {
                  this.setState({ isRejectedPopover: false });
                }, 2000);
              }
            );
        }  
      }     
      if(apiRequestCallId===this.getCompanyDetailsApiCallId){
        this.setState({companiesNames:responseJson.data})
      }
      if(apiRequestCallId===this.getGroupsApiCallId){
        this.setState({groupData:responseJson.data,groupDataFilter:responseJson.data})
      }
      if(apiRequestCallId===this.getSupervisorApiCallId){
        this.setState({superVisorData:responseJson.supervisors,superVisorDataFilter:responseJson.supervisors})
      }
    }
  }
  handleClose=()=>{
    if(this.state.assignModal===true
      &&this.state.showGroupModal===false
      &&this.state.showSuperVisorModal===false){
      this.setState({assignModal:false,companyName:"",searchCompany:""})
    }else if(this.state.showGroupModal===true){
      this.setState({showGroupModal:false,selectedGroups:[]})
    }else if(this.state.showSuperVisorModal===true){
      this.setState({showSuperVisorModal:false})
    }
  }
  getAllCompanies = async () => {
    const token = await getStorageData('authToken');
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCompanyDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/tasks/company_list`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getGroups = async () => {
    const token = await  getStorageData('authToken');
    console.log(token)
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGroupsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/tasks/group_list?id=${this.state.companyId}`
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  companySearch=(event: any) => {
    const value = event.target.value.toLowerCase();
    this.setState({ searchCompany: value }, () => {
      const filtered = this.state.companiesNames.filter((company: any) =>
        company.attributes.company_name.toLowerCase().includes(value)
      );
      this.setState({ companyFilterData: filtered });
    });
  
  };
  groupSearch=(event:any)=>{
    const value=event.target.value.toLowerCase()
    this.setState({ searchGroup: value }, () => {
      const filtered = this.state.groupData.filter((company: any) =>
        company.attributes.group_name.toLowerCase().includes(value)
      );
      this.setState({ groupDataFilter: filtered });
    });
  }
  askForReview=()=>{
  this.setState({showDoneModal:true,showGroupModal:false,showSuperVisorModal:false})
  this.createTask();
  }
  createTask=async ()=>{
    let token = await getStorageData('authToken')
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const httpBody = {
        "task": {
            "title": "Review Course Content",
            "description": "Click on the course to view content and review it",
            "due_date": null,
            "task_lists_attributes": [],
            "status": "in_progress",
            "is_important": false,
            "is_assigned": true,
            "assign_ids": this.state.selectedSuperVisor,
            "course_id":localStorage.getItem("courseId"),
            company_ids:[this.state.companyId]
        }
    
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.askForReviewApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/tasks`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `POST`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDone=()=>{
    this.setState({showDoneModal:false,assignModal:false,companyName:"",companyId:"",selectedGroups:[],selectedSuperVisor:[]})
  }
  superVisorSearch=(event:any)=>{
    const value=event.target.value.toLowerCase()
    this.setState({ searchSupervisor: value }, () => {
      const filtered = this.state.superVisorData.filter((company: any) =>
        company.first_name.toLowerCase().includes(value)
      );
      this.setState({ superVisorDataFilter: filtered });
    });
  }
  showSuperVisorModal=()=>{
    this.showSuperVisor();
    this.setState({showSuperVisorModal:true,showGroupModal:false})
  }
  showSuperVisor=async ()=>{
    const token = await  getStorageData('authToken');
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSupervisorApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/courses/supervisors?company_id=${this.state.companyId}`
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSelectCompany=(event:any)=>{
    const selectedCompanyId = event.target.value;
    const selectedCompany = this.state.companiesNames.find(
      (company) => company.attributes.id === selectedCompanyId
    );
  
   
    if (selectedCompany) {
      this.setState({
        companyName: selectedCompany.attributes.company_name, 
      });
    }
    this.setState({companyId:selectedCompanyId})
  }
  selectCompany=(id:any)=>{
    const selectedCompanyId = id;
    const selectedCompany = this.state.companiesNames.find(
      (company) => company.attributes.id === selectedCompanyId
    );
  
   
    if (selectedCompany) {
      this.setState({
        companyName: selectedCompany.attributes.company_name, 
        searchCompany:String(selectedCompany.attributes.company_name)
      });
    }
    this.setState({companyId:selectedCompanyId})
  }
  showGroups=()=>{
    this.getGroups()
    this.setState({showGroupModal:true})
  }

  handleSelectGroup = (selectedId:any) => {
    this.setState((prevState) => {
      let selectedGroups1 = [...prevState.selectedGroups];
      if (selectedGroups1.includes(selectedId)) {
        selectedGroups1 = selectedGroups1.filter(id => id !== selectedId);
      } else {         
        selectedGroups1.push(selectedId);
      }
      return { selectedGroups: selectedGroups1 };
    }, () => {

    });
  };
  handleSelectSuperVisor= (selectedId:any) => {
    this.setState((prevState) => {
      let selectedSuperVisor1 = [...prevState.selectedSuperVisor];
      if (selectedSuperVisor1.includes(selectedId)) {
        selectedSuperVisor1 = selectedSuperVisor1.filter(id => id !== selectedId);
      } else {
        selectedSuperVisor1.push(selectedId);
      }
      return { selectedSuperVisor: selectedSuperVisor1 };
    }, () => {});
  };
  handleAssgingroup=async()=>{
   this.setState({showAllSelectedGroups:true})
    const courseId=await localStorage.getItem("courseId")
    const token = await  getStorageData('authToken');
    const header = {
      "Content-Type": "application/json",
      "Accept": "*/*",
      token
    };
    
    const httpBody = {
      group_ids:this.state.selectedGroups
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGroups1ApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/courses/${courseId}/submit_approval`
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
   'PUT'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  showNotesModalDeleteNotes = async (item: ItemData) => {
    const noteId = item.attributes.id;
    const bodyData = {
      reference_id: 1
    }
    this.deleteNotesCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: "DELETE",
      endPoint: `bx_block_projectnotes/notes/${noteId}`,
      body: bodyData
    });
  }

  handleMenu=()=>{
    this.setState({open:!this.state.open});
    localStorage.setItem('open',JSON.stringify(!this.state.open));
  }
  
  handleTheme=()=>{
    this.setState({darkTheme:!this.state.darkTheme})
  }

  handleEditModal=()=>{
    this.setState({
      editModal:true,
    })
  }

  handleDeleteModal=(noteId:string)=>{
    this.setState({
      deleteModal:true,
      editId:noteId
    })
  }
  closeDeleteModal=()=>{
    this.setState({
      deleteModal:false,
      deleteSuccessfulModal:true
    })
  }

  editPost=(note:any)=>{
    this.setState({
      title:note.attributes.title,
      note:note.attributes.note_content,
      editId:note.attributes.id,
      openNoteModal:true,
      enableEdit:true
    });
  }
  togglePlay = () => {
    const video = this.videoRef.current;
    if (video) {
      if (this.state.isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      this.setState((prevState) => ({ isPlaying: !prevState.isPlaying }));
    }
  };

  handleTimeUpdate = () => {
    const video = this.videoRef.current;
    if (video) {
      this.setState({ currentTime: video.currentTime });
    }
  };

  handleLoadedMetadata = () => {
    const video = this.videoRef.current;
    if (video) {
      this.setState({ duration: video.duration });
    }
  };

  handleProgressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const video = this.videoRef.current;
    if (video) {
      const value = parseFloat(e.target.value);
      video.currentTime = (value / 100) * this.state.duration;
      this.setState({ currentTime: video.currentTime });
    }
  };

  formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${minutes}:${formattedSeconds}`;
  };

  handleMouseEnter = () => {
    this.setState({ showControls: true });
  };

  handleMouseLeave = () => {
    this.setState({ showControls: false });
  };

  handleNextVideo = (videoChapters: any) => {
    this.setState((prevState) => {
      const nextIndex = prevState.currentVideoIndex + 1;
      if (nextIndex < videoChapters.length && prevState.unlockedVideoIndex.includes(nextIndex)) {
        if (this.videoRef?.current) {
          this.videoRef.current.currentTime = 0;
        }
        return { currentVideoIndex: nextIndex, videoCompleted: false };
      }
      return null;
    });
  };
  
  
  handlePreviousVideo = (videoChapters: any) => {
    this.setState(prevState => {
      const prevIndex = (prevState.currentVideoIndex - 1 + videoChapters.length) % videoChapters.length;
      if(prevState.currentVideoIndex > 0) {
        return { currentVideoIndex: prevIndex }
      }
      return null;
    });
  };
  
  getCurrentVideo = () => {
    const { currentChapterIndex } = this.state;
    const currentChapter = this.state.Chapters[currentChapterIndex];
    return currentChapter.type === 'video' ? currentChapter : null;
  };

  updateAudioProgress = (index: number) => {
    const audio = this.audioRefs[index];
    if (audio) {
      this.setState(prevState => ({
        audioStates: {
          ...prevState.audioStates,
          [index]: {
            ...prevState.audioStates[index],
            currentAudioTime: audio.currentTime,
            audioDuration: audio.duration,
            audioProgress: (audio.currentTime / audio.duration) * 100
          }
        }
      }));
    }
  };
  
  handleAudioLoadedMetadata = (index: number) => {
    const audio = this.audioRefs[index];
    if (audio) {
      this.setState(prevState => ({
        audioStates: {
          ...prevState.audioStates,
          [index]: {
            ...prevState.audioStates[index],
            audioDuration: isNaN(audio.duration) ? 0 : audio.duration
          }
        }
      }));
    }
  };

  toggleAudioPlayPause = (index: number) => {
    const audio = this.audioRefs[index];
    if (audio) {
      this.audioRefs.forEach((audioElement, i) => {
        if (audioElement && i !== index) {
          audioElement.pause();
          this.setState(prevState => ({
            audioStates: {
              ...prevState.audioStates,
              [i]: { ...prevState.audioStates[i], isAudioPlaying: false }
            }
          }));
        }
      });
  
      if (this.state.audioStates[index]?.isAudioPlaying) {
        audio.pause();
      } else {
        audio.play();
      }
  
      this.setState(prevState => ({
        audioStates: {
          ...prevState.audioStates,
          [index]: {
            ...prevState.audioStates[index],
            isAudioPlaying: !prevState.audioStates[index]?.isAudioPlaying
          }
        }
      }));
    }
  };
  

  formatAudioTime = (timeInSeconds: number) => {
    if (isNaN(timeInSeconds) || timeInSeconds === 0) {
      return "0:00";
    }
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  handleVideoEnded = (videoChapters: any) => {
    const { currentVideoIndex, unlockedVideoIndex,selectedVideoIndex } = this.state;
    const nextIndex = currentVideoIndex + 1;

    this.setState({videoCompleted: true, isPlaying: false});

    this.setState((prevState) => {
      const nextUnlockedIndex = selectedVideoIndex + 1;

      return {
        unlockedChapterIndex: Math.max(
          prevState.unlockedChapterIndex,
          prevState.selectedChapterIndex + 1
        ), 
        unlockedVideoIndex: [...prevState.unlockedVideoIndex, nextUnlockedIndex],
        timerRunning: false,
      };
    });

    if (nextIndex < videoChapters.length) {
      if(this.state.autoplay){
        this.setState({      
          currentVideoIndex: nextIndex,
          unlockedVideoIndex: [...unlockedVideoIndex, nextIndex],
          isPlaying: true,
          videoCompleted: false
        });
        if (this.videoRef?.current) {
          this.videoRef.current.currentTime = 0;
          this.videoRef.current.play();
        }
      }
    }
  };

  
  handleReplay = () => {
    if (this.videoRef.current) {
      this.videoRef.current.currentTime = 0;
      this.videoRef.current.play();
    }
    this.setState({ videoCompleted: false, isPlaying: true });
  };
  
  toggleAutoplay = () => {
    this.setState((prevState) => ({ autoplay: !prevState.autoplay }));
  };

  toggleExpand = () => {
    this.setState((prevState) => ({ isExpanded: !prevState.isExpanded }));
  };

  handleVolumeClick = () => {
    this.setState((prevState) => {
      const newMutedState = !prevState.isMuted;
      return {
        isMuted: newMutedState,
        volume: newMutedState ? 0 : prevState.volume,
      };
    });
  };

  handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(event.target.value);
    this.setState({ volume: newVolume });
    if (newVolume === 0) {
      this.setState({ isMuted: true });
    } else {
      this.setState({ isMuted: false });
    }
  };

  handleVolMouseEnter = () => {
    this.setState({ showVolumeBar: true });
  };

  totalSlides = 3;

  goToPreviousPage = () => {
    if (this.state.pageNumber > 1) {
      this.setState((prevState) => ({
        pageNumber: prevState.pageNumber - 1,
      }));
    }
  };

  goToNextPage = () => {
    if (this.state.pageNumber < this.totalSlides) {
      this.setState((prevState) => ({
        pageNumber: prevState.pageNumber + 1,
      }));
    }
  };

  handleMediaOpen = () => {
    this.setState({ isMediaClicked: true});
  }

  handleAboutOpen = () => {
    this.setState({ isMediaClicked: false});
  }

  handlePreviousChapter = () => {
    this.setState((prevState) => ({
      selectedChapterIndex: Math.max(prevState.selectedChapterIndex - 1, 0),
    }));
  };
  
  handleNextLesson = () => {
    this.setState((prevState) => ({
      selectedChapterIndex: Math.min(
        prevState.selectedChapterIndex + 1,
        prevState.Chapters.length - 1
      ),
    }));
  };
  getSelectionBoxStyle = (id: any) => {
    const isSelected = this.state.selectedSuperVisor.includes(id);
    return {
      width: isSelected ? "24px" : "16.67px",
      height: isSelected ? "24px" : "16.67px",
      borderRadius: "7.2px",
      background: isSelected ? "#18408E" : "transparent",
      border: isSelected ? "none" : "1.2px solid #18408E",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "all 0.3s ease-in-out",
      cursor: "pointer",
    };
  };
  getGroupSelectionStyle = (id: any) => {
    const isSelected = this.state.selectedGroups.includes(id);
    return {
      width: isSelected ? "24px" : "16.67px",
      height: isSelected ? "24px" : "16.67px",borderRadius: "7.2px",
      background: isSelected ? "#18408E" : "transparent",
      border: isSelected ? "none" : "1.2px solid #18408E",
      display: "flex",alignItems: "center",
      justifyContent: "center",
      transition: "all 0.3s ease-in-out",
      cursor: "pointer",
    };
  };
  
  goToPostCreationPage=()=> {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'PostCreation');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  showText=()=>{
    if(this.state.showSuperVisorModal){
      return "Review Course"
    }else{
      return "Assign Course"
    }
  }
  assignModal =()=> {
    this.getAllCompanies();
this.setState({assignModal:true})}

  getCourse = async()=>{
    const courseId = await getStorageData("courseId")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCourseApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCourseDataAPIEndpoint}/${courseId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
handleFeedBack=()=>{
    sessionStorage.setItem("step","4");
 this.goToPostCreationPage()
  }
  approveCourse = async () => {
    const courseId = await getStorageData("courseId")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.approveCourseApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCourseDataAPIEndpoint}/${courseId}/submit_approval`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateNoteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleClosePopover = () =>{
    this.setState({isApproved:false})
  }

  rejectCourse = () =>{
    this.setState({isRejected:true})
  }

  handleCloseReject = () =>{
    this.setState({isRejected:false})

  }
  
  submitFeedback = async () => {
    const courseId = await getStorageData("courseId")

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken")
    };

    const requestData = {
        message: this.state.feedback,
        course_id:courseId,
    };

    const httpBody = {
      feedback: requestData
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.rejectCourseApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postFeedbackAPIEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addFeedbackText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ feedback: event.target.value.slice(0, 500) });
  };  

}
// Customizable Area End
