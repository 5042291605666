// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import 'antd/dist/antd.css';
import 'react-toastify/dist/ReactToastify.css';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import AssessmentTest from "../../blocks/assessmenttest/src/AssessmentTest.web";
import Share from "../../blocks/share/src/Share";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import DownloadOptions from "../../blocks/downloadoptions/src/DownloadOptions";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import DocumentOpener from "../../blocks/documentopener/src/DocumentOpener";
import CustomProfileHeader from "../../blocks/customprofileheader/src/CustomProfileHeader";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Trash from "../../blocks/trash/src/Trash";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Videos from "../../blocks/videos/src/Videos";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import DataStorage from "../../blocks/datastorage/src/DataStorage";
import DocumentDistribution from "../../blocks/documentdistribution/src/DocumentDistribution";
import Richtexteditor1 from "../../blocks/richtexteditor1/src/Richtexteditor1";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Customfeature1559826823644 from "../../blocks/customfeature1559826823644/src/Customfeature1559826823644";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import OfflineBrowsing from "../../blocks/offlinebrowsing/src/OfflineBrowsing";
import PerformanceTracker from "../../blocks/performancetracker/src/PerformanceTracker.web";
import PtCoursesScreen from "../../blocks/performancetracker/src/PtCoursesScreen";
import PtTestsForCourseScreen from "../../blocks/performancetracker/src/PtTestsForCourseScreen";
import PtStudentsOnCourseScreen from "../../blocks/performancetracker/src/PtStudentsOnCourseScreen";
import PtTestScoresForStudentScreen from "../../blocks/performancetracker/src/PtTestScoresForStudentScreen";
import PtBasicUserCoursesScreen from "../../blocks/performancetracker/src/PtBasicUserCoursesScreen";
import PtBasicUserAllCoursesScreen from "../../blocks/performancetracker/src/PtBasicUserAllCoursesScreen";
import PtStudentRankingScreen from "../../blocks/performancetracker/src/PtStudentRankingScreen";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock.web";
import DailyScheduleNotifications from "../../blocks/dailyschedulenotifications/src/DailyScheduleNotifications.web";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import SpellCheck from "../../blocks/spellcheck/src/SpellCheck";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Download from "../../blocks/download/src/Download";
import DownloadUpload from "../../blocks/download/src/DownloadUpload";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import CreateComment from "../../blocks/comments/src/CreateComment";
import VideoLibrary from "../../blocks/videolibrary/src/VideoLibrary";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import QrCodes from "../../blocks/qrcodes/src/QrCodes";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Notifications from "../../blocks/notifications/src/Notifications";
import PdfEdit from "../../blocks/pdfedit/src/PdfEdit";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import Readermode from "../../blocks/readermode/src/Readermode";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu.web";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import DragDropInterface from "../../blocks/dragdropinterface/src/DragDropInterface";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import PostCreation from "../../blocks/postcreation/src/PostCreation.web";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Customiseinterface2 from "../../blocks/customiseinterface2/src/Customiseinterface2";
import CustomisableUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web";
import NotificationSettingWeb   from "../../blocks/notificationsettings/src/NotificationSettings.web";
import Automaticreminders  from "../../blocks/automaticreminders/src/Automaticreminders.web";
import BulletinBoard from "../../blocks/bulletinboard/src/BulletinBoard.web";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import CertificationTracking from "../../blocks/certificationtracking/src/CertificationTracking.web"
import Projectnotes from "../../blocks/projectnotes/src/Projectnotes.web";
import BreadcrumbNavigation from "../../blocks/breadcrumbnavigation/src/BreadcrumbNavigationPage.web"
import PtStudentInfoScreen from "../../blocks/performancetracker/src/PtStudentInfoScreen.web";
import TestScoring from "../../blocks/scoring/src/TestScoring.web"
import CourseFeedback from "../../blocks/postcreation/src/CourseFeedback.web"
const routeMap = {
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
AssessmentTest:{
 component:AssessmentTest,
path:"/AssessmentTest"},
Share:{
 component:Share,
path:"/Share"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
DownloadOptions:{
 component:DownloadOptions,
path:"/DownloadOptions"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
DocumentOpener:{
 component:DocumentOpener,
path:"/DocumentOpener"},
CustomProfileHeader:{
 component:CustomProfileHeader,
path:"/CustomProfileHeader"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Dashboard:{
  component:Dashboard,
path:"/Dashboard",
},
Projectnotes:{
  component:Projectnotes,
path:"/Projectnotes",
},
Trash:{
 component:Trash,
path:"/Trash"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Videos:{
 component:Videos,
path:"/Videos"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
DataStorage:{
 component:DataStorage,
path:"/DataStorage"},
DocumentDistribution:{
 component:DocumentDistribution,
path:"/DocumentDistribution"},
Richtexteditor1:{
 component:Richtexteditor1,
path:"/Richtexteditor1"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Customfeature1559826823644:{
 component:Customfeature1559826823644,
path:"/Customfeature1559826823644"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
OfflineBrowsing:{
 component:OfflineBrowsing,
path:"/OfflineBrowsing"},
PerformanceTracker:{
 component:PerformanceTracker,
path:"/PerformanceTracker"},
PtCoursesScreen:{
 component:PtCoursesScreen,
path:"/PtCoursesScreen"},
PtTestsForCourseScreen:{
 component:PtTestsForCourseScreen,
path:"/PtTestsForCourseScreen"},
PtStudentsOnCourseScreen:{
 component:PtStudentsOnCourseScreen,
path:"/PtStudentsOnCourseScreen"},
PtTestScoresForStudentScreen:{
 component:PtTestScoresForStudentScreen,
path:"/PtTestScoresForStudentScreen"},
PtBasicUserCoursesScreen:{
 component:PtBasicUserCoursesScreen,
path:"/PtBasicUserCoursesScreen"},
PtBasicUserAllCoursesScreen:{
 component:PtBasicUserAllCoursesScreen,
path:"/PtBasicUserAllCoursesScreen"},
PtStudentRankingScreen:{
 component:PtStudentRankingScreen,
path:"/PtStudentRankingScreen"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
DailyScheduleNotifications:{
 component:DailyScheduleNotifications,
path:"/DailyScheduleNotifications"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
SpellCheck:{
 component:SpellCheck,
path:"/SpellCheck"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Download:{
 component:Download,
path:"/Download"},
DownloadUpload:{
 component:DownloadUpload,
path:"/DownloadUpload"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
Automaticreminders:{
  component:Automaticreminders,
 path:"/Automaticreminders"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
QrCodes:{
 component:QrCodes,
path:"/QrCodes"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
PdfEdit:{
 component:PdfEdit,
path:"/PdfEdit"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/signup"},
Readermode:{
 component:Readermode,
path:"/Readermode"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/login"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LandingPage:{
 component:HomeScreen,
path:"/LandingPage"},
CameraAccess:{
 component:CameraAccess,
path:"/CameraAccess"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
DragDropInterface:{
 component:DragDropInterface,
path:"/DragDropInterface"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:Task,
path:"/TaskList"},
Task:{
 component:TaskList,
path:"/Task"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
NotificationSettingWeb: {
  component: NotificationSettingWeb,
  path: '/notificationsetting'
},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Customiseinterface2:{
 component:Customiseinterface2,
path:"/Customiseinterface2"},
CustomisableUserProfiles:{
  component:CustomisableUserProfiles,
 path:"/CustomisableUserProfiles"},
 BulletinBoard:{
  component:BulletinBoard,
 path:"/BulletinBoard"},
 CertificationTracking:{
  component:CertificationTracking,
 path:"/CertificationTracking"},
 BreadcrumbNavigation:{
  component:BreadcrumbNavigation,
 path:"/BreadcrumbNavigation"},
 PtStudentInfoScreen:{
  component:PtStudentInfoScreen,
  path:"/TeamMembers",
 },
 TestScoring:{
  component:TestScoring,
  path:"/TestScoring"
 },

  Home: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  CourseFeedback: {
    component: CourseFeedback,
    path: "*/CourseFeedback",
    modal: true
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
        <View style={{ minHeight: '100vh'}}>
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
    );
  }
}

export default App;