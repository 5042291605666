import { Box, Grid, styled } from "@material-ui/core";
import { Close, Pause, PlayArrow } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import React, { CSSProperties, useRef, useState, ChangeEvent, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Button from "./Button";
import PreviewFileCard from "./Card";
import IconButton from "./IconButton";
import CustomLabel from "./Label";
import { handleFileSize } from "./handle-file-size";

const videoIcon = require("./assets/video-icon.svg");
const pdfIcon = require("./assets/pdf-icon.svg");
const audioIcon = require("./assets/audio-icon.svg");
const zipIcon = require("./assets/zip-icon.svg");


type IFileUploadProps = | {
    id?: string;
    required?: boolean;
    label?: string;
    labelStyle?: CSSProperties;
    supportText?: string;
    fileType?: "video" | "image" | "pdf" | string;
    multiple?: false;
    withoutPreview?: boolean;
    disableBrowsing?: boolean;
    uploadingFileIcon?: string;
    triggerStyle?: CSSProperties;
    value?: File;
    onChange?: (files: File[] | File | null) => void;
} | {
    id?: string;
    required?: boolean;
    label?: string;
    labelStyle?: CSSProperties;
    supportText?: string;
    fileType?: "video" | "image" | "pdf" | string;
    multiple: true;
    uploadingFileIcon?: string;
    triggerStyle?: CSSProperties;
    value?: File[];
    onChange?: (files: File[] | File | null) => void;
}


const FileUploadV2 = (props: IFileUploadProps) => {
    const { 
        id, 
        required, 
        label, 
        labelStyle, 
        supportText, 
        fileType = "image/*",
        uploadingFileIcon, 
        triggerStyle,
        value,
        onChange,
        ...rest 
    } = props;
    const fileRef = useRef<HTMLInputElement>(null);
    const videoPreviewRef = useRef<HTMLVideoElement>(null);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        handleFilesToPreview(acceptedFiles);
        setFileList([...fileList, ...acceptedFiles]);
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const [previewUrl, setPreviewUrl] = useState(!rest.multiple && !rest.withoutPreview && value && value instanceof File ? URL.createObjectURL(value) : "");
    const [previewUrls, setPreviewUrls] = useState<string[]>(rest.multiple && value && Array.isArray(value) ? value.map(file => URL.createObjectURL(file)) : []);
    const [fileName, setFileName] = useState(value ? (Array.isArray(value) ? (value.length > 0 ? value[0]?.name : "") : value.name) : "");
    const [fileSize, setFileSize] = useState(value ? (Array.isArray(value) ? (value.length > 0 ? value[0]?.size : 0) : value.size) : 0);
    const [fileList, setFileList] = useState<File[]>(value ? (value instanceof File ? [value] : value as File[]) : []);
    const [previewVideoPlaying, setPreviewVideoPlaying] = useState(false);

    useEffect(() => {
        if (fileList && onChange) {
            onChange(fileList);
        }
    }, [fileList]);

    const handleFilesToPreview = (files: Array<File>) => {
        if (rest.multiple) {
            if (files.length === 0) {
                setPreviewUrls([]);
            } else {
                setPreviewUrls([...previewUrls, ...Array.from(files).map(file => URL.createObjectURL(file))]);
            }
        } else {
            if (!rest.withoutPreview) {
                if (files.length === 0) {
                    setPreviewUrl("");
                } else {
                    setPreviewUrl(URL.createObjectURL(files[0]));
                }
            } else {
                if (files.length === 0) {
                    setFileName("");
                    setFileSize(0);
                } else {
                    setFileName(files[0].name);
                    setFileSize(files[0].size);               
                }
            }
        }
    }
    const getFileTypeIcon = (fileType: string) => {
        if (fileType.includes("video")) {
            return videoIcon;
        }
        if (fileType.includes("audio")) {
            return audioIcon;
        }
        if (fileType.includes("zip")) {
            return zipIcon;
        }
        return pdfIcon;
    }
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            handleFilesToPreview(Array.from(event.target.files));
            if (!rest.multiple) {
                setFileList([event.target.files[0]])
            } else {
                setFileList([...fileList, ...Array.from(event.target.files)]);
            }
        }
    }
    const handleFileUploadClick = () => {
        if (fileRef && fileRef.current) {
            fileRef.current.click();
        }
    };
    const removePreviewMedia = (index?: number) => {
        if (!rest.multiple) {
            if (!rest.withoutPreview) {
                setPreviewUrl("");
            } else {
                setFileName("");
                setFileSize(0);
            }
            if (fileRef && fileRef.current) {
                fileRef.current.value = "";
            }
            setFileList([]);
        } else {
            if (typeof index === "number") {
                setPreviewUrls([...previewUrls.slice(0, index), ...previewUrls.slice(index + 1, previewUrls.length)]);
                setFileList(fileList ? [...fileList?.slice(0, index), ...fileList?.slice(index +1, fileList.length)] : []);
            }
        }
    }
    const handlePlayPauseVideo = () => {
        if (previewVideoPlaying) {
            videoPreviewRef.current?.pause();
        } else {
            videoPreviewRef.current?.play();
        }
        setPreviewVideoPlaying(!previewVideoPlaying);
    }

    if (!rest.multiple && rest.withoutPreview) {
        return (
            <Container>
                <FileUploadWithoutPreviewZone>
                    <CustomLabel>No file selected</CustomLabel>
                    <label htmlFor="fileFromLocal">
                        <input
                            id="fileFromLocal"
                            type="file"
                            accept={fileType}
                            ref={fileRef}
                            style={{ display: "none" }}
                            onChange={handleChange}
                        />
                        {!rest.disableBrowsing 
                            ? <BrowseButton onClick={handleFileUploadClick}>Browse file</BrowseButton> 
                            : <BrowseButton>Assign from Library</BrowseButton>}
                    </label>
                </FileUploadWithoutPreviewZone>
                {fileName !== "" && !rest.disableBrowsing
                    && (
                        <PreviewFileCard 
                            thumbnailSrc={getFileTypeIcon(fileType)} 
                            title={fileName} 
                            subtitle={handleFileSize(fileSize)} 
                            actionButton={<IconButton onClick={() => removePreviewMedia()}><CloseIcon /></IconButton>} 
                            style={{ marginTop: "5px", width: "80%" }}
                        />
                    )}
            </Container>
        )
    }

    return (
        <Container>
            {label && (
                <Label 
                    htmlFor={id} 
                    role="alert" 
                    style={labelStyle}
                >
                    {label} {required && <span style={{ fontSize: '8px' }}>(Required <span style={{ color: "#DC2626" }}>*</span>)</span>}
                </Label>)}
            {previewUrls.length > 0 && fileType.includes("image") && (
                <Box style={{ padding: "30px" }}>
                    <Grid container spacing={1}>
                        {previewUrls.map((url, index) => (
                            <Grid item xs={4} justifyContent="center" alignItems="stretch">
                                <ImagePreview src={url} alt={`Image ${index}`} style={{ height: "204px" }} />
                                <RemoveButton style={{ height: "fit-content", left: "80%" }} onClick={() => removePreviewMedia(index)}>
                                    <Close />
                                </RemoveButton>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
            {previewUrl !== ""
                ? (
                    <FilePreview>
                        {fileType.includes("image") 
                            ? <ImagePreview src={previewUrl} />
                            : <VideoPreview src={previewUrl} ref={videoPreviewRef} controls />
                        }
                        {/* {!fileType.includes("image") && (
                            <PlayButton variant="ghost" onClick={handlePlayPauseVideo}>
                                {!previewVideoPlaying ? <PlayArrow /> : <Pause />}
                            </PlayButton>)} */}
                        <RemoveButton style={{ height: "fit-content" }} onClick={() => removePreviewMedia()}>
                            <Close />
                        </RemoveButton>
                    </FilePreview>)
                : <label htmlFor="uploadedImage" className="profile-upload-btn">
                    <input
                        id="uploadedImage"
                        type="file"
                        accept={fileType}
                        ref={fileRef}
                        multiple={rest.multiple}
                        style={{ display: "none" }}
                        onChange={handleChange}
                        {...getInputProps}
                    />
                    <FileUploadZone {...getRootProps()} style={triggerStyle}>
                        <img src={uploadingFileIcon} />
                        <RowStack>
                            <SupportText>{isDragActive ? "Is dragging file" : supportText}</SupportText>
                            <Button variant="ghost" size="sm" style={{ fontSize: "12px", lineHeight: "18px" }} onClick={handleFileUploadClick}>browse</Button>
                        </RowStack>
                    </FileUploadZone>
                </label>}
        </Container>
    )
}

export default FileUploadV2;

const Container = styled(Box)({
    width: '100%',
    maxWidth: '100%'
})
const Label = styled('label')(({ theme }) => ({
    fontFamily: 'Rubik',
    paddingBottom: '4px',
    color: theme.palette.text.primary,
    lineHeight: '22px'
}))
const FilePreview = styled(Box)(({ theme }) => ({
    position: "relative",
  display: 'flex',
  alignItems: 'center',
  height: '239px',
  borderRadius: '22px',
  border: `1px solid ${theme.palette.primary.main}`,
  flexDirection: 'column'
}))
const ImagePreview = styled('img')({
    width: "100%", 
    height: "100%", 
    borderRadius: "15px",
})
const VideoPreview = styled('video')({
    width: "100%", 
    height: "100%", 
    borderRadius: "15px",
})
const PlayButton = styled(Button)({
    position: "absolute",
    bottom: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "48px",
    height: "48px",
    padding: 0,
    "& svg": { fill: "#F8FAFC" }
})
const RemoveButton = styled(IconButton)(({ theme }) => ({
    position: "relative",
    bottom: "85%",
    left: "40%",
    backgroundColor: "#D7D7D780", 
    padding: "3px", 
    borderRadius: 0,
    "& svg": { 
        height: "15px",
        width: "15px",
        fill: theme.palette.secondary.main 
    }
}))
const FileUploadZone = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "22px",
    height: "239px",
    border: `1px dashed ${theme.palette.secondary.contrastText}`,
    backgroundColor: theme.palette.primary.main,
    cursor: "pointer"
}))
const RowStack = styled(Box)({
    display: "flex",
    alignItems: "center"
})
const FileUploadWithoutPreviewZone = styled(RowStack)(({ theme }) => ({
    height: '90px',
    width: "100%",
    borderRadius: '8px',
    border: `1px solid ${theme.palette.info.dark}`,
    justifyContent: 'space-between',
    padding: '18px 16px',
    backgroundColor: "#FFF"
}))
const SupportText = styled('span')(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: '10px',
    lineHeight: '12px',
}))
const BrowseButton = styled(Button)({
    paddingLeft: "60px", 
    paddingRight: "60px", 
    borderRadius: "30px"
})