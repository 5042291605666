import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
interface CourseAttributes {
  course_name: string;
}

interface CourseData {
  data: {
    attributes: CourseAttributes;
  };
}

interface SearchStudentCourseAssignmentAttributes {
  status: string;
  group: string;
  created_at: string;
  course: CourseData;
}

interface SearchStudentCourseAssignment {
  id: string;
  type: string;
  attributes: SearchStudentCourseAssignmentAttributes;
}

interface SearchDataResponse {
  data: SearchStudentCourseAssignment[];
  meta: {
    result_courses_count: number;
  };
}
interface SearchHistoryItem {
  courseName: string;
  groupName: string;
  status: string;
  sort: string;
}

interface SearchHistoryResponse {
  history: HistoryItem[];
  meta: MetaData;
}

interface MetaData {
  total_pages: number;
  current_page: number;
  per_page: number;
  message: string;
}

interface HistoryItem {
  id: string;
  type: string;
  attributes: HistoryAttributes;
}

interface HistoryAttributes {
  id: number;
  account_id: number;
  created_at: string;
  course: CourseData;
  student_course_assignment: StudentCourseAssignmentData | null;
}

interface CourseData {
  data: {attributes:CourseAttributes};
}
interface StudentCourseAssignmentData {
  data: StudentCourseAssignment | null;
}
interface StudentCourseAssignment {
  id: string;
  type: string;
  attributes: StudentCourseAssignmentAttributes;
}
interface StudentCourseAssignmentAttributes {
  id: number;
  created_at: string;
  updated_at: string;
  status: string;
  progress_percentage: string;
  expiration_date: string;
  red_label: string | null;
  group: string;
  course: CourseDetail;
  profile: ProfileDetail;
}
interface CourseDetail {
  data: CourseInfo;
  included: CourseContent[];
}
interface CourseDataResponse {
  data: CourseDataItem[];
  meta: {
    result_courses_count: number;
  };
}
interface CourseDataItem {
  id: string;
  type: string;
  attributes: CourseAttributes;
}
interface CourseInfo {
  id: string;
  type: string;
  attributes: CourseAttributes;
  relationships: CourseRelationships;
}
interface CourseAttributes {
  course_name: string;
  course_description: string | null;
  category: Category | null;
  sub_category: SubCategory | null;
  sub_sub_category: string | null;
  category_name: string | null;
  sub_category_name: string | null;
  language: string | null;
  price: string | null;
  level: string | null;
  tags: string | null;
  duration: string | null;
  year: string | null;
  percentage: number | null;
  most_relevant: boolean | null;
  is_assigned: boolean;
  is_completed: boolean;
  profile_id: number;
  role_id: string;
  preferred_name: string;
  media_type: string | null;
  publish_type: string | null;
  group_names: string | null;
  status: string;
  curriculum_outline: string | null;
  tag_color: string | null;
  downloaded: boolean;
  due_date: string | null;
  folder_ids: number[];
  start_date: string | null;
  end_date: string | null;
  time_for_supervisor_to_review: string | null;
  user_library_ids: number[];
  total_size: string;
  course_contents_count: number;
  image: string | null;
  video_url: string | null;
  document_url: string | null;
  course_content: {
    data: CourseContent[];
  };
  upload_media: {
    data: any[];
  };
  quizzes: {
    data: any[];
  };
  final_tests: {
    data: any[];
  };
  chapters: any[];
  folders: {
    data: any[];
  };
  user_libraries: {
    data: any[];
  };
}

interface Category {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  admin_user_id: number | null;
  rank: number | null;
  identifier: string | null;
  activated: boolean;
}

interface SubCategory {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  parent_id: number | null;
  rank: number | null;
  category_id: number;
  activated: boolean;
}

interface CourseRelationships {
  course_contents: {
    data: CourseContentData[];
  };
}
interface CourseContentData {
  id: string;
  type: string;
}
interface CourseContent {
  id: string;
  type: string;
  attributes: {
    course_id: number;
    title: string;
    lesson_name: string;
    lesson_number: number;
    duration: string | null;
    offline_download: boolean;
    video: string | null;
    document: string | null;
    file: string | null;
  };
}

interface StudentCourseAssignmentData {
  data: StudentCourseAssignment | null;
}

interface StudentCourseAssignment {
  id: string;
  type: string;
  attributes: StudentCourseAssignmentAttributes;
}

interface StudentCourseAssignmentAttributes {
  id: number;
  created_at: string;
  updated_at: string;
  status: string;
  progress_percentage: string;
  expiration_date: string;
  red_label: string | null;
  group: string;
  course: CourseDetail;
  profile: ProfileDetail;
}

interface CourseDetail {
  data: CourseInfo;
  included: CourseContent[];
}

interface CourseInfo {
  id: string;
  type: string;
  attributes: CourseAttributes;
  relationships: CourseRelationships;
}

interface ProfileDetail {
  // Define attributes based on your application's requirements
}

interface CourseContent {
  id: string;
  type: string;
  attributes: CourseContentAttributes; // Use a consistent type here
}

interface CourseContentAttributes {
  course_id: number;
  title: string;
  lesson_name: string;
  lesson_number: number;
  duration: string | null;
  offline_download: boolean;
  video: string | null;
  document: string | null;
  file: string | null;
}

interface ProfileDetail {
  data: ProfileInfo;
}
interface ProfileInfo {
  id: string;
  type: string;
  attributes: ProfileAttributes;
}
interface ProfileAttributes {
  id: number;
  first_name: string | null;
  last_name: string | null;
  email: string;
  date_of_birth: string;
  preferred_name: string;
  role_id: string;
  full_phone_number: string;
  employee_number: string | null;
  location: string | null;
  company_reference: string | null;
  completed_course: string | null;
  account_id: number;
  image: string | null;
  group_id: number;
  group_name: string;
  company_id: number;
  company_name: string;
  phone_number_verified: boolean;
  selected_theme: string;
  font_size: string;
}
interface MetaData {
  total_pages: number;
  current_page: number;
  per_page: number;
  message: string;
}
type FilterCategory = 'cource' | 'status' | 'sort';
interface ProfileData  {
  id: string;
  type: string;
  attributes: ProfileAttributes;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  darkMode:boolean|undefined;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  firstNameSearchText: string;
  lastNameSearchText: string;
  advancedsearchList: any;
  activeId: number;
  activeFirstName: string;
  activeLastName: string;
  activeUserName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeCountryCode: string;
  activeType: string;
  activeDeviceId: string;
  activeCreatedAt: string;
  isVisible: boolean;
  sidOpen: boolean;
  darkMode: boolean;
  isOpen: boolean;
  searchHistory: { id:string; courseName: string; }[];
  searchAllHistory: {
   id:string; courseName: string; groupName: string; status: string; sort: string,
  }[];
  searchHistoryList: { id: string; name: string; }[];
  error: string;
  filters: boolean;
  history: boolean;
  allFilters: {
    course: string[];
    status: string[];
    sort: string[];
  };
  allSelectedFilters: {
    "cource": string[];
    "status": string[];
    "sort": string[];
  };
  selectedFilters: string[],
  modalOpen: boolean;
  isLoading: boolean;
  searchResults: any[],
  searchQuery: string,
  openSaveFilters: boolean;
  selectedTheme: boolean;
  anchorEl: null;
  profileData:ProfileData;
  openEmptySearch:boolean;
  roleId:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdvancedSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  advancedsearchApiCallId: any;
  getSearchBarApiCallId = "";
  getSearchHistoryApiCallId = "";
  deleteSearchHistoryApiCallId = "";
  deleteSearchHistoryByIdApiCallId = "";
  getAdvacedSearchApiCallId="";
  apiProfileGETCallId="";
  postSearchHistoryApiCallId="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      firstNameSearchText: "",
      lastNameSearchText: "",
      advancedsearchList: [],
      activeId: 0,
      activeFirstName: "",
      activeLastName: "",
      activeUserName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeCountryCode: "",
      activeType: "",
      activeDeviceId: "",
      activeCreatedAt: "",
      isVisible: false,
      sidOpen: false,
      darkMode: false,
      isOpen: false,
      searchHistory: [],
      searchAllHistory: [],
      searchHistoryList: [],
      error: "",
      filters: false,
      history: false,
      allFilters: {
        course: [],
        status: [],
        sort: [],
      },
      allSelectedFilters:{
        "cource":[],
        "status":[],
        "sort":[]
      },
      selectedFilters: [],
      modalOpen: false,
      isLoading: false,
      searchResults: [],
      searchQuery: "",
      openSaveFilters: false,
      selectedTheme: false,
      anchorEl: null,
      profileData: {
        id: "",
        type: "profile",
        attributes: {
          id: 0,
          first_name: null,
          last_name: null,
          email: "",
          date_of_birth: "",
          preferred_name: "",
          role_id: "",
          full_phone_number: "",
          employee_number: null,
          location: null,
          company_reference: null,
          completed_course: null,
          account_id: 0,
          image: null,
          group_id: 0,
          group_name: "",
          company_id: 0,
          company_name: "",
          phone_number_verified: false,
          selected_theme: "",
          font_size: "",
        },
      },
      openEmptySearch:false,
      roleId:"",
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getUserProfileData();
    this.checkDarkMode();
    await this.handleSearchHistory();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      this.handleSessionResponse(message);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiResponse(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSessionResponse(message: Message) {
    let token = message.getData(getName(MessageEnum.SessionResponseToken));
    runEngine.debugLog("TOKEN", token);
    this.setState({ token: token });
  }

  handleApiResponse(message: Message) {
    runEngine.debugLog("Message Received", message);
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    runEngine.debugLog("API Message Received", message);

    if (responseJson) {
      this.processResponseJson(apiRequestCallId, responseJson);
    }
  }

  processResponseJson(apiRequestCallId: string, responseJson: any) {
    if (responseJson.accounts) {
      this.handleAccountsResponse(responseJson.accounts);
    } else if (responseJson.errors) {
      this.handleErrorResponse(apiRequestCallId);
    }

    this.handleSpecificApiResponses(apiRequestCallId, responseJson);
  }

  handleAccountsResponse(accounts: any) {
    if (typeof accounts === "string") {
      alert(accounts);
    } else {
      this.setState({ advancedsearchList: accounts.data });
    }
  }

  handleErrorResponse(apiRequestCallId: string) {
    if (apiRequestCallId === this.advancedsearchApiCallId) {
      this.showAlert("Alert", "API Error", "");
    }
  }

  handleSpecificApiResponses(apiRequestCallId: string, responseJson: any) {
    if (this.getSearchBarApiCallId === apiRequestCallId && !responseJson.errors) {
      this.handleSearchByRole(responseJson);
    } else if (this.getSearchHistoryApiCallId === apiRequestCallId && !responseJson.errors) {
      this.handleHistoryByRole(responseJson)     
    } else if (this.deleteSearchHistoryByIdApiCallId === apiRequestCallId && !responseJson.errors) {
      this.setState({
        isLoading: false,
      }, () => this.handleSearchHistory());
    } else if (this.apiProfileGETCallId === apiRequestCallId && !responseJson.errors) {
      setStorageData("user_role", responseJson.role_id)
      this.setState({
        roleId: responseJson.role_id,
        isLoading: false,
      },()=>{this.setFiltersForRoles()});
    } else if (this.postSearchHistoryApiCallId === apiRequestCallId && !responseJson.errors) {
      this.handleSearchHistory();
    }else if (responseJson.errors) {
      this.setState({
        isLoading: false,
        error: responseJson.errors || "Unknown error",
        searchHistory: [],
        searchAllHistory: [],
        searchHistoryList: []
      });
    }
  }
  handleSearchByRole = (responsedata: any)=>{
    this.setState({
      searchHistory: this.transformSearchData(responsedata),
      isLoading: false,
    });
  }

  handleHistoryByRole = async(responsedata: any)=>{
    const searchHistory: { id: string; name: string }[] = (!responsedata || !responsedata.history) 
      ? [] 
      : responsedata.history.map((history: { id: string; attributes: { name: string }}) => ({
        id: history.id,
        name: history.attributes.name
      }));
    this.setState({ 
      searchHistoryList: searchHistory,
      isLoading: false
    })
  }
  setFiltersForRoles=()=>{
    if(this.state.roleId==="worker"){
      const newFilters = {
        course: ["title", "group"],
        status: ["in_progress", "completed"],
        sort: []
      }
      this.setState({
        allFilters:newFilters,
      });
    } else if(this.state.roleId==="instructor"){
      const newFilters = {
        course: [ "company","title", "group"],
        status: ["draft","published","archived"],
        sort: ["creation_date"],
      }
      this.setState({
        allFilters:newFilters,  
      });
    }
    else if(this.state.roleId==="supervisor"){
      const newFilters = {
        course: [ "title", "group","instructor"],
        status: ["published"],
        sort: ["completion_rate"],
      }
      this.setState({
        allFilters:newFilters,  
      });
    }
  }
  setHistoryStatus=(is_assigned:boolean,is_completed:boolean)=>{
    let status:string="";
    if(this.state.roleId==="worker"){
      if(is_assigned && is_completed){
        status="Assigned";
      } 
      if(is_assigned){
        status="Completed";
      }
    } else {
      if(is_assigned){
        status="DRAFT";
      } 
      if(is_assigned && is_completed){
        status="PUBLISHED";
      }
    }
    return status;
  }
  
  transformSearchData(searchData: SearchDataResponse) {
    if (!searchData || !searchData.data) {
      return [];
    }
    return searchData.data.map((item: any) => ({
      id: item.id,
      courseName: this.state.roleId !== "worker" ? item.attributes.course_name : item.attributes.course.data.attributes.course_name
    }));
  }

  handleAddToSearchHistory = async (queryString: string) => {
    this.setState({ isLoading: true });
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": "application/json",
    };
    
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postSearchHistoryApiCallId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.addSearchHistoryEndPoint);
    message.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify({ q: queryString }));
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(message.id, message);
  };

  navigateToCourseDetail = async (id: string) => {
    await setStorageData('courseId', id.toString());
    this.props.navigation.navigate("Projectnotes");
  }

  getAPIKey = () => {
    if (this.state.roleId === "worker")
      return "student_course_assignment_id"
    else return "course_id"
  }

  handleSearch = async (query: string) => {
    this.setState({ isLoading: true });
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": "application/json",
    };
  
    const attrs: any = {
      per_page: 5,
    };
  
    if (query && query.trim() !== "") {
      attrs.q = query;
    }

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSearchBarApiCallId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getAdvacedSearchApiEndPoint);
    message.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(attrs));
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(message.id, message);
  };
 
  handleSearchHistory = async () => {
    this.setState({ isLoading: true });
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": "application/json",
    };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSearchHistoryApiCallId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getSearchHistoryApiEndPoint);
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(message.id, message);
  };
  getUserProfileData=()=>{
    const webHeader = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProfileGETCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileGetURL
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }

  txtInputFirstNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setFirstNameText(text);
    }
  };

  txtInputLastNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setLastNameText(text);
    }
  };

  setFirstNameText = (firstName: string) => {
    this.setState({ firstNameSearchText: firstName });
  };

  setLastNameText = (firstName: string) => {
    this.setState({ lastNameSearchText: firstName });
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeFirstName: item.attributes.first_name,
      activeLastName: item.attributes.last_name,
      activeUserName: item.attributes.user_name,
      activeEmail: item.attributes.email,
      activePhoneNumber: item.attributes.phone_number,
      activeCountryCode: item.attributes.country_code,
      activeType: item.type,
      activeDeviceId: item.attributes.device_id,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible
    });
  };

  handleClose = () => {
    this.setState({ isOpen: false, filters: false, history: false, openSaveFilters: false });
  };
  openHistory = () => {
    this.setState({ isOpen: true }); 
  }
  handleDelete = async (id: string) => {
    this.setState({ isLoading: true });
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": "application/json",
    };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteSearchHistoryByIdApiCallId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.deleteSearchHistoryApiEndPoint}/${id}`);
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");

    runEngine.sendMessage(message.id, message);
  }
 
  goToFilters = (route: string) => {
    this.handleAddToSearchHistory(this.state.searchQuery);
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Filteritems");
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { data: route });
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  handleInputChange = (event: any) => {
    const searchQuery = event.target.value;
    this.setState({ isOpen: true, searchQuery }, async () => {
      await this.handleSearch(searchQuery);
    }); 
   
  }
  handleSelectSearchHistory = async (name: string) => {
    this.setState({ searchQuery: name }, async () => {
      await this.handleSearch(name);
      await this.handleAddToSearchHistory(name);
    }); 
  }

  checkDarkMode=()=>{
    let mode = JSON.parse(localStorage.getItem('darkMode')!);
    if(mode)
      this.setState({
        darkMode:mode
      })
  }
  // Customizable Area End
}
